import React from 'react';
import NProgress from 'nprogress';
import Router, { useRouter } from 'next/router';
import Head from 'next/head';
import { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import { motion } from 'framer-motion';
import { UserContextProvider, ToastContextProvider } from '../context';
import { useApollo, useScreenSize } from '../hooks';
import '../styles.css';
import Sidebar from '../components/layout/Sidebar';

// @ts-expect-error err is not defined
function MyApp({ Component, pageProps, err }: AppProps<any>) {
  const apolloClient = useApollo(pageProps.initialApolloState);
  const [decreaseMenu, setDecreaseMenu] = React.useState(false);
  const [screenSize_, setScreenSize_] = React.useState(null);
  const { screenSize } = useScreenSize();
  const router = useRouter();
  React.useEffect(() => {
    Router.events.on('routeChangeStart', () => {
      NProgress.start();
    });

    Router.events.on('routeChangeComplete', () => {
      NProgress.done();
    });

    Router.events.on('routeChangeError', () => {
      NProgress.done();
    });
  }, []);

  React.useEffect(() => {
    if (screenSize > 0) {
      setScreenSize_(screenSize);
      if (screenSize < 768) {
        setDecreaseMenu(false);
      }
    }
  }, [screenSize]);

  return (
    <ApolloProvider client={apolloClient}>
      <>
        <Head>
          <title>Admin | Spacars</title>
          <link rel="icon" href="/icon.png" />
        </Head>
        <UserContextProvider>
          <ToastContextProvider>
            {router?.asPath?.includes('app') ? (
              <>
                <Sidebar
                  decreaseMenu={decreaseMenu}
                  setDecreaseMenu={setDecreaseMenu}
                  screenSize={screenSize_}
                />
                <motion.section
                  initial={
                    screenSize_ >= 768
                      ? {
                          marginLeft: '288px',
                        }
                      : {
                          marginLeft: '0px',
                        }
                  }
                  animate={
                    screenSize_ >= 768
                      ? {
                          marginLeft: decreaseMenu ? '52px' : '288px',
                        }
                      : {
                          marginLeft: 0,
                        }
                  }
                  transition={{ duration: 0.18 }}
                  className="relative bg-[#FBFBFB] overflow-x-hidden min-h-screen md:ml-[288px] ml-0"
                >
                  <Component {...pageProps} err={err} />
                </motion.section>
              </>
            ) : (
              <Component {...pageProps} err={err} />
            )}
          </ToastContextProvider>
        </UserContextProvider>
      </>
    </ApolloProvider>
  );
}

export default MyApp;
