import React from 'react';
import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import { useMutation } from '@apollo/client';
import { Appointment, Order } from '@spa-cars/models';
import { Button, Input } from '@spa-cars/ui';
import { UPDATE_APPOINTMENT, UPDATE_ORDER } from '../../graphql/mutations';
import { useAvailableSlots, useNotify } from '../../hooks';
import SlotPicker from '../Appoinment/SlotPicker';

interface ChangeOrderDateProps {
  appointment: Partial<Appointment>;
}

function ChangeOrderDate({ appointment }: ChangeOrderDateProps) {
  const notify = useNotify();
  const [isOpen, setIsOpen] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [time, setTime] = React.useState([]);
  const [date, setDate] = React.useState(
    dayjs(appointment.date).format('YYYY-MM-DD')
  );

  const stringToDate = (value: string) => {
    const dateParts = value.split('/');
    return new Date(+dateParts[2], parseInt(dateParts[1]) - 1, +dateParts[0]);
  };

  const processDate = (value: string) => {
    const timeParts = time[0].split(':');
    const hours = parseInt(timeParts[0]);
    const partOfDay = timeParts[1].split(' ')[1]; // AM || PM

    const dateObject = stringToDate(value);
    dateObject.setHours(partOfDay === 'PM' ? hours + 12 : hours);
    dateObject.setMinutes(parseInt(timeParts[1]));

    return new Date(dateObject).toISOString();
  };

  const [updateAppointmentMutation] = useMutation<{
    updateAppointment: {
      record: Appointment;
    };
  }>(UPDATE_APPOINTMENT);

  const [slots, loadingGetAvailableSlots] = useAvailableSlots(
    appointment.location._id,
    dayjs(date).format('DD/MM/YYYY'),
    appointment.user._id
  );

  const hasFlush = React.useMemo(
    () =>
      // search flush in services of the appointment
      appointment.services.some((service) => service.name === 'flush'),
    [appointment]
  );

  const handleUpdateOrder = async () => {
    try {
      setDisabled(true);
      if (!date || !time || time.length === 0)
        return notify(
          'Debe seleccionar una fecha y hora para la nueva cita',
          'error'
        );
      const _date = processDate(dayjs(date).format('DD/MM/YYYY'));
      const _dateWithHalfHour = dayjs(_date).add(30, 'minute').toISOString();

      if (!hasFlush) {
        await updateAppointment(_date, {
          _id: appointment._id,
        });
      } else {
        // has to reschedule two blocks
        // first the original appointment
        await updateAppointment(_date, {
          _id: appointment._id,
        });
        // then the flush appointment
        await updateAppointment(_dateWithHalfHour, {
          reservationNumber: appointment?.reservationNumber,
          show: false,
        });
      }
    } catch (error) {
      console.log(error);
      notify('Error al reagendar la cita', 'error');
    } finally {
      setDisabled(false);
      notify('Cita reagendada correctamente', 'success');
      // refresh page
      window.location.reload();
    }
  };

  const updateAppointment = async (
    _date: string,
    filter: Partial<Appointment>
  ) => {
    try {
      await updateAppointmentMutation({
        variables: {
          filter: {
            ...filter,
          },
          record: {
            date: _date,
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <motion.li className="p-4 relative flex flex-col min-w-0 bg-white bg-clip-border shadow-[0_4px_10px_rgba(0,0,0,0.25)] rounded-lg ">
      <div className="flex mb-2">
        <h2 className=" text-neutral-100 text-sm">REAGENDAR CITA</h2>
        <div className="ml-auto">
          <Button
            type="button"
            className=" py-2 px-4  w-fit h-fit font-normal text-sm text-white"
            onClick={(e) => {
              e.preventDefault();
              setIsOpen(!isOpen);
            }}
          >
            <p className="leading-[14px]">{isOpen ? 'Cerrar' : 'Reagendar'}</p>
          </Button>
        </div>
      </div>
      {isOpen ? (
        <>
          <Input
            type="date"
            value={date}
            onChange={(e) => {
              setDate(e.target.value);
            }}
            className=""
          />
          <SlotPicker
            date={date}
            shop={appointment.location._id}
            setTime={setTime}
            loadingGetAvailableSlots={loadingGetAvailableSlots}
            slots={slots}
            time={time}
            withFlush={hasFlush}
          />
          <Button
            className="mt-5"
            onClick={(e) => {
              e.preventDefault();
              handleUpdateOrder();
            }}
            disabled={disabled}
          >
            Reagendar
          </Button>
        </>
      ) : null}
    </motion.li>
  );
}

export default ChangeOrderDate;
