import React from 'react';
import LocationForm from './LocationForm';
import { Layout } from '../layout';

export default function CreateLocationForm() {
  return (
    <Layout
      title="Nuevo punto de servicio"
      routesHistory={[
        {
          text: 'Perfil de punto de servicio',
          href: '/app/locations/create',
          key: 'create',
        },
      ]}
    >
      <LocationForm />
    </Layout>
  );
}
