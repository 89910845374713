import React from 'react';
import dayjs from 'dayjs';
import { useLazyQuery } from '@apollo/client';
import { GET_AVAILABLE_SLOTS } from '../graphql/queries';

export interface Slot {
  hour: string;
  available: boolean;
  count: number;
}

export function useAvailableSlots(shop: string, date: string, user: string) {
  // React hook
  const [slots, setSlots] = React.useState<Slot[]>([]);
  // Apollo hook
  const [getAvailableSlots, { loading, data }] = useLazyQuery<{
    getAvailableSlots: {
      slots: Slot[];
    };
  }>(GET_AVAILABLE_SLOTS, {
    fetchPolicy: 'cache-and-network',
  });

  const stringToDate = (value: string) => {
    const dateParts = value.split('/');
    return new Date(+dateParts[2], parseInt(dateParts[1]) - 1, +dateParts[0]);
  };

  React.useEffect(
    function syncSlots() {
      setSlots(
        data?.getAvailableSlots?.slots && !loading
          ? data?.getAvailableSlots?.slots
          : []
      );
    },
    [data, loading]
  );

  React.useEffect(
    function getSlots() {
      console.log(date);
      if (shop && date) {
        // get appointments by location and date
        const _date = stringToDate(date);
        getAvailableSlots({
          variables: {
            data: {
              date: dayjs(_date).startOf('day').toISOString(),
              location: shop,
              user,
            },
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shop, date]
  );

  // Use slots in parent component
  return [slots, loading] as const;
}
