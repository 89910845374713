import React from 'react';
import NProgress from 'nprogress';
import dayjs from 'dayjs';
import { Button } from '@spa-cars/ui';
import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
  useQuery,
} from '@apollo/client';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import {
  Appointment,
  User,
  Order,
  Repair,
  Vehicle,
  Currency,
  Product,
  RepairStep,
  OrderProduct,
} from '@spa-cars/models';
import { useNotify, useUser } from '../../hooks';
import { ACTIONS, reducer } from './reducer';
import {
  CANCEL_APPOINTMENT,
  UPDATE_ORDER,
  UPDATE_REPAIR,
} from '../../graphql/mutations';
import { Invoice } from './product';
import {
  ClientCard,
  ClientSelectionCard,
  DateCard,
  OperatorCard,
  RepairStepCard,
  VehicleCard,
} from './cards';
import HistoryCard from './cards/HistoryCard';
import { GET_CURRENCIES, GET_PRODUCTS } from '../../graphql/queries';
import {
  ConfirmationModal,
  FlushModal,
  FlushSaleModal,
  RescheduleModal,
} from './modals';
import ChangeOrderDate from './ChangeOrderDate';

const today = dayjs();

interface AppointmentFormProps {
  appointment?: Partial<Appointment>;
  refetchAppointment: (variables?: Partial<OperationVariables>) => Promise<
    ApolloQueryResult<{
      appointment: Appointment;
    }>
  >;
  setAppointment?: React.Dispatch<React.SetStateAction<Partial<Appointment>>>;
  dataRepair?: {
    getRepair: {
      repair: Repair;
      mechanic: User;
      vehicle: Vehicle;
    };
  };
  refetchRepair?: (variables?: Partial<OperationVariables>) => Promise<
    ApolloQueryResult<{
      getRepair: {
        repair: Repair;
        mechanic: User;
        vehicle: Vehicle;
      };
    }>
  >;
}

function AppointmentForm({
  appointment = null,
  setAppointment = null,
  dataRepair = null,
  refetchRepair = null,
  refetchAppointment,
}: AppointmentFormProps) {
  const [user] = useUser();
  const [state, dispatch] = React.useReducer(reducer, appointment || {});
  const notify = useNotify();
  const [disabled, setDisabled] = React.useState(false);
  const [isOpen, setOpen] = React.useState(false);
  const [isOpenFlush, setOpenFlush] = React.useState(false);
  const [isOpenReschedule, setOpenReschedule] = React.useState(false);
  const [isOpenFlushSale, setOpenFlushSale] = React.useState(false);
  const [superFlushIsRequired, setSuperFlushIsRequired] = React.useState(false);
  const [normalFlushIsRequired, setNormalFlushIsRequired] =
    React.useState(false);
  const [disableFlushModal, setDisableFlushModal] = React.useState(false);
  const [date, setDate] = React.useState(today.format('DD/MM/YYYY'));
  const [operator, setOperator] = React.useState<Partial<User>>(null);
  const [vehicle, setVehicle] = React.useState<Partial<Vehicle>>(null);
  const [repair, setRepair] = React.useState<Partial<Repair>>(null);
  const router = useRouter();
  const [flushProducts, setFlushProducts] = React.useState<Product[]>(null);
  const [cancelAppointment] = useMutation<{
    cancelAppointment: Appointment;
  }>(CANCEL_APPOINTMENT);

  const [updateOrder] = useMutation<{
    updateOrder: {
      record: Order;
    };
  }>(UPDATE_ORDER);
  const [updateRepair] = useMutation<{
    updateRepair: {
      record: Repair;
    };
  }>(UPDATE_REPAIR);

  const {
    loading: loadingCurrencies,
    data: dataCurrencies,
    error: errorCurrencies,
  } = useQuery<{
    currencies: Currency[];
  }>(GET_CURRENCIES, {
    variables: {
      filter: {
        active: true,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const {
    loading: loadingProducts,
    data: dataProducts,
    error: errorProducts,
  } = useQuery<{ getProducts: Product[] }>(GET_PRODUCTS, {
    variables: {
      data: {
        tags: superFlushIsRequired ? ['super-flush'] : ['flush'],
        location: appointment?.location?._id ?? '',
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const onPayOrder = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      e.preventDefault();
      NProgress.start();
      setDisabled(true);
      if (appointment) {
        const { data } = await updateOrder({
          variables: {
            filter: {
              _id: (appointment?.order as Order)?._id,
            },
            record: {
              __typename: undefined,
              paymentStatus: 'manual_check',
            },
          },
        });
        if (data?.updateOrder?.record?._id) {
          notify('Servicio actualizada con éxito', 'success');
          setAppointment({
            ...state,
            order: {
              ...(state?.order as Order),
              paymentStatus: 'manual_check',
            },
          });
        } else {
          notify('Ha ocurrido un error al actualizar el servicio', 'error');
        }
      }
      setDisabled(false);
    } catch (err) {
      notify(err.message, 'error');
      setDisabled(false);
    } finally {
      NProgress.done();
    }
  };

  const updateRepairInfo = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      e.preventDefault();
      NProgress.start();
      setDisabled(true);
      if (repair) {
        const { data } = await updateRepair({
          variables: {
            filter: {
              _id: repair?._id,
            },
            record: {
              mechanic: operator?._id,
              vehicle: vehicle?._id,
            },
          },
        });
        if (data?.updateRepair?.record?._id) {
          notify('Servicio actualizada con éxito', 'success');
          refetchRepair();
        } else {
          notify('Ha ocurrido un error al actualizar el servicio', 'error');
        }
      }
      setDisabled(false);
    } catch (err) {
      notify(err.message, 'error');
      setDisabled(false);
    } finally {
      NProgress.done();
    }
  };

  const cancelOrder = async () => {
    try {
      NProgress.start();
      setDisabled(true);
      if (appointment) {
        const { data: dataAppointment } = await cancelAppointment({
          variables: {
            data: {
              appointment: appointment?._id,
              admin: user?._id,
            },
          },
        });

        if (dataAppointment?.cancelAppointment) {
          notify('Servicio cancelado con éxito', 'success');
          setAppointment({
            ...state,
          });
        } else {
          notify('Ha ocurrido un error al cancelar el servicio', 'error');
        }
      } else {
        notify('Ha ocurrido un error al cancelar el servicio', 'error');
      }
      router?.back();
      setDisabled(false);
      setOpen(false);
    } catch (err) {
      notify(err.message, 'error');
      setOpen(false);
      setDisabled(false);
    } finally {
      NProgress.done();
    }
  };

  const continueServiceWithoutFlush = async () => {
    try {
      NProgress.start();
      setDisableFlushModal(true);
      const { data: dataRepair_ } = await updateRepair({
        variables: {
          filter: {
            _id: repair?._id,
          },
          record: {
            currentStep: repair.currentStep + 1,
          },
        },
      });
      if (dataRepair_?.updateRepair?.record) {
        notify('Se ha actualizado el servicio con éxito', 'success');
      } else {
        notify('Ha ocurrido un error', 'error');
      }
    } catch (error) {
      notify(`Error al al crear la cita: ${error}`, 'error');
    } finally {
      setDisableFlushModal(false);
      setOpenFlush(false);
      NProgress.done();
    }
  };

  const openFlushSaleModal = () => {
    setOpenFlush(false);
    setOpenFlushSale(true);
  };

  React.useEffect(() => {
    if (appointment) {
      dispatch({ type: ACTIONS.DEFAULT, payload: appointment });
      setDate(dayjs(appointment?.date).format('DD/MM/YYYY'));
      setVehicle(appointment?.vehicle);
    }
  }, [appointment]);

  React.useEffect(() => {
    if (dataRepair?.getRepair) {
      setRepair(dataRepair?.getRepair?.repair);
      setOperator(dataRepair?.getRepair?.mechanic);
    }
  }, [dataRepair]);

  React.useEffect(() => {
    if (dataProducts?.getProducts && dataProducts?.getProducts?.length > 0) {
      setFlushProducts(dataProducts?.getProducts);
    }
  }, [dataProducts]);

  React.useEffect(() => {
    if (repair) {
      if (repair?.process[1]?.result === repair?.process[1]?.resultOptions[2]) {
        setSuperFlushIsRequired(true);
      } else if (
        repair?.process[1]?.result === repair?.process[1]?.resultOptions[1]
      ) {
        setNormalFlushIsRequired(true);
      }
    }
  }, [repair]);

  return (
    <form
      method="POST"
      onSubmit={(e) => {
        e.preventDefault();
      }}
      className="flex flex-col w-full gap-8"
    >
      {/* ask if you want to add flush */}
      <FlushModal
        setOpen={setOpenFlush}
        isOpen={isOpenFlush}
        disableModal={disableFlushModal}
        continueServiceWithoutFlush={continueServiceWithoutFlush}
        openFlushSaleModal={openFlushSaleModal}
      />

      {/* reschedule appointment in case the user added super-flush and has to come back with certain mileage */}
      <RescheduleModal
        setOpen={setOpenReschedule}
        isOpen={isOpenReschedule}
        refetchAppointment={refetchAppointment}
        appointmentId={appointment?._id}
        locationId={appointment?.location?._id}
        driver={appointment?.driver}
        isLoading={false}
      />

      {/* modal with flush payment  */}
      <FlushSaleModal
        setOpen={setOpenFlushSale}
        driver={appointment?.driver}
        isLoading={loadingCurrencies || loadingProducts}
        isOpen={isOpenFlushSale}
        superFlushIsRequired={superFlushIsRequired}
        refetchAppointment={refetchAppointment}
        orderProducts={flushProducts?.map((flushProduct) => ({
          _id: flushProduct?._id,
          title: flushProduct?.title,
          variant: flushProduct?.variants[0],
          product: flushProduct,
          quantity: 1,
          price: flushProduct?.variants[0]?.price,
        }))}
        appointmentId={appointment?._id}
        locationId={appointment?.location?._id}
        state={state?.order}
        currencies={dataCurrencies?.currencies}
        repairId={repair?._id}
        user={appointment?.user}
        appointmentCurrentDate={new Date(appointment?.date)}
        hasFlush={!!appointment?.services?.find((s) => s.name === 'flush')}
      />

      {/* ask if you want to cancel the service */}
      <ConfirmationModal
        setOpen={setOpen}
        isOpen={isOpen}
        cancelOrder={cancelOrder}
      />

      <div className="w-full flex gap-8 md:flex-row flex-col items-center md:items-start ">
        {/* left side */}
        <motion.div layout className="w-full flex flex-col gap-6">
          <motion.ul layout className="w-full flex flex-col gap-4">
            <ClientCard state={state} />
            <ClientSelectionCard
              ownFlush={state?.order?.ownFlush ?? false}
              ownFilter={state?.order?.ownFilter}
              ownOil={state?.order?.ownOil}
              items={
                (state?.order?.items as Partial<OrderProduct>[])?.filter(
                  (op) => op?.product
                ) ?? []
              }
            />
            {operator?._id ? (
              <OperatorCard
                operator={operator}
                setOperator={setOperator}
                disabled={disabled}
                locationId={state?.location?._id}
                updateRepairInfo={updateRepairInfo}
              />
            ) : null}
            {appointment?.status === 'not_started' ? (
              <ChangeOrderDate appointment={appointment} />
            ) : null}
            {vehicle ? (
              <VehicleCard
                vehicle={vehicle}
                setVehicle={setVehicle}
                updateRepairInfo={updateRepairInfo}
                disabled={disabled}
                clientId={((appointment?.order as Order)?.user as User)?._id}
              />
            ) : null}
          </motion.ul>
          {repair?.process?.length > 0 ? (
            <motion.div layout className="w-full flex flex-col gap-2">
              <motion.span layout className=" font-medium text-text-black">
                Historial del chequeo
              </motion.span>
              <motion.ul layout className="w-full flex flex-col gap-4">
                {(repair?.process ?? []).map(
                  (item: RepairStep) =>
                    (repair?.currentStep ?? 1) - 1 >=
                      repair?.process?.indexOf(item) && (
                      <RepairStepCard
                        repairStep={item}
                        inProcess={
                          (repair?.currentStep ?? 1) - 1 ===
                            repair?.process?.indexOf(item) &&
                          !(
                            repair?.currentStep === repair?.process?.length &&
                            item?.date
                          )
                        }
                        key={item?._id}
                        mileage={repair?.mileage}
                      />
                    )
                )}
              </motion.ul>
            </motion.div>
          ) : null}
          {(state?.order as Order)?.history?.length > 0 ? (
            <motion.div layout className="w-full flex flex-col gap-2">
              <motion.span layout className=" font-medium text-text-black">
                Historial de cambios del servicio
              </motion.span>
              <motion.ul layout className="w-full flex flex-col gap-4">
                {((state?.order as Order)?.history ?? []).map((item) => (
                  <HistoryCard history={item} key={item?._id} />
                ))}
              </motion.ul>
            </motion.div>
          ) : null}
        </motion.div>
        {/* right side */}
        <div className="w-[336px] flex flex-col gap-4">
          <DateCard repair={repair} date={date} appointment={appointment} />
          <Invoice
            order={state?.order as Order}
            location={appointment?.location}
          />
          {appointment?.services?.filter(
            (service) =>
              (appointment?.subOrders as Order[])?.find(
                (subO) => subO?._id === service?.order
              )
          )?.length > 0 ? (
            <Invoice
              services
              order={state?.order as Order}
              location={appointment?.location}
              subOrders={appointment?.subOrders}
              serviceOrderIds={appointment?.services?.map((s) => s?.order)}
            />
          ) : null}
          {/* {(state?.order as Order)?.paymentStatus === 'initial' ||
          (state?.order as Order)?.paymentStatus === 'void' ? (
            <Button type="button" onClick={onPayOrder}>
              Marcar como pagado
            </Button>
          ) : null} */}
          {(repair?._id && repair?.status === 'started') ||
          (appointment?.status !== 'cancelled' &&
            appointment?.status !== 'completed') ? (
            <Button
              type="button"
              className=" bg-danger-100"
              onClick={(e) => {
                e.preventDefault();
                setOpen(true);
              }}
            >
              Cancelar servicio
            </Button>
          ) : null}

          {appointment?.status === 'in_progress' &&
          repair?.currentStep === 2 &&
          ((normalFlushIsRequired &&
            !appointment?.services?.find((ss) => ss?.name === 'flush')) ||
            (superFlushIsRequired &&
              !appointment?.services?.find(
                (ss) => ss?.name === 'super_flush'
              ))) ? (
            <Button
              type="button"
              className=" "
              onClick={(e) => {
                e.preventDefault();

                if (dataProducts?.getProducts?.length > 0) {
                  if (superFlushIsRequired) {
                    setOpenFlushSale(true);
                  } else {
                    setOpenFlush(true);
                  }
                } else {
                  notify('No se encuentra el producto flush', 'error');
                }
              }}
            >
              {superFlushIsRequired ? 'Reagendar servicio' : 'FLUSH'}
            </Button>
          ) : null}
          {appointment?.services?.find((ss) => ss?.name === 'super_flush') &&
          appointment?.status !== 'completed' &&
          appointment?.status !== 'cancelled' ? (
            <Button
              type="button"
              className=" "
              onClick={(e) => {
                e.preventDefault();
                setOpenReschedule(true);
              }}
            >
              Reagendar servicio
            </Button>
          ) : null}
        </div>
      </div>
    </form>
  );
}

export default AppointmentForm;
