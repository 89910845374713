import { useEffect, useState } from 'react';

function useScreenSize() {
  const [screenSize, setScreenSize] = useState(0);

  useEffect(() => {
    function updateScreenSize() {
      setScreenSize(window.innerWidth);
    }

    window.addEventListener('resize', updateScreenSize);

    updateScreenSize();

    return () => window.removeEventListener('resize', updateScreenSize);
  }, [screenSize]);

  return { screenSize, setScreenSize };
}

export default useScreenSize;
