export const priorities = [
  {
    name: 'Baja',
    value: 1,
  },
  {
    name: 'Media',
    value: 2,
  },
  {
    name: 'Alta',
    value: 3,
  },
];
