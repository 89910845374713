import React from 'react';
import {
  AppointmentStatusEnum,
  OrderStatusEnum,
  Schedule,
} from '@spa-cars/models';

export const ACTIONS = {
  LOCATION: 'LOCATION',
  SHIFT: 'SHIFT',
  STATUS: 'STATUS',
  DEFAULT: 'DEFAULT',
} as const;

export type ACTIONS_TYPE =
  | { type: 'LOCATION'; payload: string }
  | { type: 'SHIFT'; payload: 'morning' | 'afternoon' }
  | { type: 'STATUS'; payload: OrderStatusEnum | AppointmentStatusEnum }
  | { type: 'DEFAULT'; payload: Partial<Schedule> };

export function reducer(
  state: Partial<Schedule>,
  action: ACTIONS_TYPE
): Partial<Schedule> {
  switch (action.type) {
    case ACTIONS.LOCATION:
      return { ...state, location: action.payload };
    case ACTIONS.SHIFT:
      return { ...state, shift: action.payload };
    case ACTIONS.STATUS:
      return { ...state, status: action.payload };

    case ACTIONS.DEFAULT:
      return { ...state, ...(action?.payload ?? {}) };
    default:
      return { ...state };
  }
}
