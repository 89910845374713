import React from 'react';
import dayjs from 'dayjs';
import { useLazyQuery } from '@apollo/client';
import { DateInput, SelectTime, ByMyCarIcon, InfoIcon } from '@spa-cars/ui';
import { useNotify } from '../../hooks';
import { GET_AVAILABLE_SLOTS } from '../../graphql/queries';

const today = dayjs();

interface NewAppointmentProps {
  location: string;
  newAppointmentDate: Date;
  setNewAppointmentDate: React.Dispatch<React.SetStateAction<Date>>;
}

function NewAppointment({
  location,
  newAppointmentDate,
  setNewAppointmentDate,
}: NewAppointmentProps) {
  const notify = useNotify();
  const [date, setDate] = React.useState(today.format('DD/MM/YYYY'));
  const [time, setTime] = React.useState<string[]>([]);
  const [disabled, setDisabled] = React.useState(false);

  const [
    getAvailableSlots,
    { loading: loadingGetAvailableSlots, data: dataGetAvailableSlots },
  ] = useLazyQuery<{
    getAvailableSlots: {
      slots: { hour: string; available: boolean; count: number }[];
    };
  }>(GET_AVAILABLE_SLOTS, {
    fetchPolicy: 'cache-and-network',
  });

  const stringToDate = (value: string) => {
    const dateParts = value.split('/');
    return new Date(+dateParts[2], parseInt(dateParts[1]) - 1, +dateParts[0]);
  };

  React.useEffect(() => {
    // get appointment date and set it as iso string
    if (time?.length > 0) {
      const timeParts = time[0].split(':');
      const partOfDay = timeParts[1].split(' ')[1]; // AM || PM

      const dateObject = stringToDate(date);

      dateObject.setHours(
        partOfDay === 'PM'
          ? parseInt(timeParts[0]) + 12
          : parseInt(timeParts[0])
      );
      dateObject.setMinutes(parseInt(timeParts[1]));
      setNewAppointmentDate(new Date(dateObject));
    }
  }, [time]);

  // const handleSubmit = async (
  //   e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  // ) => {
  //   try {
  //     e.preventDefault();
  //     NProgress.start();
  //     if (disabled) {
  //       return;
  //     }
  //     setDisabled(true);

  //     // build full date as date object
  //     const timeParts = time[0].split(':');
  //     const partOfDay = timeParts[1].split(' ')[1]; // AM || PM

  //     const dateObject = stringToDate(date);

  //     dateObject.setHours(
  //       partOfDay === 'PM'
  //         ? parseInt(timeParts[0]) + 12
  //         : parseInt(timeParts[0])
  //     );
  //     dateObject.setMinutes(parseInt(timeParts[1]));
  //     const dateISO = new Date(dateObject).toISOString();

  //     const { data: dataAppointment } = await rescheduleAppointment({
  //       variables: {
  //         data: {
  //           appointment: appointmentId,
  //           date: new Date(dateObject).toISOString(),
  //           location,
  //           driver: {
  //             ...driver,
  //             __typename: undefined,
  //             phone: {
  //               ...driver?.phone,
  //               __typename: undefined,
  //             },
  //           },
  //         },
  //       },
  //     });
  //     if (dataAppointment?.rescheduleAppointment) {
  //       setNewAppointmentCreated(true);
  //       notify('Se ha agendado la nueva cita con éxito', 'success');
  //     } else {
  //       notify('Ha ocurrido un error', 'error');
  //     }
  //   } catch (error) {
  //     notify(`Error al al crear la cita: ${error}`, 'error');
  //   } finally {
  //     setDisabled(false);
  //     NProgress.done();
  //   }
  // };

  React.useEffect(() => {
    // get appointments reserved by location and date to get reversed hours of a day
    async function getReservations() {
      if (location && date) {
        // get appointments by location and date
        const _date = stringToDate(date);
        const { data } = await getAvailableSlots({
          variables: {
            data: {
              date: dayjs(_date).startOf('day').toISOString(),
              location,
            },
          },
        });
      }
    }
    getReservations();
  }, [location, date]);

  return (
    <div className="w-full max-w-[1440px] m-auto px-5 pb-10">
      <section className="flex md:flex-row flex-col gap-6">
        {/* left side */}
        <div className="w-full md:w-2/3 mt-1">
          <DateInput
            open
            size="medium"
            value={date}
            setState={(value: string) => {
              setDate(value);
            }}
          />
        </div>
        {/* right side */}
        <div className="w-full ">
          <div
            className="mt-5 md:mt-0 bg-teal-50 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md"
            role="alert"
          >
            <div className="flex">
              <div className="py-1">
                <InfoIcon className=" h-6 w-6 text-teal-500 mr-4" />
              </div>
              <div>
                <p className="font-bold">Servicio de Flush</p>
                <p className="text-sm">
                  Como este servicio tendrá flush, debe escoger dos bloques de
                  horario.
                </p>
              </div>
            </div>
          </div>
          <h4 className="font-semibold text-lg text-primary-300 mt-6">
            Seleccione una hora
          </h4>
          {location && date ? (
            <SelectTime
              time={time}
              setTime={setTime}
              hourSlots={dataGetAvailableSlots?.getAvailableSlots?.slots ?? []}
              loadingReservations={loadingGetAvailableSlots}
              withFlush
            />
          ) : (
            <div className="flex w-full flex-col">
              <ByMyCarIcon className="w-36 h-36  mx-auto" />
              <span className="mx-auto text-center">
                Selecciona día y tienda para conocer la disponibilidad de horas
              </span>
            </div>
          )}
          {/*
          <div className="w-full sm:w-1/2 md:w-1/3 ml-auto">
            <Button className="my-5 " onClick={handleSubmit}>
              Reagendar
            </Button>
          </div> */}
        </div>
      </section>
    </div>
  );
}

export default NewAppointment;
