import React from 'react';
import NProgress from 'nprogress';
import Link from 'next/link';
import {
  ArrowBackIcon,
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  Line,
  GoogleMap,
  LocationOnIcon,
} from '@spa-cars/ui';
import { useMutation } from '@apollo/client';
import { Loader } from '@googlemaps/js-api-loader';
import { useRouter } from 'next/router';
import { Editor } from '@tinymce/tinymce-react';
import {
  Address,
  DocumentModel,
  GoogleMapsMarker,
  Location,
} from '@spa-cars/models';
import { useNotify } from '../../hooks';
import { ACTIONS, reducer } from './reducer';
import {
  CREATE_LOCATION,
  UPDATE_LOCATION,
  UPDATE_LOCATION_CUSTOM,
} from '../../graphql/mutations';
import { DocumentForm } from '../document';
import OperatorTable from './OperatorTable';

interface LocationFormProps {
  location?: Partial<Location>;
  setLocation?: React.Dispatch<React.SetStateAction<Partial<Location>>>;
}

function getAddressObject(
  addressComponents: google.maps.GeocoderAddressComponent[]
) {
  const shouldBeComponent = {
    home: ['street_number'],
    postal_code: ['postal_code'],
    street: ['street_address', 'route'],
    region: [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5',
    ],
    city: [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4',
    ],
    municipality: ['administrative_area_level_2', 'political'],
    zone: ['political', 'sublocality', 'sublocality_level_1'],
    country: ['country'],
  };

  const address = {
    home: '',
    postal_code: '',
    street: '',
    region: '',
    city: '',
    municipality: '',
    country: '',
    zone: '',
  };

  addressComponents.forEach((component) => {
    Object.keys(shouldBeComponent).forEach((shouldBe) => {
      if (shouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
        address[shouldBe] = component.long_name;
      }
    });
  });

  return address;
}

function LocationForm({
  location = null,
  setLocation = null,
}: LocationFormProps) {
  const [state, dispatch] = React.useReducer(reducer, location || {});
  const router = useRouter();
  const [updateLocation] = useMutation<{
    updateLocation: {
      recordId: string;
    };
  }>(UPDATE_LOCATION);
  const [updateLocationCustom] = useMutation<{
    updateLocationCustom: Location;
  }>(UPDATE_LOCATION_CUSTOM);
  const [createLocation] = useMutation<{ createLocation: Location }>(
    CREATE_LOCATION
  );

  const [documents, setDocuments] = React.useState<
    Array<Partial<DocumentModel>>
  >([]);
  const notify = useNotify();
  const [disabled, setDisabled] = React.useState(false);

  const [locationSelected, setLocationSelected] = React.useState<
    GoogleMapsMarker[]
  >([]);

  const loadScript = async (): Promise<void> => {
    const loader = new Loader({
      apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY,
      version: 'weekly',
    });

    await loader.load();
  };

  const handleSelectLocation = async (event: google.maps.MapMouseEvent) => {
    await loadScript();
    const geocoder = new google.maps.Geocoder();
    const newLocation = {
      id: '',
      lng: event.latLng.lng(),
      lat: event.latLng.lat(),
      action: () => {},
    };
    setLocationSelected([newLocation]);

    geocoder
      .geocode({ location: newLocation })
      .then((response) => {
        if (response.results[0]) {
          const addressResponse = response.results[0];
          const addressObject = getAddressObject(
            addressResponse.address_components
          );

          const newAddress = {
            city: addressObject.city,
            country: addressObject.country,
            municipality: addressObject.municipality,
            province: addressObject.region,
            zip: addressObject.postal_code,
            coordinates: {
              coordinates: [event.latLng.lat(), event.latLng.lng()],
            },
            address1: `${
              addressObject.street ? `${addressObject.street},` : ''
            } ${
              addressObject.zone ? `${addressObject.zone},` : ''
            } ${addressObject?.municipality}, ${addressObject.city}, ${
              addressObject.country
            }`,
          } as Address;

          dispatch({
            type: ACTIONS.ADDRESS,
            payload: newAddress,
          });
        } else {
          window.alert('No results found');
        }
      })
      .catch((e) => notify(`Geocoder failed due to: ${e}`, 'error'));
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      NProgress.start();
      setDisabled(true);
      delete state?._id;

      const locationData = {
        ...state,
        photos:
          documents?.length > 0
            ? documents?.map((d) => ({
                src: (d?.src as string) ?? null,
                alt: (d?.name as string) ?? null,
                type: (d?.file?.type as string) ?? null,
              }))
            : null,
        address: {
          ...state?.address,
          __typename: undefined,
          slug: undefined,
          active: undefined,
          coordinates: [
            state?.address?.coordinates?.coordinates[0],
            state?.address?.coordinates?.coordinates[1],
          ],
        },
        booths: parseInt((state?.booths ?? 1)?.toString()),
      };

      if (location) {
        const { data } = await updateLocationCustom({
          variables: {
            data: {
              _id: location._id,
              ...locationData,
              __typename: undefined,
            },
          },
        });
        if (data?.updateLocationCustom?._id) {
          notify('Punto de servicio actualizado con éxito', 'success');
          router.push('/app/locations');
        } else {
          notify(
            'Ha ocurrido un error al actualizar el punto de servicio',
            'error'
          );
        }
      } else {
        const { data } = await createLocation({
          variables: {
            data: {
              ...locationData,
              __typename: undefined,
            },
          },
        });
        if (data?.createLocation?._id) {
          notify('Punto de servicio creado con éxito', 'success');
          router.push(`/app/locations/${data?.createLocation?._id}`);
        } else {
          notify('Ha ocurrido un error al crear el punto de servicio', 'error');
        }
      }
      setDisabled(false);
    } catch (err) {
      notify(err.message, 'error');
      setDisabled(false);
    } finally {
      NProgress.done();
    }
  };
  const updateActiveLocation = async (active: boolean) => {
    try {
      NProgress.start();
      setDisabled(true);
      if (location) {
        const { data } = await updateLocation({
          variables: {
            filter: {
              _id: location._id,
            },
            record: {
              active,
            },
          },
        });
        if (data?.updateLocation?.recordId) {
          notify('Punto de servicio actualizado con éxito', 'success');
          router.push(`/app/locations`);
        } else {
          notify(
            'Ha ocurrido un error al inhabilitar el punto de servicio',
            'error'
          );
        }
      }
      setDisabled(false);
    } catch (err) {
      notify(err.message, 'error');
      setDisabled(false);
    } finally {
      NProgress.done();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({ type: name as any, payload: value });
  };
  React.useEffect(() => {
    if (location) {
      dispatch({ type: ACTIONS.DEFAULT, payload: location });
      setLocationSelected([
        {
          id: (location?.address as Address)?._id,
          lat: (location?.address as Address)?.coordinates?.coordinates[0],
          lng: (location?.address as Address)?.coordinates?.coordinates[1],
          action: () => {},
        },
      ]);
      if (location?.photos?.length > 0) {
        setDocuments(
          location?.photos?.map((photo) => ({
            src: photo?.src,
            name: photo?.alt ?? '',
          }))
        );
      }
    }
  }, [location]);

  function ConvertDDToDMS(coordinate: number) {
    const absolute = Math.abs(coordinate);
    const degrees = Math.floor(absolute);
    const minutesNotTruncated = (absolute - degrees) * 60;
    const minutes = Math.floor(minutesNotTruncated);
    const seconds = Math.floor((minutesNotTruncated - minutes) * 60);
    if (
      Number.isNaN(degrees) ||
      Number.isNaN(minutes) ||
      Number.isNaN(seconds)
    ) {
      return '';
    }
    return ` ${degrees ?? ''}°${minutes ?? ''}'${seconds ?? ''}"`;
  }

  return (
    <form
      method="POST"
      onSubmit={onSubmit}
      className="flex flex-col w-full gap-8"
    >
      <Card>
        <CardHeader>
          {location ? 'Actualizar' : 'Crear'} punto de servicio
          <Link
            href="/app/locations"
            className="flex gap-1 text-success-200 font-medium items-center"
          >
            <ArrowBackIcon className="w-4 h-4" />
            PUNTOS DE SERVICIO
          </Link>
        </CardHeader>

        <Line />
        <CardBody>
          <div className="w-full flex md:flex-row flex-col gap-4">
            <div className="w-full">
              <Input
                name={ACTIONS.NAME}
                value={state?.name ?? ''}
                label="Nombre"
                type="text"
                placeholder="Ingrese un nombre"
                onChange={handleChange}
                required
              />
            </div>
            <div className="w-full">
              <Input
                name={ACTIONS.BOOTHS}
                label="Cantidad de cabinas de servicio"
                type="number"
                min="1"
                step="1"
                required
                value={state?.booths ?? 1}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="flex md:flex-row flex-col">
            <div className="w-full md:w-1/2 pr-2">
              <Input
                name={ACTIONS.INDEXA}
                value={state.indexa ?? ''}
                label="ID de Indexa"
                required
                type="text"
                placeholder="Ingrese un título"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="w-full">
            <label className="block">
              <span className="text-neutral-200 text-sm font-medium mb-2 ">
                Descripción
              </span>
              <Editor
                value={state?.description ?? ''}
                apiKey={process.env.NEXT_PUBLIC_TINY_KEY}
                init={{
                  height: 300,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount',
                    'media',
                    'image',
                  ],
                  toolbar: `undo redo | formatselect | bold italic forecolor backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help`,
                }}
                onEditorChange={(content) =>
                  dispatch({
                    type: ACTIONS.DESCRIPTION as any,
                    payload: String(content),
                  })
                }
              />
            </label>
          </div>
        </CardBody>
      </Card>

      <Card>
        <CardHeader>Imagen del punto de servicio</CardHeader>
        <CardBody>
          <DocumentForm documents={documents} updateURLs={setDocuments} />
        </CardBody>
      </Card>

      <div className="w-full flex gap-[10px] md:flex-row flex-col">
        <Card className="w-full h-fit">
          <div className="px-4 pt-4 flex items-center gap-[10px]">
            <LocationOnIcon className=" w-[20px] h-[20px] text-neutral-100" />
            <span className="text-neutral-100 font-semibold tracking-wide">
              Ubicación
            </span>
          </div>
          <CardBody className="pt-3">
            {state?.address ? (
              <span className=" text-neutral-300 font-medium">
                {(state?.address as Address)?.address1}
              </span>
            ) : (
              <span className=" text-neutral-300 font-medium">
                Seleccione una ubicación en el mapa
              </span>
            )}
            <div className="flex gap-[10px] ">
              <span className=" py-1 px-3  bg-[#EEEEEE] text-neutral-300 font-medium rounded">
                {ConvertDDToDMS(
                  (state?.address as Address)?.coordinates?.coordinates[0]
                )}
                N
              </span>
              <span className=" py-1 px-3  bg-[#EEEEEE] text-neutral-300 font-medium rounded">
                {ConvertDDToDMS(
                  (state?.address as Address)?.coordinates?.coordinates[1]
                )}
                W
              </span>
            </div>
          </CardBody>
        </Card>
        <GoogleMap
          apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY}
          centers={locationSelected}
          handleClick={handleSelectLocation}
          mapContainerStyle={{
            width: '100%',
            height: '334px',
            borderRadius: '8px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
          }}
        />
      </div>
      {location ? (
        <OperatorTable
          locationId={location?._id}
          locationName={location?.name}
        />
      ) : null}

      <div className="flex self-end gap-[30px]">
        {location?.active ? (
          <Button
            type="button"
            className=" w-fit py-3 px-[25px] bg-danger-100"
            disabled={disabled}
            onClick={(e) => {
              e.preventDefault();
              updateActiveLocation(false);
            }}
          >
            Definir como almacén
          </Button>
        ) : (
          location && (
            <Button
              type="button"
              className=" w-fit py-3 px-[25px] bg-white text-primary-400"
              disabled={disabled}
              onClick={(e) => {
                e.preventDefault();
                updateActiveLocation(true);
              }}
            >
              Habilitar
            </Button>
          )
        )}
        <Button
          type="submit"
          className=" w-fit py-3 px-[25px]"
          disabled={disabled}
        >
          {location ? 'Actualizar' : 'Crear'}
        </Button>
      </div>
    </form>
  );
}

export default LocationForm;
