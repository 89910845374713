import React from 'react';
import dayjs from 'dayjs';
import { AnimatePresence, motion } from 'framer-motion';
import { History, User } from '@spa-cars/models';
import { actionTypes } from '../../../lib';

interface HistoryCardProps {
  history: History;
}

export default function HistoryCard({ history }: HistoryCardProps) {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <motion.div
      layout
      className="relative flex flex-col min-w-0 bg-white bg-clip-border shadow-[0_4px_10px_rgba(0,0,0,0.25)] rounded-lg "
    >
      {/* <div className="w-full flex gap-[14px] items-center"> */}
      <motion.li
        onClick={toggleOpen}
        layout
        className="w-full p-4 flex gap-[14px] items-center cursor-pointer"
      >
        <motion.div layout>
          {/* <PointIcon
            className={`w-[20px] h-[20px] ${
              isOpen ? 'text-primary-300' : 'text-text-black'
            }  `}
          /> */}
        </motion.div>
        <motion.div layout style={{ width: '100%' }}>
          <motion.div layout className="w-full flex justify-between">
            <motion.div
              layout
              className={` ${
                isOpen ? 'text-primary-300' : 'text-neutral-100'
              } uppercase`}
            >
              {`${(history?.user as User)?.firstName ?? ''} ${
                (history?.user as User)?.lastName ?? ''
              } ${actionTypes?.find(
                (action) => action.value === history?.actionType
              )?.name}`}
            </motion.div>
            <motion.div
              layout
              className={` ${isOpen ? 'text-primary-300' : 'text-text-black'}`}
            >
              {dayjs(history?.createdAt).format('DD/MM/YYYY - hh:mm A')}
            </motion.div>
          </motion.div>
          <AnimatePresence>
            {isOpen && (
              <motion.div
                layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <p className="text-text-black text-[15px]">
                  {history?.comment}
                </p>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </motion.li>
    </motion.div>
  );
}
