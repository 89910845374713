import React from 'react';
import Link from 'next/link';
import dayjs from 'dayjs';
import { validateString } from 'avilatek-utils';
import { Hit, SearchResponse } from '@algolia/client-search';
import { CheckIcon, CloseIcon, DotIcon, Table, TimerIcon } from '@spa-cars/ui';
import { useLazyQuery } from '@apollo/client';
import {
  Appointment,
  User,
  AppointmentStatusEnum,
  PaginationInfo,
} from '@spa-cars/models';
import { useAlgolia } from '../../hooks';
import { GET_APPOINTMENTS_LIST } from '../../graphql/queries';

const getStatusType = (statusType: AppointmentStatusEnum, date: Date) => {
  switch (statusType) {
    case 'not_started':
      if (dayjs(date).isBefore(dayjs())) return 'Vencida';
      return 'Pendiente';
    case 'in_progress':
      return 'En proceso';
    case 'completed':
      return 'Completada';
    case 'cancelled':
      return 'Cancelada';
    default:
      return 'N/A';
  }
};
const getStatusTypeIcon = (statusType: AppointmentStatusEnum, date: Date) => {
  switch (statusType) {
    case 'not_started':
      if (dayjs(date).isBefore(dayjs())) {
        return <TimerIcon className="h-4 w-4 text-danger-200" />;
      }
      return <DotIcon className="h-4 w-4 text-success-300" />;
    case 'in_progress':
      return <TimerIcon className="h-4 w-4 text-neutral-200" />;
    case 'completed':
      return <CheckIcon className="h-4 w-4 text-success-300" />;
    case 'cancelled':
      return <CloseIcon className="h-4 w-4 text-danger-200" />;
    default:
      return '';
  }
};
const buildTableData = (
  appointments: Array<Appointment | Hit<Appointment>> = []
) =>
  appointments.map((appointment) => ({
    _id: appointment?.order?._id ?? (appointment?.order as any)?.id,
    location: appointment?.location?.name,
    user: `${(appointment?.user as User)?.firstName ?? ''} ${
      (appointment?.user as User)?.lastName ?? ''
    }`,
    createdAt: dayjs(appointment?.createdAt).format('DD/MM/YYYY') ?? 'N/A',
    total: `$${(appointment?.order?.total ?? 0)?.toFixed(2)}` ?? 'N/A',
    status: (
      <p className="flex gap-[10px] items-center">
        {getStatusTypeIcon(appointment?.status, appointment?.date)}
        {getStatusType(appointment?.status, appointment?.date)}
      </p>
    ),
  }));

interface PaginationDataProps {
  items: Array<Appointment>;
  count: number;
  pageInfo: PaginationInfo;
}

interface AppointmentTableProps {
  userId: string;
}

export default function AppointmentTable({ userId }: AppointmentTableProps) {
  const searchAppointment = useAlgolia();
  const searchIndex = searchAppointment.initIndex('appointments');
  const [searchResult, setSearchResult] =
    React.useState<Hit<Appointment>[]>(null);
  const [paginationData, setPaginationData] = React.useState<
    PaginationDataProps | SearchResponse<Appointment>
  >(null);
  const [perPage, setPerPage] = React.useState(10);
  const [searchInput, setSearchInput] = React.useState(''); // guarda lo que el usuario ingresa en el input de busqueda
  const [loadingSearch, setLoadingSearch] = React.useState(false);
  const [loadAppointments, { data, loading, error, fetchMore }] = useLazyQuery<{
    appointmentPagination: {
      items: Array<Appointment>;
      count: number;
      pageInfo: PaginationInfo;
    };
  }>(GET_APPOINTMENTS_LIST);

  const queryVariables = {
    perPage: perPage ?? 10,
    sort: 'CREATEDAT_DESC',
    filter: {
      show: true,
      active: true,
      user: userId,
    },
  };

  const handleGoToPage = (value) => {
    if (validateString(searchInput)) {
      searchAppointments(searchInput, value - 1);
    } else {
      loadAppointments({
        variables: {
          page: value ?? 1,
          ...queryVariables,
        },
        fetchPolicy: 'cache-and-network',
      });
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <p className="flex justify-between items-center">
            CLIENTE
            {/* <MenuIcon className="w-5 h-5 text-neutral-100" /> */}
          </p>
        ),

        accessor: 'user',
      },
      {
        Header: (
          <p className="flex justify-between items-center">
            PUNTO DE SERVICIO
            {/* <MenuIcon className="w-5 h-5 text-neutral-100" /> */}
          </p>
        ),

        accessor: 'location',
      },

      {
        Header: (
          <p className="flex justify-between items-center">
            CREACIÓN
            {/* <MenuIcon className="w-5 h-5 text-neutral-100" /> */}
          </p>
        ),
        accessor: 'createdAt',
      },
      {
        Header: (
          <p className="flex justify-between items-center">
            TOTAL
            {/* <MenuIcon className="w-5 h-5 text-neutral-100" /> */}
          </p>
        ),
        accessor: 'total',
      },
      {
        Header: (
          <p className="flex justify-between items-center">
            ESTATUS
            {/* <MenuIcon className="w-5 h-5 text-neutral-100" /> */}
          </p>
        ),
        accessor: 'status',
      },
    ],
    []
  );

  const filterResults = React.useCallback(
    (results: SearchResponse<Appointment>) =>
      results?.hits?.filter((aa) => aa?.order) ?? [],
    []
  );

  const searchAppointments = async (value: string, page_: number) => {
    try {
      if (!validateString(value) || value === '') {
        loadAppointments({
          variables: {
            page: 1,
            ...queryVariables,
          },
          fetchPolicy: 'cache-and-network',
        });
        setPaginationData(data?.appointmentPagination);
        setSearchResult([]);
        return;
      }
      setLoadingSearch(true);
      const result = await searchIndex.search<Appointment>(value, {
        facetFilters: [`show:true`, `active:true`, `user.id:${userId}`],
        page: page_,
        hitsPerPage: perPage ?? 10,
      });
      setLoadingSearch(false);
      setPaginationData(result);
      const resultsFiltered = filterResults(result);
      setSearchResult(resultsFiltered);
    } catch (err) {
      console.log(err);
    }
  };

  const onChangeSearch = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setSearchInput(e.target.value);
    },
    []
  );

  const dataMemo = React.useMemo(
    () =>
      searchResult?.length > 0
        ? buildTableData(searchResult)
        : buildTableData(
            (paginationData as PaginationDataProps)?.items?.filter(
              (aa) => aa?.order
            )
          ) ?? [],
    [paginationData, searchResult]
  );

  React.useEffect(() => {
    if (data) {
      setPaginationData(data?.appointmentPagination);
    }
  }, [data]);

  React.useEffect(() => {
    searchAppointments(searchInput, 0); // para algolia, la paginación empieza en 0
  }, [searchInput, perPage, userId]);

  return (
    <div className="flex flex-col gap-4">
      <Table
        columns={columns}
        data={dataMemo}
        perPage={perPage}
        setPerPage={setPerPage}
        error={null}
        loading={loading || loadingSearch}
        pageCount={0}
        header="Historial de servicios"
        pageNumber={
          ((paginationData as SearchResponse<Appointment>)?.page ??
            ((paginationData as PaginationDataProps)?.pageInfo?.currentPage ??
              1) - 1) + 1
        } //  Si la paginacion viene de algolia, se le suma 1 para que coincida con el numero de pagina que viene de mongoose
        addButtonText="Servicio"
        addButtonPath="/app/walkin"
        handleGoToPage={handleGoToPage}
        results={
          (paginationData as SearchResponse<Appointment>)?.nbHits ??
          (paginationData as PaginationDataProps)?.count
        }
        path="app/orders"
        link={Link}
        onChangeSearch={onChangeSearch}
      />
    </div>
  );
}
