import React from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import { User } from '@spa-cars/models';
import { GET_USER } from '../../graphql/queries';
import { Layout } from '../layout';
import OperatorForm from './OperatorForm';
import { IRoute } from '../../routes';
import { Loading } from '../shared';

interface UpdateOperatorFormProps {
  prevRoute?: Partial<IRoute>;
}

function UpdateOperatorForm({ prevRoute = null }: UpdateOperatorFormProps) {
  const router = useRouter();
  const { data, loading, error, refetch } = useQuery<{ user: User }>(GET_USER, {
    variables: {
      filter: { _id: router.query._id as string, userType: 'mechanic' },
    },
    fetchPolicy: 'cache-and-network',
  });
  const [operator, setOperator] = React.useState<Partial<User>>(
    data?.user ?? null
  );

  React.useEffect(() => {
    if (!loading && data?.user) {
      setOperator(data.user);
    } else if (!loading && !data?.user) {
      router.push('/app/operators');
    }
  }, [data?.user]);

  return (
    <Layout
      titlePhoto={operator?.photo?.src ?? '/img/placeholder/user.png'}
      title={`${operator?.firstName ?? ''} ${operator?.lastName ?? ''}`}
      routesHistory={[
        prevRoute,
        {
          text: 'Perfil del operador',
          href: prevRoute
            ? `${prevRoute?.href}/operator/${router.query._id}`
            : `/app/operators/${router.query._id}`,
          key: 'update',
        },
      ]}
    >
      {!loading && data ? (
        <OperatorForm
          operator={data.user}
          setOperator={setOperator}
          prevLink={prevRoute?.href ?? null}
          prevLinkName={prevRoute?.text ?? null}
          refetch={refetch}
        />
      ) : (
        <Loading />
      )}
    </Layout>
  );
}

export default UpdateOperatorForm;
