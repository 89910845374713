export type TTotal = {
  subtotal?: number;
  totalTax?: number;
  total?: number;
  discountTotal?: number;
};

export const InitialState = {
  pdvRef: '',
  confirmedForm: false,
  inPaymentProcess: false,
  appliedDiscounts: [],
  total: {
    subtotal: 0,
    totalTax: 0,
    total: 0,
    discountTotal: 0,
  },
};

export interface PaymentState {
  pdvRef: string;
  confirmedForm: boolean;
  inPaymentProcess: boolean;
  total: TTotal;
  TOTAL: 'TOTAL';
}

export const ACTIONS = {
  PDV_REF: 'PDV_REF',
  CONFIRMED_FORM: 'CONFIRMED_FORM',
  IN_PAYMENT_PROCESS: 'IN_PAYMENT_PROCESS',
  TOTAL: 'TOTAL',
  RESET: 'RESET',
  DEFAULT: 'DEFAULT',
} as const;

export type ACTIONS_TYPE =
  | { type: 'PDV_REF'; payload: string }
  | { type: 'CONFIRMED_FORM'; payload: boolean }
  | { type: 'IN_PAYMENT_PROCESS'; payload: boolean }
  | { type: 'TOTAL'; payload: TTotal }
  | { type: 'RESET'; payload: Record<string, never> }
  | { type: 'DEFAULT'; payload: Partial<PaymentState> };

export function reducer(
  state: Partial<PaymentState>,
  action: ACTIONS_TYPE
): Partial<PaymentState> {
  switch (action.type) {
    case ACTIONS.PDV_REF:
      return { ...state, pdvRef: action.payload };
    case ACTIONS.CONFIRMED_FORM:
      return { ...state, confirmedForm: action.payload };
    case ACTIONS.IN_PAYMENT_PROCESS:
      return { ...state, inPaymentProcess: action.payload };
    case ACTIONS.DEFAULT:
      return { ...state, ...(action?.payload ?? {}) };
    case ACTIONS.TOTAL:
      return { ...state, total: action.payload };
    case ACTIONS.RESET:
      return {};
    default:
      return { ...state };
  }
}
