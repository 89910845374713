import React from 'react';
import NProgress from 'nprogress';
import Link from 'next/link';
import PhoneInput from 'react-phone-input-2';
import {
  AfternoonIcon,
  ArrowBackIcon,
  Button,
  Card,
  CardBody,
  CardHeader,
  EyeIcon,
  Input,
  Line,
  Select,
  MorningIcon,
  DniInput,
} from '@spa-cars/ui';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client/react';
import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import { validateEmail, validateString } from 'avilatek-utils';
import { Editor } from '@tinymce/tinymce-react';
import { DocumentModel, Location, User } from '@spa-cars/models';
import { DocumentForm } from '../document';
import { ACTIONS, reducer } from './reducer';
import { useNotify, useUser } from '../../hooks';
import { GET_LOCATIONS } from '../../graphql/queries';
import { CREATE_USER, UPDATE_USER } from '../../graphql/mutations';
import { dniTypes } from '../../lib';

interface OperatorFormProps {
  operator?: Partial<User>;
  setOperator?: React.Dispatch<React.SetStateAction<Partial<User>>>;
  prevLink?: string;
  prevLinkName?: string;
  refetch?: (variables?: Partial<OperationVariables>) => Promise<
    ApolloQueryResult<{
      user: User;
    }>
  >;
}
function OperatorForm({
  operator,
  setOperator,
  prevLink = null,
  prevLinkName = null,
  refetch = null,
}: OperatorFormProps) {
  const [state, dispatch] = React.useReducer(
    reducer,
    operator
      ? {
          ...operator,
          phone: { phone: `${operator.phone.code}${operator.phone.phone}` },
        }
      : { dniType: 'V' }
  );

  const notify = useNotify();
  const router = useRouter();
  const [user] = useUser();
  const [showPassword, setShowPassword] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);

  const [documents, setDocuments] = React.useState<
    Array<Partial<DocumentModel>>
  >(operator?.photo?.src ? [{ src: operator.photo.src }] : []);
  const { data: locationsData } = useQuery<{ locations: Location[] }>(
    GET_LOCATIONS,
    {
      variables: {
        filter: {
          active: true,
          _id:
            user?.userType === 'admin'
              ? (user?.location as Location)?._id
              : undefined,
        },
      },
      fetchPolicy: 'cache-and-network',
    }
  );
  const [updateUser] = useMutation<{
    updateUser: {
      recordId: string;
    };
  }>(UPDATE_USER);

  const [createUser] = useMutation<{
    createUser: {
      _id: string;
    };
  }>(CREATE_USER);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      NProgress.start();
      setDisabled(true);
      delete state?._id;
      if (disabled) {
        return;
      }
      if (
        !validateString(state.firstName) ||
        String(state.firstName).length > 127
      ) {
        return notify('Su nombre es inválido', 'error');
      }

      if (
        !validateString(state.lastName) ||
        String(state.lastName).length > 127
      ) {
        return notify('Su apellido es inválido', 'error');
      }

      if (!validateEmail(state.email)) {
        return notify('Su correo electrónico es inválido', 'error');
      }

      // if (
      //   !validatePhone(state.phone.phone) ||
      //   String(state.phone.phone).length > 127
      // ) {
      //   return notify('Su teléfono es inválido', 'error');
      // }

      if (String(state.dni).length > 8) {
        return notify('Su cédula es inválida', 'error');
      }

      if (!validateString(state.shift)) {
        return notify('Su turno es inválido', 'error');
      }

      if (state?.password && String(state?.password).length < 8 && !operator) {
        return notify(
          'La contraseña debe contener al menos 8 caracteres',
          'error'
        );
      }

      let localPhone = null;
      let phoneCode = null;

      if (state.phone.phone?.length > 3) {
        localPhone = state.phone.phone.slice(
          state.phone.phone.length - 10,
          state.phone.phone.length
        );
        phoneCode = state.phone.phone.slice(0, state.phone.phone.length - 10);
      }

      if (operator) {
        const { data } = await updateUser({
          variables: {
            filter: {
              _id: operator._id,
            },
            record: {
              firstName: state.firstName,
              password: state?.password ?? undefined,
              lastName: state.lastName,
              email: state.email?.toLowerCase(),
              dni: state.dni,
              dniType: state?.dniType ?? 'V',
              shift: state.shift,
              location: state?.location,
              phone: {
                phone: localPhone,
                code: phoneCode,
              },
              photo: {
                src: (documents[0]?.src as string) ?? null,
                alt: (documents[0]?.name as string) ?? null,
                type: (documents[0]?.file?.type as string) ?? null,
              },
            },
          },
        });
        if (data?.updateUser?.recordId) {
          notify('Operador actualizado con éxito', 'success');
          router.back();
        } else {
          notify('Ha ocurrido un error al actualizar el cliente', 'error');
        }
      } else {
        const { data } = await createUser({
          variables: {
            data: {
              firstName: state.firstName,
              lastName: state.lastName,
              email: state.email?.toLowerCase(),
              password: state.password,
              dni: state.dni,
              dniType: state.dniType,
              shift: state.shift,
              userType: 'mechanic',
              location: state.location,
              phone: {
                phone: localPhone,
                code: phoneCode,
              },
              photo: {
                src: (documents[0]?.src as string) ?? null,
                alt: (documents[0]?.name as string) ?? null,
                type: (documents[0]?.file?.type as string) ?? null,
              },
            },
          },
        });

        if (data?.createUser?._id) {
          notify('Operador creado con éxito', 'success');
          router.push(`/app/operators/${data?.createUser?._id}`);
        } else {
          notify('Ha ocurrido un error al crear el operador', 'error');
        }
      }
    } catch (err) {
      notify(err.message, 'error');
    } finally {
      setDisabled(false);
      NProgress.done();
    }
  };

  const updateActiveOperator = async (active: boolean) => {
    try {
      NProgress.start();
      setDisabled(true);
      let shift = null;
      if (active) {
        shift = state?.shift;
      }
      if (operator) {
        const { data } = await updateUser({
          variables: {
            filter: {
              _id: operator._id,
            },
            record: {
              active,
              shift,
            },
          },
        });
        if (data?.updateUser?.recordId) {
          notify('Operador actualizado con éxito', 'success');
          router.back();
        } else {
          notify('Ha ocurrido un error al inhabilitar el operador', 'error');
        }
      }
      setDisabled(false);
    } catch (err) {
      notify(err.message, 'error');
      setDisabled(false);
    } finally {
      NProgress.done();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({ type: name as any, payload: value });
  };

  const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    dispatch({ type: name as any, payload: value });
  };

  React.useEffect(() => {
    if (operator) {
      dispatch({ type: ACTIONS.DEFAULT, payload: operator });
      dispatch({
        type: ACTIONS.LOCATION,
        payload: (operator?.location as Location)?._id,
      });
      if (operator?.photo?.src) {
        setDocuments([
          { src: operator?.photo?.src, name: operator?.photo?.alt },
        ]);
      }
      dispatch({
        type: ACTIONS.PHONE,
        payload: `${operator?.phone?.code ?? ''}${
          operator?.phone?.phone ?? ''
        }`,
      });
      if (!operator?.phone?.phone) {
        dispatch({ type: ACTIONS.PHONE, payload: '+58' });
      }
    } else {
      dispatch({ type: ACTIONS.PHONE, payload: '+58' });
    }
  }, [operator]);

  return (
    <form
      method="POST"
      onSubmit={onSubmit}
      className="flex flex-col w-full gap-8"
    >
      <Card>
        <CardHeader>
          {operator ? 'Actualizar' : 'Crear'} operador
          <Link
            href={prevLink ?? '/app/operators'}
            className="flex gap-1 text-success-200 font-medium items-center uppercase"
          >
            <ArrowBackIcon className="w-4 h-4" />
            {prevLinkName ?? 'OPERADORES'}
          </Link>
        </CardHeader>
        <Line />
        <CardBody>
          <div className="w-full flex md:flex-row flex-col gap-4">
            <div className="w-full">
              <Input
                name={ACTIONS.FIRST_NAME}
                value={state?.firstName ?? ''}
                label="Nombre"
                type="text"
                placeholder="Ingrese un nombre"
                onChange={handleChange}
                required
              />
            </div>
            <div className="w-full">
              <Input
                name={ACTIONS.LAST_NAME}
                value={state?.lastName ?? ''}
                label="Apellido"
                type="text"
                placeholder="Ingrese un apellido"
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className=" flex md:flex-row flex-col gap-4">
            <div className="w-full">
              <DniInput
                selectName={ACTIONS.DNI_TYPE}
                selectValue={state?.dniType}
                label="Cédula de identidad"
                handleChangeSelect={handleChangeSelect}
                defaultSelect={dniTypes[0].value}
                inputName={ACTIONS.DNI}
                inputValue={state?.dni ?? ''}
                placeholder="Ingrese una cédula de identidad"
                handleChange={handleChange}
              >
                {dniTypes.map((option) => (
                  <option value={option.name} key={option.name}>
                    {option.name}
                  </option>
                ))}
              </DniInput>
            </div>
            <div className="w-full">
              <Input
                name={ACTIONS.EMAIL}
                value={state?.email ?? ''}
                label="Email"
                type="text"
                placeholder="Ingrese un email"
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className=" flex md:flex-row flex-col gap-4">
            <div className="w-full">
              <span className="text-neutral-200 text-sm font-medium mb-2 ">
                Teléfono
              </span>
              <PhoneInput
                inputStyle={{
                  appearance: 'none',
                  height: '24px',
                  background: 'transparent',
                  width: '100%',
                  border: 'solid',
                  borderWidth: '0.5px',
                  borderColor: '#A6A6A6',
                  borderRadius: '4px',
                  minHeight: '40px',
                  fontSize: '16px',
                  color: '#606060',
                  fontWeight: '300',
                }}
                countryCodeEditable={false}
                value={state?.phone?.phone}
                placeholder="Ingrese un teléfono"
                onChange={(phone) => {
                  dispatch({ type: ACTIONS.PHONE, payload: phone });
                }}
              />
            </div>
            <div className="w-full">
              <Input
                name={ACTIONS.PASSWORD}
                value={state?.password ?? ''}
                label="Cambiar contraseña"
                type={showPassword ? 'text' : 'password'}
                placeholder={
                  operator
                    ? 'Cambiar contraseña actual'
                    : 'Ingrese un una contraseña'
                }
                onChange={handleChange}
                rightIcon={
                  <EyeIcon
                    className="w-5 h-5 cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowPassword(!showPassword);
                    }}
                  />
                }
              />
            </div>
          </div>
          <div className=" flex md:flex-row flex-col gap-4">
            <div className="w-full md:w-1/2 ">
              <Select
                name={ACTIONS.LOCATION}
                value={(state?.location as string) ?? ''}
                label="Punto de servicio"
                onChange={handleChangeSelect}
              >
                <option value="" disabled>
                  Seleccione un punto de servicio
                </option>
                {locationsData?.locations.map((location) => (
                  <option key={location._id} value={location._id}>
                    {location.name}
                  </option>
                ))}
              </Select>
            </div>
            <label className="block w-full md:w-1/2">
              <span className="text-neutral-200 text-sm font-medium mb-2 ">
                Turno
              </span>
              <div className="gap-4 flex">
                <button
                  type="button"
                  className={`flex justify-center items-center gap-2 py-2 px-4 rounded w-full  shadow-lg font-medium tracking-wide ${
                    state.shift === 'morning'
                      ? 'text-text-white bg-primary-500'
                      : 'text-text-black bg-neutral-100'
                  } `}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch({ type: ACTIONS.SHIFT, payload: 'morning' });
                  }}
                >
                  <MorningIcon className={`w-5 h-5 ${'text-[#E2C200]'}`} />{' '}
                  Mañana
                </button>
                <button
                  type="button"
                  className={`flex justify-center items-center gap-2 py-2 px-4 rounded w-full  shadow-lg font-medium tracking-wide ${
                    state.shift === 'afternoon'
                      ? 'text-text-white bg-primary-500'
                      : 'text-text-black bg-neutral-100'
                  } `}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch({ type: ACTIONS.SHIFT, payload: 'afternoon' });
                  }}
                >
                  <AfternoonIcon className="w-5 h-5" />
                  Tarde
                </button>
              </div>
            </label>
          </div>
          <div className="w-full">
            <label className="block">
              <span className="text-neutral-200 text-sm font-medium mb-2 ">
                Observaciones
              </span>
              <Editor
                value={state?.observation ?? ''}
                apiKey={process.env.NEXT_PUBLIC_TINY_KEY}
                init={{
                  height: 300,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount',
                    'media',
                    'image',
                  ],
                  toolbar: `undo redo | formatselect | bold italic forecolor backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help`,
                }}
                onEditorChange={(content) =>
                  dispatch({
                    type: ACTIONS.OBSERVATION as any,
                    payload: String(content),
                  })
                }
              />
            </label>
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>Imagen de perfil</CardHeader>
        <CardBody>
          <DocumentForm
            documents={documents}
            updateURLs={setDocuments}
            maximumAmount={1}
          />
        </CardBody>
      </Card>
      <div className="flex self-end gap-[30px]">
        {operator?.active ? (
          <Button
            type="button"
            className=" w-fit py-3 px-[25px] bg-danger-100"
            disabled={disabled}
            onClick={(e) => {
              e.preventDefault();
              updateActiveOperator(false);
            }}
          >
            Banear
          </Button>
        ) : (
          operator && (
            <Button
              type="button"
              className=" w-fit py-3 px-[25px] bg-white text-primary-400"
              disabled={disabled}
              onClick={(e) => {
                e.preventDefault();
                updateActiveOperator(true);
              }}
            >
              Activar
            </Button>
          )
        )}
        <Button
          type="submit"
          className=" w-fit py-3 px-[25px]"
          disabled={disabled}
        >
          {operator ? 'Actualizar' : 'Crear'}
        </Button>
      </div>
    </form>
  );
}

export default OperatorForm;
