export const appointmentStatusTypes = [
  {
    name: 'Pendiente',
    value: 'not_started',
  },
  {
    name: 'En proceso',
    value: 'in_progress',
  },
  {
    name: 'Completada',
    value: 'completed',
  },
  {
    name: 'Cancelada',
    value: 'cancelled',
  },
];
