import { Tax } from '@spa-cars/models';

export const ACTIONS = {
  NAME: 'NAME',
  VALUE: 'VALUE',
  DEFAULT: 'DEFAULT',
} as const;

export type ACTIONS_TYPE =
  | { type: 'NAME'; payload: string }
  | { type: 'VALUE'; payload: number }
  | { type: 'DEFAULT'; payload: Partial<Tax> };

export function reducer(
  state: Partial<Tax>,
  action: ACTIONS_TYPE
): Partial<Tax> {
  switch (action.type) {
    case ACTIONS.NAME:
      return { ...state, name: action.payload };
    case ACTIONS.VALUE:
      return { ...state, value: action.payload };
    case ACTIONS.DEFAULT:
      return { ...state, ...(action?.payload ?? {}) };
    default:
      return { ...state };
  }
}
