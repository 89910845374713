import {
  Order,
  User,
  Appointment,
  Location,
  Vehicle,
  Driver,
} from '@spa-cars/models';

export const ACTIONS = {
  DATE: 'DATE',
  USER: 'USER',
  LOCATION: 'LOCATION',
  DRIVER: 'DRIVER',
  VEHICLE: 'VEHICLE',
  ORDER: 'ORDER',
  DEFAULT: 'DEFAULT',
} as const;

export type ACTIONS_TYPE =
  | { type: 'DATE'; payload: Date }
  | { type: 'USER'; payload: User }
  | { type: 'LOCATION'; payload: Location }
  | { type: 'DRIVER'; payload: Driver }
  | { type: 'VEHICLE'; payload: Vehicle }
  | { type: 'ORDER'; payload: Order }
  | { type: 'DEFAULT'; payload: Partial<Appointment> };

export function reducer(
  state: Partial<Appointment>,
  action: ACTIONS_TYPE
): Partial<Appointment> {
  switch (action.type) {
    case ACTIONS.DATE:
      return { ...state, date: action.payload };
    case ACTIONS.USER:
      return { ...state, user: action.payload };
    case ACTIONS.LOCATION:
      return { ...state, location: action.payload };
    case ACTIONS.DRIVER:
      return { ...state, driver: action.payload };
    case ACTIONS.VEHICLE:
      return { ...state, vehicle: action.payload };
    case ACTIONS.ORDER:
      return { ...state, order: action.payload };

    case ACTIONS.DEFAULT:
      return { ...state, ...(action?.payload ?? {}) };
    default:
      return { ...state };
  }
}
