import React from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import { Appointment, Repair, User, Vehicle } from '@spa-cars/models';
import { Layout } from '../layout';
import { GET_APPOINTMENT, GET_REPAIR } from '../../graphql/queries';
import OrderForm from './OrderForm';
import { IRoute } from '../../routes';
import { Loading } from '../shared';

interface UpdateOrderFormProps {
  prevRoute?: Partial<IRoute>;
}

export default function UpdateAppointmentForm({
  prevRoute = null,
}: UpdateOrderFormProps) {
  const router = useRouter();
  const { data, loading, error, refetch } = useQuery<{
    appointment: Appointment;
  }>(GET_APPOINTMENT, {
    variables: {
      filter: { order: router.query._id as string },
    },
    fetchPolicy: 'cache-and-network',
  });
  const {
    data: dataRepair,
    loading: loadingRepair,
    error: errorRepair,
    refetch: refetchRepair,
  } = useQuery<{
    getRepair: { repair: Repair; mechanic: User; vehicle: Vehicle };
  }>(GET_REPAIR, {
    variables: {
      data: { appointment: data?.appointment?._id ?? '' },
    },
    fetchPolicy: 'cache-and-network',
  });
  const [appointment, setAppointment] = React.useState<Partial<Appointment>>(
    data?.appointment ?? null
  );

  React.useEffect(() => {
    if (data?.appointment) {
      setAppointment(data.appointment);
    }
  }, [data?.appointment]);

  return (
    <Layout
      title={`${
        appointment?.reservationNumber
          ? `Servicio #${appointment?.reservationNumber}`
          : 'Perfil del servicio'
      }`}
      routesHistory={[
        prevRoute,
        {
          text: 'Perfil del servicio',
          href: prevRoute
            ? `${prevRoute?.href}/order/${router.query._id}`
            : `/app/orders/${router.query._id}`,
          key: 'update',
        },
      ]}
    >
      {loading || loadingRepair ? (
        <Loading />
      ) : (
        <OrderForm
          dataRepair={dataRepair}
          refetchRepair={refetchRepair}
          appointment={appointment}
          refetchAppointment={refetch}
          setAppointment={setAppointment}
        />
      )}
    </Layout>
  );
}
