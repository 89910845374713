import { Multimedia, User } from '@spa-cars/models';

export const ACTIONS = {
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME',
  EMAIL: 'EMAIL',
  PASSWORD: 'PASSWORD',
  DNI: 'DNI',
  DNI_TYPE: 'DNI_TYPE',
  PHONE: 'PHONE',
  PHONE_CODE: 'PHONE_CODE',
  PHOTO: 'PHOTO',
  CLIENT_ORDERS: 'CLIENT_ORDERS',
  DEFAULT: 'DEFAULT',
} as const;

export type ACTIONS_TYPE =
  | { type: 'FIRST_NAME'; payload: string }
  | { type: 'LAST_NAME'; payload: string }
  | { type: 'EMAIL'; payload: string }
  | { type: 'PASSWORD'; payload: string }
  | { type: 'DNI'; payload: string }
  | { type: 'DNI_TYPE'; payload: string }
  | { type: 'PHONE'; payload: string }
  | { type: 'PHONE_CODE'; payload: string }
  | { type: 'PHOTO'; payload: Multimedia }
  | { type: 'CLIENT_ORDERS'; payload: string[] }
  | { type: 'DEFAULT'; payload: Partial<User> };

export function reducer(
  state: Partial<User>,
  action: ACTIONS_TYPE
): Partial<User> {
  switch (action.type) {
    case ACTIONS.FIRST_NAME:
      return { ...state, firstName: action.payload };
    case ACTIONS.LAST_NAME:
      return { ...state, lastName: action.payload };
    case ACTIONS.EMAIL:
      return { ...state, email: action.payload };
    case ACTIONS.PASSWORD:
      return { ...state, password: action.payload };
    case ACTIONS.DNI:
      return { ...state, dni: action.payload };
    // case ACTIONS.DNI_TYPE:
    //   return { ...state, dniType: action.payload };
    case ACTIONS.PHONE:
      return {
        ...state,
        phone: { ...(state?.phone ?? {}), phone: action.payload },
      };
    case ACTIONS.PHONE_CODE:
      return {
        ...state,
        phone: { ...(state?.phone ?? {}), code: action.payload },
      };
    case ACTIONS.PHOTO:
      return { ...state, photo: action.payload };

    // case ACTIONS.CLIENT_ORDERS:
    //   return {
    //     ...state,
    //     client: { ...(state?.client as Client), orders: action.payload },
    //   };

    case ACTIONS.DEFAULT:
      return { ...state, ...(action?.payload ?? {}) };
    default:
      return { ...state };
  }
}
