import React from 'react';
import axios from 'axios';
import NProgress from 'nprogress';
import { useDrag, useDrop } from 'react-dnd';
import { useMutation } from '@apollo/client';
import { CloseIcon, PageIcon } from '@spa-cars/ui';
import { DocumentModel } from '@spa-cars/models';
import { SIGN_S3 } from '../../graphql/mutations';
import { useNotify } from '../../hooks';

const type = 'Image';

interface ImageProps {
  file?: File;
  id: string;
  name: string;
  src?: string;
  index?: number;
  onDelete?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  moveImage?: (dragIndex: number, hoverIndex: number) => void;
  updateURLs?: React.Dispatch<React.SetStateAction<DocumentModel[]>>;
  updateSrc?: (id: string, src: string, name: string) => void;
  route?: string;
  down?: boolean;
  image?: boolean;
}

function Image({
  file,
  src,
  id,
  index,
  name,
  onDelete,
  moveImage,
  updateURLs,
  updateSrc,
  down,
  route,
  image = true,
}: ImageProps) {
  const notify = useNotify();
  const [signS3] = useMutation(SIGN_S3);
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [uploading, setUploading] = React.useState(false);
  React.useEffect(() => {
    if (src && !src.startsWith('http')) {
      const upload = async () => {
        try {
          setUploading(true);
          NProgress.start();

          const { data } = await signS3({
            variables: {
              data: {
                filename: file?.name,
                filetype: file?.type,
              },
            },
          });

          const options = {
            headers: {
              'Content-Type': file?.type,
              'x-amz-acl': 'public-read',
            },
          };

          const response = await axios.put(
            data?.signS3?.signedRequest,
            file,
            options
          );

          if (response) {
            updateURLs((urls) => {
              const i = urls?.findIndex((_url) => _url?.id === id);
              if (i === -1) {
                return [...urls, { src: data?.signS3?.url, name, id, file }];
              }
              return urls;
            });
            updateSrc(id, data?.signS3?.url, name);
            notify('Archivo agregado exitosamente', 'success');
          } else {
            notify(`Error cargando el archivo ${name}`, 'error');
          }
        } catch (err) {
          notify(`Error cargando el archivo ${name}`, 'error');
        } finally {
          setUploading(false);
          NProgress.done();
        }
      };
      upload();
    }
  }, [src, updateURLs, index, id, updateSrc, name]);

  const [, drop] = useDrop({
    accept: type,
    hover(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = (item as any).index as number;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      moveImage(dragIndex, hoverIndex);
      (item as any).index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type,
    item: () => ({ type, id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));

  return (
    <div
      className={`relative bg-white flex flex-row  rounded  ${
        isDragging || uploading ? 'opacity-25' : 'opacity-100'
      } ${down ? 'justify-center' : 'items-start justify-start'} ${
        image ? 'items-start  justify-between mr-4 ' : 'w-full h-14 p-2'
      }`}
      ref={ref}
    >
      <a
        download
        target="_blank"
        rel="noopener noreferrer"
        href={src}
        className={`text-sm truncate flex items-start flex-row overflow-hidden ${
          image ? ' w-[60px] h-[60px] ' : 'ml-4 w-11/12'
        }`}
      >
        {image ? (
          // eslint-disable-next-line @next/next/no-img-element
          <img
            src={src}
            alt={name}
            className="h-full w-full object-cover object-center rounded-lg"
          />
        ) : (
          <>
            <PageIcon className="w-5 h-5 mr-3" />
            {name}
          </>
        )}
      </a>
      <button
        type="button"
        className={`${
          image ? 'absolute -right-2 -top-2' : ''
        } p-1 bg-danger-200 rounded-full`}
        onClick={onDelete}
        data-id={id}
        data-index={index}
      >
        <CloseIcon className="w-3 h-3 text-neutral-50" />
      </button>
    </div>
  );
}

export default Image;
