import React from 'react';
import { validateString } from 'avilatek-utils';
import { AnimatePresence, motion } from 'framer-motion';
import { CheckIcon, Line } from '@spa-cars/ui';
import { OrderProduct } from '@spa-cars/models';
import OwnProductCheck from './OwnProductCheck';

interface ClientSelectionCardProps {
  items: Partial<OrderProduct>[];
  ownFilter?: boolean;
  ownOil?: boolean;
  ownFlush?: boolean;
}

export default function ClientSelectionCard({
  items,
  ownFilter,
  ownOil,
  ownFlush,
}: ClientSelectionCardProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);
  return (
    <motion.li
      onClick={toggleOpen}
      layout
      transition={{ type: 'spring', bounce: 0 }}
      className="w-full p-4 relative flex flex-col min-w-0 bg-white bg-clip-border shadow-[0_4px_10px_rgba(0,0,0,0.25)] rounded-lg "
    >
      <motion.div layout className={` w-full `}>
        <h2 className={`  text-sm ${'text-neutral-100'}`}>
          SELECCIÓN DEL CLIENTE
        </h2>
      </motion.div>
      <AnimatePresence>
        {true && (
          <motion.div
            layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex flex-col  gap-2 max-h-[382px] overflow-y-auto w-full overflow-x-none mt-4"
          >
            <Line />

            {items?.map((item) => (
              <>
                <div className="flex items-center gap-4" key={item?._id}>
                  {validateString(item?.variant?.photo?.src) ? (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="w-fit"
                      onClick={(e) => {
                        if (e.stopPropagation) e.stopPropagation();
                      }}
                      href={item?.variant?.photo?.src ?? ''}
                    >
                      <motion.img
                        src={item?.variant?.photo?.src ?? ''}
                        alt="img"
                        className="h-[48px] w-[48px] object-contain object-center rounded-lg z-50 "
                      />
                    </a>
                  ) : null}
                  <div className="">
                    <p className="text-text-black text-sm">
                      <p className="font-medium inline">Producto: </p>
                      {item?.title}
                    </p>
                    <p className="text-text-black text-sm ">
                      <p className="font-medium inline">Variante: </p>
                      {`${item?.variant?.option?.option1 ?? ''} ${
                        item?.variant?.option?.option2 ?? ''
                      } ${item?.variant?.option?.option3 ?? ''} `}
                    </p>
                    <p className="text-text-black text-sm ">
                      <p className="font-medium inline">Marca: </p>
                      {item?.product?.brand}
                    </p>
                    <p className="text-text-black text-sm ">
                      <p className="font-medium inline">Cantidad: </p>{' '}
                      {item?.quantity}
                    </p>
                  </div>
                </div>
                <Line />
              </>
            ))}
            <div className="flex justify-around">
              {ownOil ? (
                <OwnProductCheck label="El cliente trajo el aceite" />
              ) : null}
              {ownFilter ? (
                <OwnProductCheck label="El cliente trajo el filtro" />
              ) : null}
              {ownFlush ? (
                <OwnProductCheck label="El cliente trajo el flush" />
              ) : null}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.li>
  );
}
