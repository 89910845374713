import React from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import { Currency } from '@spa-cars/models';
import CurrencyForm from './CurrencyForm';
import { Layout } from '../layout';
import { GET_CURRENCY } from '../../graphql/queries';
import { Loading } from '../shared';

export default function UpdateCurrencyForm() {
  const router = useRouter();
  const { data, loading } = useQuery<{ currency: Currency }>(GET_CURRENCY, {
    variables: {
      filter: { _id: router.query._id as string },
    },
    fetchPolicy: 'cache-and-network',
  });
  const [currency, setCurrency] = React.useState<Partial<Currency>>(
    data?.currency ?? null
  );

  React.useEffect(() => {
    if (data?.currency) {
      setCurrency(data.currency);
    }
  }, [data?.currency]);

  return (
    <Layout
      title={currency?.name ?? 'Perfil de la moneda'}
      routesHistory={[
        {
          text: 'Perfil de la moneda',
          href: `/app/currencies/${router.query._id}`,
          key: 'update',
        },
      ]}
    >
      {loading ? (
        <Loading />
      ) : (
        <CurrencyForm currency={currency} setCurrency={setCurrency} />
      )}
    </Layout>
  );
}
