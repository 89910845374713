export const shiftTypes = [
  {
    name: 'Mañana',
    value: 'morning',
  },
  {
    name: 'Tarde',
    value: 'afternoon',
  },
];
