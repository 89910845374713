import React from 'react';
import { ByMyCarIcon, SelectTime } from '@spa-cars/ui';
import { Slot } from '../../hooks';

interface SlotPickerProps {
  shop: string;
  date: string;
  time: string[];
  setTime: React.Dispatch<React.SetStateAction<string[]>>;
  slots: Slot[];
  loadingGetAvailableSlots: boolean;
  withFlush: boolean;
}

export default function SlotPicker({
  shop,
  date,
  time,
  setTime,
  slots,
  loadingGetAvailableSlots,
  withFlush,
}: SlotPickerProps) {
  return (
    <>
      <h4 className="font-semibold text-lg text-primary-300 mt-6">
        Seleccione una hora
      </h4>
      {shop && date ? (
        <SelectTime
          time={time}
          setTime={setTime}
          hourSlots={slots ?? []}
          loadingReservations={loadingGetAvailableSlots}
          withFlush={withFlush}
        />
      ) : (
        <div className="flex w-full flex-col">
          <ByMyCarIcon className="w-36 h-36  mx-auto" />
          <span className="mx-auto text-center">
            Selecciona día y tienda para conocer la disponibilidad de horas
          </span>
        </div>
      )}
    </>
  );
}
