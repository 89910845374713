import Link from 'next/link';
import React from 'react';
import { IRoute } from '../routes';

interface BreadcrumbNavigationProps {
  routesHistory: Partial<IRoute>[];
}

function BreadcrumbNavigation({
  routesHistory = [],
}: BreadcrumbNavigationProps) {
  return (
    <ul className=" flex items-center">
      {routesHistory?.map(
        (route, i) =>
          route && (
            <li
              className="flex items-center"
              key={`${route.href}-${i}-${route?.key}`}
            >
              <Link href={`${route.href}`} className=" pr-1">
                {i === 0 ? (
                  <span className="block w-5 h-5 text-neutral-100">
                    {route.icon}
                  </span>
                ) : (
                  <span
                    className={`font-medium text-sm  ${
                      i === (routesHistory?.length ?? 0) - 1
                        ? 'text-neutral-300'
                        : 'text-neutral-100 '
                    }`}
                  >
                    {route.text}
                  </span>
                )}
              </Link>
              <span
                className={` pr-1 text-neutral-100 ${
                  i === (routesHistory?.length ?? 0) - 1 ? 'hidden' : ''
                }`}
              >
                /
              </span>
            </li>
          )
      )}
    </ul>
  );
}

export default BreadcrumbNavigation;
