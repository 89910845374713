import React from 'react';
import TaxForm from './TaxForm';
import { Layout } from '../layout';

export default function CreateTaxForm() {
  return (
    <Layout
      title="Nuevo impuesto"
      routesHistory={[
        {
          text: 'Perfil de impuesto',
          href: '/app/taxes/create',
          key: 'create',
        },
      ]}
    >
      <TaxForm />
    </Layout>
  );
}
