/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
import React from 'react';
import { Button, formatCurrency, Input, SpinnerIcon } from '@spa-cars/ui';
import { validateString } from 'avilatek-utils';
import { useRouter } from 'next/router';
import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import { v4 as uuid } from 'uuid';
import {
  Appointment,
  Currency,
  Discount,
  Driver,
  Order,
  OrderItem,
  User,
} from '@spa-cars/models';
import { ACTIONS, reducer } from './reducer';
import { useNotify, useUser } from '../../../hooks';
import {
  NEW_APPOINTMENT_ORDER,
  RESCHEDULE_APPOINTMENT,
} from '../../../graphql/mutations';
import { getOrderTotal } from '../../../lib';

const ubiButtonStyle = {
  borderRadius: '5px',
  fontFamily: 'Work Sans',
  width: '100%',
  padding: '0rem 1rem',
  fontSize: '16px',
  height: '40px',
  lineHeight: 'initial',
  fontWeight: '600',
  marginTop: '15px',
  marginBottom: '10px',
};

interface PaymentProps {
  localCurrency: Currency;
  items: OrderItem[]; // flush item and flush service
  discounts?: Discount[];
  locationId: string;
  order: Partial<Order>;
  repairId: string;
  paid: boolean;
  setPaid: React.Dispatch<React.SetStateAction<boolean>>;
  newAppointment: boolean; // if user wants to reschedule
  appointmentCurrentDate: Date;
  appointmentId: string;
  newAppointmentDate: Date; // the new appointment date
  refetchAppointment: (variables?: Partial<OperationVariables>) => Promise<
    ApolloQueryResult<{
      appointment: Appointment;
    }>
  >;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  driver: Partial<Driver>;
}

function Payment({
  localCurrency,
  items,
  discounts = [],
  locationId,
  order,
  repairId,
  paid,
  setPaid,
  newAppointment,
  appointmentId,
  newAppointmentDate,
  refetchAppointment,
  appointmentCurrentDate,
  setOpenModal,
  driver,
}: PaymentProps) {
  const [state, dispatch] = React.useReducer(reducer, {});
  const notify = useNotify();
  const router = useRouter();
  const [pdvCollapsable, setPdvCollapsable] = React.useState<boolean>(false);
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [confirmOrder, setConfirmOrder] = React.useState<boolean>(false);
  const [user] = useUser();

  const [newAppointmentOrder] = useMutation<{
    newAppointmentOrder: Order;
  }>(NEW_APPOINTMENT_ORDER);
  // const [updateOrder] = useMutation<{
  //   updateOrder: {
  //     record: Order;
  //   };
  // }>(UPDATE_ORDER);
  // const [addFlushToSteps] = useMutation<{
  //   addFlushToSteps: Repair;
  // }>(ADD_FLUSH_TO_STEPS);
  // const [createNextBlockAppointment] = useMutation<{
  //   createNextBlockAppointment: { success: boolean };
  // }>(CREATE_NEXT_BLOCK_APPOINTMENT);

  const [rescheduleAppointment] = useMutation<{
    rescheduleAppointment: Appointment;
  }>(RESCHEDULE_APPOINTMENT);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({ type: name as any, payload: value });
  };

  const rescheduleCurrentAppointment = async () => {
    // reschedule appointment, the original appointment can have flush or not
    // the new appointment will always have flush
    // if isExtended is true, the original appointment will be extended, not rescheduled
    try {
      if (!newAppointmentDate && newAppointment) {
        notify('Debe seleccionar una fecha para la nueva cita', 'error');
        return;
      }
      const { data: dataAppointment } = await rescheduleAppointment({
        variables: {
          data: {
            appointment: appointmentId,
            date: newAppointment ? newAppointmentDate : appointmentCurrentDate,
            isExtend: !newAppointment,
            location: locationId,
            admin: user?._id,
            driver: {
              ...driver,
              __typename: undefined,
              phone: {
                ...driver?.phone,
                __typename: undefined,
              },
            },
          },
        },
      });
      if (dataAppointment?.rescheduleAppointment) {
        notify('Se ha agendado la nueva cita con éxito', 'success');
      } else {
        notify('Ha ocurrido un error', 'error');
      }
      setDisabled(false);
      refetchAppointment();
      setOpenModal(false);
    } catch (error) {
      notify(error.message, 'error');
    }
  };

  // const addFlushToCurrentService = async () => {
  //   // only if user doesn't reschedule and want to add flush
  //   try {
  //     if (repairId) {
  //       const { data: dataOrderUpdate } = await updateOrder({
  //         variables: {
  //           filter: {
  //             _id: order?._id,
  //           },
  //           record: {
  //             withFlush: true,
  //           },
  //         },
  //       });
  //       let dataRepair_ = null;
  //       let dataAppointment_ = null;
  //       if (!order?.withFlush) {
  //         const { data: dataRepair } = await addFlushToSteps({
  //           variables: {
  //             data: {
  //               repair: repairId,
  //             },
  //           },
  //         });
  //         const { data: nextBlock } = await createNextBlockAppointment({
  //           variables: { data: { appointment: appointmentId } },
  //         });
  //         dataRepair_ = dataRepair;
  //         dataAppointment_ = nextBlock;
  //       } else {
  //         dataRepair_ = true;
  //         dataAppointment_ = true;
  //       }
  //       if (
  //         dataOrderUpdate?.updateOrder?.record?._id &&
  //         dataRepair_ &&
  //         dataAppointment_
  //       ) {
  //         notify('Flush agregado con éxito', 'success');
  //         setPaid(true);
  //         setDisabled(false);
  //         refetchAppointment();
  //         setOpenModal(false);
  //         // router.push(`/app/sales/${data?.newAppointmentOrder?._id}`);
  //       } else {
  //         notify('Ha ocurrido un error al crear la venta', 'error');
  //       }
  //     } else {
  //       notify('Ha ocurrido un error al crear la venta', 'error');
  //     }
  //   } catch (error) {
  //     notify(error.message, 'error');
  //   }
  // };

  /**
   * ubii payment btn integration
   */
  React.useEffect(() => {
    if (state?.confirmedForm && confirmOrder) {
      const initUbiiProcess = async () => {
        const { initUbii } = await import('@ubiipagos/boton-ubii-dc');
        initUbii(
          'botonpago',
          {
            amount_ds: `${formatCurrency(state.total.total).replace(',', '')}`,
            amount_bs: `${formatCurrency(
              localCurrency.rate * state.total.total
            ).replace(',', '')}`,
            concept: `Venta realizada por ${user?.firstName} ${user?.lastName}`,
            principal: 'ds',
            // clientId: process.env.NEXT_PUBLIC_UBII_PAGOS_KEY,
            clientId: 'd659dd33-7d4e-11ed-a842-00505696ff82',
            orderId: uuid(),
          },
          ubiiBtnCallbackFn,
          // Personalización con su texto y colores
          {
            text: `Pagar - $${formatCurrency(state.total.total)}`,
            textColor: '#ffffff',
            buttonColor: '#2EA89D',
            borderColor: '#2EA89D',
          }
        );
      };
      initUbiiProcess();
    }
  }, [state?.confirmedForm]);

  /**
   * ubii callback after close payment process
   */
  const ubiiBtnCallbackFn = async (answer) => {
    dispatch({ type: ACTIONS.IN_PAYMENT_PROCESS, payload: true });
    if (answer.error) {
      switch (answer.data.error_data) {
        case '1':
          notify('Datos incorrectos', 'error');
          break;
        case '2':
          notify('Transacción no autorizada', 'error');
          break;
        case '3':
          notify(
            'No se preocupe querido usuario, su orden ya ha sido procesada y se está haciendo en estos momentos',
            'error'
          );
          break;
        case '4':
          notify('Opción deshabilitada', 'error');
          break;
        default:
          break;
      }
    } else if (answer.data.R === '0') {
      if (!validateString(order?._id)) {
        notify('No se encuentra la orden actual', 'error');
        return;
      }
      setDisabled(true);
      try {
        // Creates flush sale
        const { data } = await newAppointmentOrder({
          variables: {
            data: {
              promoCodes: discounts?.map((dd) => dd?.code),
              appointment: appointmentId,
              items: [
                items.map((item) => ({
                  productId: item?.product?._id,
                  variantId: item?.variant?._id,
                  quantity: 1,
                })),
              ],
              location: locationId,
              admin: user?._id,
              client: (order?.user as User)?._id,
              paymentStatus: 'approved',
              paymentInfo: {
                status: 'approved',
                gateway: 'otros',
                amount: state.total.total,
                currency: 'dollars',
                metadata: {
                  trace: answer.trace,
                  method: answer.method,
                  ref: answer.ref,
                },
              },
            },
          },
        });
        if (data?.newAppointmentOrder?._id && repairId) {
          rescheduleCurrentAppointment();
        } else {
          notify('Ha ocurrido un error al crear la venta', 'error');
        }
      } catch (error) {
        notify(error.message, 'error');
      }
    } else {
      notify('Transacción fallida', 'error');
    }
    dispatch({ type: ACTIONS.IN_PAYMENT_PROCESS, payload: false });
  };

  const payWithPdv = async () => {
    try {
      setDisabled(true);
      if (state.confirmedForm && validateString(state.pdvRef)) {
        if (!validateString(order?._id)) {
          notify('No se encuentra la orden actual', 'error');
          return;
        }
        // Creates flush sale
        const { data } = await newAppointmentOrder({
          variables: {
            data: {
              promoCodes: discounts?.map((dd) => dd?.code),
              appointment: appointmentId,
              items: [
                ...items.map((item) => ({
                  productId: item?.product?._id,
                  variantId: item?.variant?._id,
                  quantity: 1,
                })),
              ],
              admin: user?._id,
              location: locationId,
              client: (order?.user as User)?._id,
              paymentInfo: {
                status: 'approved',
                gateway: 'pdv',
                amount: state.total.total,
                currency: 'dollars',
                metadata: {
                  ref: state.pdvRef,
                },
              },
            },
          },
        });
        if (data?.newAppointmentOrder?._id && repairId) {
          rescheduleCurrentAppointment();
        } else {
          notify('Ha ocurrido un error al crear la venta', 'error');
        }
      } else {
        return notify('Indique la referencia', 'error');
      }
    } catch (error) {
      notify(error.message, 'error');
    }
  };

  const confirmForm = () => {
    if (validateString(locationId)) {
      // validate if the user has selected a date for the new appointment
      if (newAppointment && !newAppointmentDate) {
        notify('Debe seleccionar una fecha', 'warning');
        return;
      }
      if (items?.length > 0) {
        dispatch({
          type: ACTIONS.TOTAL,
          payload: getOrderTotal(items, discounts),
        });
        dispatch({ type: ACTIONS.CONFIRMED_FORM, payload: true });
        setConfirmOrder(true);
      } else {
        notify('Debe agregar los productos a pagar', 'warning');
      }
    } else {
      notify('No se encuentra el punto de servicio', 'warning');
    }
  };

  React.useEffect(() => {
    if (newAppointment && !newAppointmentDate) {
      setConfirmOrder(false);
      dispatch({ type: ACTIONS.RESET, payload: {} });
    }
  }, [newAppointment]);

  return (
    <div className="flex flex-col w-full">
      {!paid ? (
        <>
          {!confirmOrder || !state?.confirmedForm ? (
            <Button
              id="confirmButton"
              onClick={(e) => {
                e.preventDefault();
                confirmForm();
              }}
              className="mt-4 py-2 px-4  rounded w-full bg-neutral-50  font-semibold  text-primary-400 border-2 border-primary-300 hover:text-white hover:bg-primary-300 transition-colors"
            >
              Confirmar venta
            </Button>
          ) : (
            <button
              style={ubiButtonStyle}
              disabled={disabled}
              id="botonpago"
              onClick={(e) => {
                e.preventDefault();
                dispatch({ type: ACTIONS.IN_PAYMENT_PROCESS, payload: true });
              }}
            />
          )}
          {state.confirmedForm || disabled ? (
            <div className=" ">
              <input
                type="checkbox"
                checked={pdvCollapsable}
                onChange={(e) => {
                  setPdvCollapsable(!pdvCollapsable);
                }}
                className="w-4 h-4 mt-1 cursor-pointer text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label className="ml-2 text-neutral-300  text-lg" htmlFor="">
                Pagar con punto de venta
              </label>
            </div>
          ) : null}
          {pdvCollapsable && state.confirmedForm ? (
            <>
              <Input
                className="bg-white mt-2"
                label="Referencia del punto de venta"
                value={state.pdvRef}
                onChange={handleChange}
                name={ACTIONS.PDV_REF}
              />
              <Button
                className="mt-5 flex"
                disabled={disabled}
                onClick={(e) => {
                  e.preventDefault();
                  payWithPdv();
                }}
              >
                {disabled ? (
                  <SpinnerIcon className="w-6 h-6 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-white" />
                ) : (
                  <span className="mx-auto">Pagar con punto de venta</span>
                )}
              </Button>
            </>
          ) : null}
        </>
      ) : null}
    </div>
  );
}

export default Payment;
