import React from 'react';
import { Button, CloseIcon, Line, Modal } from '@spa-cars/ui';

interface FlushModalProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  disableModal: boolean;
  continueServiceWithoutFlush: () => Promise<void>;
  openFlushSaleModal: () => void;
}

function FlushModal({
  isOpen,
  setOpen,
  disableModal,
  continueServiceWithoutFlush,
  openFlushSaleModal,
}: FlushModalProps) {
  return (
    <Modal isOpen={isOpen} setOpen={setOpen} className="w-fit md:ml-72 ">
      <div className="bg-gray-50 rounded-lg px-6 py-3">
        {/* Title */}
        <div className="flex justify-between items-center pb-3">
          <h2 className="text-xl text-primary-500 font-medium">
            ¿Desea incluir el flush al servicio?{' '}
          </h2>
          <button
            type="button"
            aria-label="close"
            onClick={(e) => {
              e.preventDefault();
              setOpen(false);
            }}
          >
            <CloseIcon className="h-5 w-5 text-red-500" />
          </button>
        </div>
        <Line />
        {/* body */}
        <div className="py-3 text-center">
          El operador ha señalado la necesidad de realizar un FLUSH. ¿Desea
          incluir en su servicio la aplicación del FLUSH?
        </div>
        <div className="w-full flex justify-end gap-4">
          <Button
            type="button"
            className=" bg-danger-200"
            disabled={disableModal}
            onClick={(e) => {
              e.preventDefault();
              continueServiceWithoutFlush();
            }}
          >
            No, continuar servicio
          </Button>
          <Button
            type="button"
            className=""
            disabled={disableModal}
            onClick={(e) => {
              e.preventDefault();
              openFlushSaleModal();
            }}
          >
            Sí, agregar flush y continuar
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default FlushModal;
