import React from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import { Location } from '@spa-cars/models';
import LocationForm from './LocationForm';
import { Layout } from '../layout';
import { GET_LOCATION } from '../../graphql/queries';
import { Loading } from '../shared';

export default function UpdateLocationForm() {
  const router = useRouter();
  const { data, loading, error } = useQuery<{ location: Location }>(
    GET_LOCATION,
    {
      variables: {
        filter: { _id: router.query.id as string },
      },
      fetchPolicy: 'cache-and-network',
    }
  );
  const [location, setLocation] = React.useState<Partial<Location>>(
    data?.location ?? null
  );

  React.useEffect(() => {
    if (data?.location) {
      setLocation(data.location);
    }
  }, [data?.location]);

  return (
    <Layout
      title={location?.name}
      routesHistory={[
        {
          text: 'Perfil de punto de servicio',
          href: `/app/locations/${router.query._id}`,
          key: 'update',
        },
      ]}
    >
      {loading ? (
        <Loading />
      ) : (
        <LocationForm location={location} setLocation={setLocation} />
      )}
    </Layout>
  );
}
