import { Currency } from '@spa-cars/models';

export const ACTIONS = {
  NAME: 'NAME',
  RATE: 'RATE',
  SYMBOL: 'SYMBOL',
  LOCAL_COIN: 'LOCAL_COIN',
  DEFAULT: 'DEFAULT',
} as const;

export type ACTIONS_TYPE =
  | { type: 'NAME'; payload: string }
  | { type: 'RATE'; payload: number }
  | { type: 'SYMBOL'; payload: string }
  | { type: 'LOCAL_COIN'; payload: boolean }
  | { type: 'DEFAULT'; payload: Partial<Currency> };

export function reducer(
  state: Partial<Currency>,
  action: ACTIONS_TYPE
): Partial<Currency> {
  switch (action.type) {
    case ACTIONS.NAME:
      return { ...state, name: action.payload };
    case ACTIONS.RATE:
      return { ...state, rate: action.payload };
    case ACTIONS.SYMBOL:
      return { ...state, symbol: action.payload };
    case ACTIONS.LOCAL_COIN:
      return { ...state, localCoin: action.payload };
    case ACTIONS.DEFAULT:
      return { ...state, ...(action?.payload ?? {}) };
    default:
      return { ...state };
  }
}
