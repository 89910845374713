/* eslint-disable no-nested-ternary */
import React from 'react';
import NProgress from 'nprogress';
import Link from 'next/link';
import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import { setUser } from '@sentry/nextjs';
import { CloseIcon, LogoutIcon, MenuIcon, ShortMenuIcon } from '@spa-cars/ui';
import { AnimatePresence, motion } from 'framer-motion';
import { UserTypeEnum } from '@spa-cars/models';
import {
  companyRoutes,
  inventoryRoutes,
  IRoute,
  marketingRoutes,
  routes,
  superAdminRoutes,
} from '../../routes';
import SidebarItem from './SidebarItem';
import { useNotify, useUser } from '../../hooks';
import { SIGN_OUT } from '../../graphql/mutations';
import SpacarsIcon from '../../public/img/logos/SpacarsIcon';

const getRoutes = (userType: UserTypeEnum) => {
  switch (userType) {
    case 'admin_manager':
      return marketingRoutes.map((rr) => ({
        ...rr,
        isOpen: rr?.children?.length > 0 ? false : undefined,
      }));
    case 'inventory_manager':
      return inventoryRoutes.map((rr) => ({
        ...rr,
        isOpen: rr?.children?.length > 0 ? false : undefined,
      }));
    case 'company':
      return companyRoutes.map((rr) => ({
        ...rr,
        isOpen: rr?.children?.length > 0 ? false : undefined,
      }));
    case 'superadmin':
      return superAdminRoutes.map((rr) => ({
        ...rr,
        isOpen: rr?.children?.length > 0 ? false : undefined,
      }));

    default:
      return routes.map((rr) => ({
        ...rr,
        isOpen: rr?.children?.length > 0 ? false : undefined,
      }));
  }
};

export default function Sidebar({ decreaseMenu, setDecreaseMenu, screenSize }) {
  const [collapseShow, setCollapseShow] = React.useState('hidden');
  const [user] = useUser();
  const [routes_, setRoutes_] = React.useState<IRoute[]>(
    getRoutes(user?.userType)
  );

  const [signOut] = useMutation(SIGN_OUT);
  const notify = useNotify();
  const router = useRouter();

  const onClickSignOut = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      e.preventDefault();
      NProgress.start();
      await signOut();
      setUser(null);
      await router.push('/sign-in');
    } catch (err) {
      notify(err.message, 'error');
    } finally {
      NProgress.done();
    }
  };

  React.useEffect(() => {
    if (user?.userType) {
      setRoutes_(getRoutes(user?.userType));
    }
  }, [user]);
  return (
    <AnimatePresence>
      {user?._id ? (
        <motion.nav
          initial={screenSize >= 768 ? { width: '288px' } : { width: '100%' }}
          animate={
            screenSize >= 768
              ? {
                  width: decreaseMenu ? 'fit-content' : '288px',
                }
              : { width: '100%' }
          }
          transition={{ duration: 0.1 }}
          className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden  bg-neutral-300  border-0  flex flex-wrap items-center justify-between md:w-72 z-50 w-full fixed"
        >
          <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full ">
            <div className="md:hidden absolute w-full flex">
              <button
                className="cursor-pointer text-text-white opacity-50 md:hidden px-4 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent  "
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setCollapseShow(
                    'bg-neutral-300 h-screen flex flex-col justify-between'
                  );
                }}
              >
                <span className="block w-4 h-4  text-text-white  ">
                  <MenuIcon />
                </span>
              </button>
            </div>
            <div className="flex justify-between md:mx-0 mx-auto">
              <Link href="/app" legacyBehavior>
                <motion.a
                  className="md:block md:my-8 md:ml-[16px] md:mr-0 my-4 overflow-hidden h-[45px]"
                  initial={
                    screenSize >= 768
                      ? {
                          width: 'fit-content',
                          height: '45px',
                          marginLeft: '16px',
                        }
                      : {
                          width: 'fit-content',
                          height: '45px',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        }
                  }
                  animate={
                    screenSize >= 768
                      ? {
                          width: decreaseMenu ? '0px' : 'fit-content',
                          marginLeft: decreaseMenu ? '0px' : '16px',
                          height: '45px',
                        }
                      : {
                          width: 'fit-content',
                          height: '45px',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        }
                  }
                  transition={{ duration: 0.2, origin: 1, bounce: 0 }}
                  // layout={screenSize >= 768}
                >
                  <SpacarsIcon />
                </motion.a>
              </Link>
              <div className="mx-auto flex flex-col justify-center gap-4">
                <img
                  className={`w-6 h-6 overflow-hidden  ${
                    !decreaseMenu ? 'hidden' : ''
                  }`}
                  alt="logo"
                  src="/img/logos/spacars-small-logo.png"
                />

                <button
                  type="button"
                  className=" hidden md:block"
                  onClick={(e) => {
                    e.preventDefault();
                    setDecreaseMenu(!decreaseMenu);
                  }}
                >
                  <ShortMenuIcon className="w-6 h-6 text-text-white " />
                </button>
              </div>
            </div>

            <div
              className={` md:flex md:flex-col md:justify-between md:items-stretch md:opacity-100 md:relative md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto  flex-1  ${collapseShow}`}
            >
              <div>
                <div className="md:min-w-full md:hidden block">
                  <div className="flex flex-wrap justify-center items-center">
                    <Link
                      href="/app"
                      className="md:block text-left  p-4  inline-block"
                    >
                      <SpacarsIcon />
                    </Link>
                    <div className=" w-full flex justify-end absolute ">
                      <button
                        type="button"
                        className="cursor-pointer text-text-white opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent "
                        onClick={(e) => {
                          e.preventDefault();
                          setCollapseShow('hidden');
                        }}
                      >
                        <span className="block w-6 h-6  text-text-white ">
                          <CloseIcon />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>

                <ul className="w-full flex flex-col pl-0 mb-0 list-none">
                  {routes_.map((route) => (
                    <SidebarItem
                      key={route.key}
                      route={route}
                      decreaseMenu={decreaseMenu}
                      setRoutes_={setRoutes_}
                      routes_={routes_}
                      screenSize={screenSize}
                      setCollapseShow={setCollapseShow}
                    />
                  ))}
                </ul>
              </div>
              <button
                type="button"
                onClick={onClickSignOut}
                className="w-fit mx-auto my-6 text-text-white font-medium hover:text-primary-200  flex items-center "
              >
                <LogoutIcon className="w-5 h-5 " />
                <motion.span
                  initial={{ width: 'fit-content', marginLeft: '16px' }}
                  animate={{
                    width: decreaseMenu ? '0px' : 'fit-content',
                    marginLeft: decreaseMenu ? '0px' : '16px',
                  }}
                  // layout={screenSize >= 768}
                  transition={{ duration: 0.1, origin: 1, bounce: 0 }}
                  className="overflow-hidden text-clip whitespace-nowrap"
                >
                  Cerrar sesión
                </motion.span>
              </button>
            </div>
          </div>
        </motion.nav>
      ) : null}
    </AnimatePresence>
  );
}
