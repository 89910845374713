export const actionTypes = [
  {
    name: 'Creación',
    value: 'create',
  },
  {
    name: 'Actualización',
    value: 'update',
  },
  {
    name: 'Eliminación',
    value: 'delete',
  },
];
