import React from 'react';
import { SpinnerIcon } from '@spa-cars/ui';

interface LoadingProps {
  className?: string;
}

function Loading({ className = '' }: LoadingProps) {
  return (
    <div
      className={`w-screen h-screen flex items-center justify-center fixed opacity-70 z-30 ${className}`}
    >
      <SpinnerIcon className="mr-2 w-24 h-24 text-gray-200 animate-spin dark:text-gray-600 fill-primary-300" />
      <span className="sr-only">Loading...</span>
    </div>
  );
}

export default Loading;
