import React from 'react';
import { Button, CloseIcon, Line, Modal, SpinnerIcon } from '@spa-cars/ui';
import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import { Appointment, Driver } from '@spa-cars/models';
import { RESCHEDULE_APPOINTMENT } from '../../../graphql/mutations';
import { useNotify, useUser } from '../../../hooks';
import NewAppointment from '../NewAppointment';

interface FlushSaleModalProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  locationId: string;
  driver: Driver;
  isLoading: boolean;
  appointmentId: string;
  refetchAppointment: (variables?: Partial<OperationVariables>) => Promise<
    ApolloQueryResult<{
      appointment: Appointment;
    }>
  >;
}
function RescheduleModal({
  isOpen,
  setOpen,
  locationId,
  isLoading,
  driver,
  appointmentId,
  refetchAppointment,
}: FlushSaleModalProps) {
  const [newAppointmentDate, setNewAppointmentDate] =
    React.useState<Date>(null);
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const notify = useNotify();
  const [user] = useUser();

  const [rescheduleAppointment] = useMutation<{
    rescheduleAppointment: Appointment;
  }>(RESCHEDULE_APPOINTMENT);

  const rescheduleCurrentAppointment = async () => {
    // reschedule appointment, the original appointment can have flush or not
    // the new appointment will always have flush
    // if isExtended is true, the original appointment will be extended, not rescheduled
    try {
      setDisabled(true);
      if (!newAppointmentDate) {
        notify('Debe seleccionar una fecha para la nueva cita', 'error');
        return;
      }
      setDisabled(true);
      const { data: dataAppointment } = await rescheduleAppointment({
        variables: {
          data: {
            appointment: appointmentId,
            date: newAppointmentDate,
            isExtend: false,
            location: locationId,
            admin: user?._id,
            driver: {
              ...driver,
              __typename: undefined,
              phone: {
                ...driver?.phone,
                __typename: undefined,
              },
            },
          },
        },
      });
      if (dataAppointment?.rescheduleAppointment) {
        notify('Se ha agendado la nueva cita con éxito', 'success');
      } else {
        notify('Ha ocurrido un error', 'error');
      }
      setDisabled(false);
      refetchAppointment();
      setOpen(false);
    } catch (error) {
      notify(error.message, 'error');
    }
  };

  return (
    <Modal isOpen={isOpen} setOpen={setOpen} className=" md:ml-72  max-w-full">
      <div className="bg-gray-50 rounded-lg px-6 py-3">
        {/* Title */}
        <div className="flex justify-between items-center pb-3">
          <h2 className="text-xl text-primary-500 font-medium">
            Reagendar servicio
          </h2>
          <button
            type="button"
            aria-label="close"
            onClick={(e) => {
              e.preventDefault();

              setOpen(false);
            }}
          >
            <CloseIcon className="h-5 w-5 text-red-500" />
          </button>
        </div>
        <Line />
        {!isLoading ? (
          <div className="py-3 w-full">
            <NewAppointment
              newAppointmentDate={newAppointmentDate}
              setNewAppointmentDate={setNewAppointmentDate}
              location={locationId}
            />
            <Button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                rescheduleCurrentAppointment();
              }}
              disabled={disabled}
            >
              {disabled ? (
                <SpinnerIcon className="w-5 h-5 mx-auto text-gray-200 animate-spin dark:text-text-white fill-primary-300" />
              ) : (
                'Reagendar servicio'
              )}
            </Button>
          </div>
        ) : (
          <SpinnerIcon className="w-20 h-20 mx-auto text-gray-200 animate-spin dark:text-text-white fill-primary-300" />
        )}
      </div>
    </Modal>
  );
}

export default RescheduleModal;
