import React from 'react';
import { Discount, Tax } from '@spa-cars/models';
import { TShopcartProduct, TTotal } from '../components/Payment/reducer';

type TShopcartTally = {
  shopcartProducts: TShopcartProduct[];
  appliedDiscounts: Discount[];
};

export function useShopcartTally({
  shopcartProducts,
  appliedDiscounts,
}: TShopcartTally): TTotal {
  const [total, setTotal] = React.useState<TTotal>({
    subtotal: 0,
    totalTax: 0,
    total: 0,
    discountTotal: 0,
  });

  React.useEffect(() => {
    const _total = calculateShopcartTally({
      shopcartProducts,
      appliedDiscounts,
    });
    setTotal(_total);
  }, [shopcartProducts, appliedDiscounts]);
  return total;
}

function calculateShopcartTally({
  shopcartProducts,
  appliedDiscounts,
}: TShopcartTally) {
  const _total = {
    subtotal: 0,
    taxableSubtotal: 0,
    totalTax: 0,
    total: 0,
    realTax: 0,
    discountTotal: 0,
  };
  // Return the total percentage and total fixed discounts
  const _discounts = appliedDiscounts.reduce(
    (acc, d) => [
      acc[0] + (d.type === 'percentage' ? d.value : 0),
      acc[1] + (d.type === 'fixed' ? d.value : 0),
    ],
    [0, 0]
  );

  shopcartProducts.forEach(({ variant, product, quantity }) => {
    if (variant) {
      const totalPerVariant = (variant?.price ?? 0) * quantity;
      const discountPerVariant = (totalPerVariant * _discounts[0]) / 100;
      // calculate subtotal
      _total.subtotal += totalPerVariant;
      // calculate taxableSubtotal
      _total.taxableSubtotal += product?.taxes?.length ? totalPerVariant : 0;
      // calculate discountTotal
      _total.discountTotal += discountPerVariant;
      // calculate totalTax and realTax
      const [_totalTax, _realTax] = product?.taxes?.reduce(
        (_acc, tax: Tax) => [
          _acc[0] + (totalPerVariant - discountPerVariant) * (tax.value / 100),
          _acc[0] + totalPerVariant * (tax.value / 100),
        ],
        [0, 0]
      ) ?? [0, 0];
      _total.totalTax += _totalTax;
      _total.realTax += _realTax;
    }
  });

  _total.discountTotal += _discounts[1];
  // calculate total
  _total.total = _total.subtotal + _total.totalTax - _total.discountTotal;

  return _total;
}
