import React from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import { User } from '@spa-cars/models';
import ClientForm from './ClientForm';
import { Layout } from '../layout';
import { GET_USER } from '../../graphql/queries';
import { Loading } from '../shared';

export default function UpdateClientForm() {
  const router = useRouter();
  const { data, loading, error } = useQuery<{ user: User }>(GET_USER, {
    variables: {
      filter: { _id: router.query.id as string },
    },
    fetchPolicy: 'cache-and-network',
  });
  const [client, setClient] = React.useState<Partial<User>>(data?.user ?? null);

  React.useEffect(() => {
    if (data?.user) {
      setClient(data.user);
    }
  }, [data?.user]);

  return (
    <Layout
      titlePhoto={client?.photo?.src ?? '/img/placeholder/user.png'}
      title={`${client?.firstName ?? ''} ${client?.lastName ?? ''}`}
      routesHistory={[
        {
          text: 'Perfil de cliente',
          href: `/app/clients/${router.query._id}`,
          key: 'update',
        },
      ]}
    >
      {loading ? (
        <Loading />
      ) : (
        <ClientForm client={client} setClient={setClient} />
      )}
    </Layout>
  );
}
