import { useLazyQuery } from '@apollo/client';
import React from 'react';
import { Appointment } from '@spa-cars/models';
import { GET_VEHICLE_LAST_APPOINTMENT } from '../graphql/queries';

export function useLastAppointment(car: string) {
  // React hooks
  const [appointment, setAppointment] = React.useState<Appointment>(null);
  const [superFlush, setSuperFlush] = React.useState<boolean>(false);
  // Apollo hook
  const [
    getLastAppointment,
    { loading: loadingLastAppointment, data: dataLastAppointment },
  ] = useLazyQuery<{
    getVehicleLastAppointment: {
      appointment: Appointment;
      superFlush: boolean;
    };
  }>(GET_VEHICLE_LAST_APPOINTMENT, {
    fetchPolicy: 'network-only',
  });

  React.useEffect(
    function syncLastAppointment() {
      if (dataLastAppointment && !loadingLastAppointment) {
        setAppointment(
          dataLastAppointment?.getVehicleLastAppointment?.appointment ?? null
        );
        setSuperFlush(
          dataLastAppointment?.getVehicleLastAppointment?.superFlush ?? false
        );
      }
    },
    [dataLastAppointment, loadingLastAppointment]
  );

  React.useEffect(
    function searchCarLastAppointment() {
      if (car) {
        getLastAppointment({
          variables: {
            data: {
              vehicle: car,
            },
          },
        });
      } else {
        setAppointment(null);
        setSuperFlush(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [car]
  );

  return [appointment, superFlush, loadingLastAppointment] as const;
}
