import React from 'react';
import NProgress from 'nprogress';
import Link from 'next/link';
import {
  ArrowBackIcon,
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  Line,
} from '@spa-cars/ui';
import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import { Currency, DocumentModel } from '@spa-cars/models';
import { useNotify } from '../../hooks';
import { ACTIONS, reducer } from './reducer';
import { CREATE_CURRENCY, UPDATE_CURRENCY } from '../../graphql/mutations';

interface CurrencyFormProps {
  currency?: Partial<Currency>;
  setCurrency?: React.Dispatch<React.SetStateAction<Partial<Currency>>>;
}

function CurrencyForm({
  currency = null,
  setCurrency = null,
}: CurrencyFormProps) {
  const [state, dispatch] = React.useReducer(
    reducer,
    currency || {
      localCoin: false,
    }
  );
  const [updateCurrency] = useMutation<{
    updateCurrency: {
      record: Currency;
    };
  }>(UPDATE_CURRENCY);
  const [createCurrency] = useMutation<{
    createCurrency: {
      record: Currency;
    };
  }>(CREATE_CURRENCY);
  const [documents, setDocuments] = React.useState<
    Array<Partial<DocumentModel>>
  >([]);
  const notify = useNotify();
  const router = useRouter();
  const [disabled, setDisabled] = React.useState(false);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      NProgress.start();
      setDisabled(true);
      delete state?._id;

      const currencyData = {
        ...state,
        rate: parseFloat(String(state?.rate)),
      };
      if (currency) {
        const { data } = await updateCurrency({
          variables: {
            filter: {
              _id: currency._id,
            },
            record: {
              ...currencyData,
              __typename: undefined,
            },
          },
        });
        if (data?.updateCurrency?.record?._id) {
          notify('Moneda actualizada con éxito', 'success');
          router.back();
        } else {
          notify('Ha ocurrido un error al actualizar la moneda', 'error');
        }
      } else {
        const { data } = await createCurrency({
          variables: {
            record: {
              ...currencyData,
              __typename: undefined,
            },
          },
        });
        if (data?.createCurrency?.record?._id) {
          notify('Moneda creada con éxito', 'success');
          router.push(`/app/currencies/${data?.createCurrency?.record?._id}`);
        } else {
          notify('Ha ocurrido un error al crear la moneda', 'error');
        }
      }
      setDisabled(false);
    } catch (err) {
      notify(err.message, 'error');
      setDisabled(false);
    } finally {
      NProgress.done();
    }
  };

  const updateActiveCurrency = async (active: boolean) => {
    try {
      NProgress.start();
      setDisabled(true);
      if (currency) {
        const { data } = await updateCurrency({
          variables: {
            filter: {
              _id: currency._id,
            },
            record: {
              active,
            },
          },
        });
        if (data?.updateCurrency?.record?._id) {
          notify('Moneda actualizada con éxito', 'success');
          router.back();
        } else {
          notify('Ha ocurrido un error al inhabilitar la moneda', 'error');
        }
      }
      setDisabled(false);
    } catch (err) {
      notify(err.message, 'error');
      setDisabled(false);
    } finally {
      NProgress.done();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({ type: name as any, payload: value });
  };

  React.useEffect(() => {
    if (currency) {
      dispatch({ type: ACTIONS.DEFAULT, payload: currency });
    }
  }, [currency]);

  return (
    <form
      method="POST"
      onSubmit={onSubmit}
      className="flex flex-col w-full gap-8"
    >
      <Card>
        <CardHeader>
          {currency ? 'Actualizar' : 'Crear'} moneda
          <Link
            href="/app/currencies"
            className="flex gap-1 text-success-200 font-medium items-center"
          >
            <ArrowBackIcon className="w-4 h-4" />
            MONEDAS
          </Link>
        </CardHeader>

        <Line />
        <CardBody>
          <div className="w-full flex md:flex-row flex-col gap-4">
            <div className="w-full">
              <Input
                name={ACTIONS.NAME}
                value={state?.name ?? ''}
                label="Nombre"
                type="text"
                placeholder="Escriba un nombre"
                onChange={handleChange}
                required
              />
            </div>
            <div className="w-full">
              <Input
                name={ACTIONS.RATE}
                value={state?.rate ?? ''}
                label="Tasa"
                type="number"
                min={0}
                step={0.01}
                placeholder="Escriba una tasa"
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="w-full flex md:flex-row flex-col gap-4">
            <div className="w-full">
              <Input
                name={ACTIONS.SYMBOL}
                value={state?.symbol ?? ''}
                label="Símbolo"
                type="text"
                placeholder="Escriba un símbolo"
                onChange={handleChange}
                required
              />
            </div>
            <div className="w-full flex items-center">
              <input
                type="checkbox"
                checked={state?.localCoin ?? false}
                onChange={(e) => {
                  // e.preventDefault();
                  dispatch({
                    type: ACTIONS.LOCAL_COIN,
                    payload: !(state?.localCoin ?? false),
                  });
                }}
                className="w-4 h-4  cursor-pointer text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label className="ml-2 text-neutral-300  text-lg" htmlFor="">
                ¿Es la moneda nacional?
              </label>
            </div>
          </div>
        </CardBody>
      </Card>
      <div className="flex self-end gap-[30px]">
        {currency?.active ? (
          <Button
            type="button"
            className=" w-fit py-3 px-[25px] bg-danger-100"
            disabled={disabled}
            onClick={(e) => {
              e.preventDefault();
              updateActiveCurrency(false);
            }}
          >
            Inhabilitar
          </Button>
        ) : (
          currency && (
            <Button
              type="button"
              className=" w-fit py-3 px-[25px] bg-white text-primary-400"
              disabled={disabled}
              onClick={(e) => {
                e.preventDefault();
                updateActiveCurrency(true);
              }}
            >
              Habilitar
            </Button>
          )
        )}
        <Button
          type="submit"
          className=" w-fit py-3 px-[25px]"
          disabled={disabled}
        >
          {currency ? 'Actualizar' : 'Crear'}
        </Button>
      </div>
    </form>
  );
}

export default CurrencyForm;
