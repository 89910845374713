import { gql } from '@apollo/client';
import {
  ORDER_FRAGMENT,
  APPOINTMENT_FRAGMENT,
  CATEGORY_FRAGMENT,
  LOCATION_FRAGMENT,
  USER_FRAGMENT,
  VARIANT_FRAGMENT,
  VEHICLE_FRAGMENT,
  SHOPCART_FRAGMENT,
  REPAIR_FRAGMENT,
  CURRENCY_FRAGMENT,
  DISCOUNT_FRAGMENT,
} from './fragments';

export const SIGN_IN = gql`
  mutation SIGN_IN($data: SignInInput!) {
    signIn(data: $data) {
      user {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const SIGN_OUT = gql`
  mutation SIGN_OUT {
    signOut {
      success
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UPDATE_USER(
    $record: UpdateOneUserInput!
    $filter: FilterUpdateOneUserInput
  ) {
    updateUser(record: $record, filter: $filter) {
      recordId
    }
  }
`;

export const SIGN_S3 = gql`
  mutation SIGN_S3($data: SignS3Input!) {
    signS3(data: $data) {
      signedRequest
      url
    }
  }
`;

export const UPDATE_LOCATION = gql`
  mutation UPDATE_LOCATION(
    $record: UpdateOneLocationInput!
    $filter: FilterUpdateOneLocationInput
  ) {
    updateLocation(record: $record, filter: $filter) {
      recordId
    }
  }
`;

export const UPDATE_LOCATION_CUSTOM = gql`
  mutation UPDATE_LOCATION_CUSTOM($data: UpdateLocationInput) {
    updateLocationCustom(data: $data) {
      ...LocationFragment
    }
  }
  ${LOCATION_FRAGMENT}
`;

export const UPDATE_PRODUCT = gql`
  mutation UPDATE_PRODUCT(
    $record: UpdateOneProductInput!
    $filter: FilterUpdateOneProductInput
  ) {
    updateProduct(record: $record, filter: $filter) {
      recordId
    }
  }
`;

export const CREATE_LOCATION = gql`
  mutation CREATE_LOCATION($data: CreateLocationInput!) {
    createLocation(data: $data) {
      ...LocationFragment
    }
  }
  ${LOCATION_FRAGMENT}
`;

export const UPDATE_VARIANT = gql`
  mutation UPDATE_VARIANT(
    $record: UpdateOneVariantInput!
    $filter: FilterUpdateOneVariantInput
  ) {
    updateVariant(record: $record, filter: $filter) {
      recordId
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation CREATE_PRODUCT($data: CreateProductInput!) {
    createProduct(data: $data) {
      _id
    }
  }
`;

export const CREATE_ADDRESS = gql`
  mutation CREATE_ADDRESS($record: CreateOneAddressInput!) {
    createAddress(record: $record) {
      recordId
    }
  }
`;

export const CREATE_VARIANT = gql`
  mutation CREATE_VARIANT($record: CreateOneVariantInput!) {
    createVariant(record: $record) {
      record {
        ...VariantFragment
      }
    }
  }
  ${VARIANT_FRAGMENT}
`;

export const UPDATE_STOCK_INVENTORY = gql`
  mutation UPDATE_STOCK_INVENTORY($data: UpdateStockInventoryInput) {
    updateStockInventory(data: $data) {
      alert
      variant {
        ...VariantFragment
      }
    }
  }
  ${VARIANT_FRAGMENT}
`;

export const UPDATE_TAX = gql`
  mutation UPDATE_TAX(
    $record: UpdateOneTaxInput!
    $filter: FilterUpdateOneTaxInput
  ) {
    updateTax(record: $record, filter: $filter) {
      recordId
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation UPDATE_CATEGORY(
    $record: UpdateOneCategoryInput!
    $filter: FilterUpdateOneCategoryInput
  ) {
    updateCategory(record: $record, filter: $filter) {
      recordId
    }
  }
`;

export const CREATE_TAX = gql`
  mutation CREATE_TAX($record: CreateOneTaxInput!) {
    createTax(record: $record) {
      recordId
    }
  }
`;

export const UPDATE_ORDER = gql`
  mutation UPDATE_ORDER(
    $record: UpdateOneOrderInput!
    $filter: FilterUpdateOneOrderInput
  ) {
    updateOrder(record: $record, filter: $filter) {
      record {
        ...OrderFragment
      }
    }
  }
  ${ORDER_FRAGMENT}
`;

export const CREATE_ORDER = gql`
  mutation CREATE_ORDER($data: CreateOrderInput!) {
    createOrder(data: $data) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

export const CREATE_APPOINTMENT = gql`
  mutation CREATE_APPOINTMENT($data: CreateAppointmentInput) {
    createAppointment(data: $data) {
      ...AppointmentFragment
    }
  }
  ${APPOINTMENT_FRAGMENT}
`;

export const RESCHEDULE_APPOINTMENT = gql`
  mutation RESCHEDULE_APPOINTMENT($data: RescheduleAppointmentInput) {
    rescheduleAppointment(data: $data) {
      ...AppointmentFragment
    }
  }
  ${APPOINTMENT_FRAGMENT}
`;

export const CREATE_NEXT_BLOCK_APPOINTMENT = gql`
  mutation CREATE_NEXT_BLOCK_APPOINTMENT($data: NextBlockInput) {
    createNextBlockAppointment(data: $data) {
      success
    }
  }
`;

export const CREATE_VEHICLE = gql`
  mutation CREATE_VEHICLE($data: CreateVehicleInput!) {
    createVehicle(data: $data) {
      ...VehicleFragment
    }
  }
  ${VEHICLE_FRAGMENT}
`;

export const LINK_VEHICLE = gql`
  mutation LINK_VEHICLE($data: LinkVehicleInput) {
    linkVehicle(data: $data) {
      ...VehicleFragment
    }
  }
  ${VEHICLE_FRAGMENT}
`;

// ---------------------------------- shopcart

export const ADD_TO_SHOPCART = gql`
  mutation ADD_TO_SHOPCART($data: AddToShopcartInput) {
    addToShopcart(data: $data) {
      shopcart {
        ...ShopcartFragment
      }
      tally {
        subtotal
        total
        discountTotal
        totalTax
      }
    }
  }
  ${SHOPCART_FRAGMENT}
`;

export const DELETE_FROM_SHOPCART = gql`
  mutation DELETE_FROM_SHOPCART($data: DeleteFromShopcart) {
    deleteFromShopcart(data: $data) {
      shopcart {
        ...ShopcartFragment
      }
      tally {
        subtotal
        total
        discountTotal
        totalTax
      }
    }
  }

  ${SHOPCART_FRAGMENT}
`;

export const UPDATE_DISCOUNT = gql`
  mutation UPDATE_DISCOUNT(
    $record: UpdateOneDiscountInput!
    $filter: FilterUpdateOneDiscountInput
  ) {
    updateDiscount(record: $record, filter: $filter) {
      recordId
    }
  }
`;

export const UPDATE_APPOINTMENT = gql`
  mutation UPDATE_APPOINTMENT(
    $record: UpdateOneAppointmentInput!
    $filter: FilterUpdateOneAppointmentInput
  ) {
    updateAppointment(record: $record, filter: $filter) {
      recordId
    }
  }
`;

export const UPDATE_CATEGORY_CUSTOM = gql`
  mutation UPDATE_CATEGORY_CUSTOM($data: UpdateCategoryInput) {
    updateCategoryCustom(data: $data) {
      ...CategoryFragment
    }
  }
  ${CATEGORY_FRAGMENT}
`;

export const CREATE_CATEGORY = gql`
  mutation CREATE_CATEGORY($data: CreateCategoryInput!) {
    createCategory(data: $data) {
      ...CategoryFragment
    }
  }
  ${CATEGORY_FRAGMENT}
`;

export const CREATE_DISCOUNT = gql`
  mutation CREATE_DISCOUNT($record: CreateOneDiscountInput!) {
    createDiscount(record: $record) {
      recordId
    }
  }
`;

export const UPDATE_REPAIR = gql`
  mutation UPDATE_REPAIR(
    $record: UpdateOneRepairInput!
    $filter: FilterUpdateOneRepairInput
  ) {
    updateRepair(record: $record, filter: $filter) {
      record {
        ...RepairFragment
      }
    }
  }
  ${REPAIR_FRAGMENT}
`;

export const RESET_PASSWORD = gql`
  mutation RESET_PASSWORD($data: ResetPasswordInput) {
    resetPassword(data: $data) {
      success
      err
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation CHANGE_PASSWORD($data: ChangePasswordInput) {
    changePassword(data: $data) {
      success
      err
    }
  }
`;

export const SIGN_UP_WITHOUT_TOKEN = gql`
  mutation SIGN_UP_WITHOUT_TOKEN($data: SignUpInput) {
    signUpWithoutToken(data: $data) {
      user {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const ADD_ITEMS_ORDER = gql`
  mutation ADD_ITEMS_ORDER($data: AddItemsOrder) {
    addItemsOrder(data: $data) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

export const REMOVE_ITEMS_ORDER = gql`
  mutation REMOVE_ITEMS_ORDER($data: RemoveItemsOrderInput) {
    removeItemsOrder(data: $data) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

export const UPDATE_CURRENCY = gql`
  mutation UPDATE_CURRENCY(
    $record: UpdateOneCurrencyInput!
    $filter: FilterUpdateOneCurrencyInput
  ) {
    updateCurrency(record: $record, filter: $filter) {
      record {
        ...CurrencyFragment
      }
    }
  }
  ${CURRENCY_FRAGMENT}
`;

export const CREATE_CURRENCY = gql`
  mutation CREATE_CURRENCY($record: CreateOneCurrencyInput!) {
    createCurrency(record: $record) {
      record {
        ...CurrencyFragment
      }
    }
  }
  ${CURRENCY_FRAGMENT}
`;

export const DECREASE_ITEMS_ORDER = gql`
  mutation DECREASE_ITEMS_ORDER($data: DecreaseItemsOrderInput) {
    decreaseItemsOrder(data: $data) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

export const CREATE_ORDER_WITHOUT_APPOINTMENT = gql`
  mutation CREATE_ORDER_WITHOUT_APPOINTMENT(
    $data: CreateOrderWithoutAppointmentInput
  ) {
    createOrderWithoutAppointment(data: $data) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

// export const CREATE_ORDER_FLUSH = gql`
//   mutation CREATE_ORDER_FLUSH($data: OrderFlushInput) {
//     orderFlush(data: $data) {
//       ...OrderFragment
//     }
//   }
//   ${ORDER_FRAGMENT}
// `;

export const ADD_FLUSH_TO_STEPS = gql`
  mutation ADD_FLUSH_TO_STEPS($data: AddFlushToStepsInput) {
    addFlushToSteps(data: $data) {
      ...RepairFragment
    }
  }
  ${REPAIR_FRAGMENT}
`;

export const UNDO_NEXT_BLOCK = gql`
  mutation UNDO_NEXT_BLOCK($data: NextBlockInput) {
    undoNextBlock(data: $data) {
      success
    }
  }
`;

export const APPLY_DISCOUNT = gql`
  mutation APPLY_DISCOUNT($data: ApplyDiscountInput) {
    applyDiscount(data: $data) {
      ...DiscountFragment
    }
  }
  ${DISCOUNT_FRAGMENT}
`;

export const CANCEL_APPOINTMENT = gql`
  mutation CANCEL_APPOINTMENT($data: CancelAppointmentInput) {
    cancelAppointment(data: $data) {
      ...AppointmentFragment
    }
  }
  ${APPOINTMENT_FRAGMENT}
`;

export const NEW_APPOINTMENT_ORDER = gql`
  mutation NEW_APPOINTMENT_ORDER($data: NewAppointmentOrderInput) {
    newAppointmentOrder(data: $data) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

export const UPDATE_VEHICLE = gql`
  mutation UPDATE_VEHICLE(
    $record: UpdateOneVehicleInput!
    $filter: FilterUpdateOneVehicleInput
  ) {
    updateVehicle(record: $record, filter: $filter) {
      recordId
      record {
        ...VehicleFragment
      }
    }
  }
  ${VEHICLE_FRAGMENT}
`;

export const CREATE_USER = gql`
  mutation CREATE_USER($data: CreateUser!) {
    createUser(data: $data) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const UNLINK_MANY_VEHICLES = gql`
  mutation UNLINK_MANY_VEHICLES($data: UntangleVehiclesInput!) {
    unlinkManyVehicles(data: $data) {
      ...VehicleFragment
    }
  }
  ${VEHICLE_FRAGMENT}
`;

export const VERIFY_VEHICLE_OWNER = gql`
  mutation VerifyVehicleOwner($data: VerifyVehicleOwnerInput) {
    verifyVehicleOwner(data: $data) {
      verified
    }
  }
`;
