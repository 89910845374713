import React from 'react';
import Link from 'next/link';
import { Hit, SearchResponse } from '@algolia/client-search';
import { DotIcon, Select, Table } from '@spa-cars/ui';
import { useLazyQuery } from '@apollo/client';
import { validateString } from 'avilatek-utils';
import { Category, PaginationInfo, Product } from '@spa-cars/models';
import { useAlgolia } from '../../hooks';
import { GET_PRODUCTS_LIST } from '../../graphql/queries';

const getStatusName = (status: string) => {
  switch (status) {
    case 'active':
      return 'Activo';

    case 'archived':
      return 'Archivado';

    case 'draft':
      return 'Borrador';

    default:
      return 'N/A';
  }
};

const getStatusColor = (status: string) => {
  switch (status) {
    case 'active':
      return 'text-primary-300';

    case 'archived':
      return 'text-danger-200';

    case 'draft':
      return 'text-neutral-200';

    default:
      return 'text-text-white';
  }
};

const buildTableData = (products: Array<Product | Hit<Product>> = []) =>
  products.map((product) => ({
    _id: product._id ?? (product as any).id,
    title: product?.title ?? 'N/A',
    categories:
      product?.categories?.length > 0
        ? product?.categories?.map((c) => (c as Category)?.title)?.join(', ')
        : 'N/A',
    status: (
      <p className="flex gap-[10px] items-center">
        <DotIcon
          className={` ${getStatusColor(product?.status)} w-[15px] h-[15px]`}
        />
        {getStatusName(product?.status)}
      </p>
    ),
  }));

interface PaginationDataProps {
  items: Array<Product>;
  count: number;
  pageInfo: PaginationInfo;
}

export default function ProductTable() {
  const searchProduct = useAlgolia();
  const searchIndex = searchProduct.initIndex('products');
  const [productType, setProductType] = React.useState<string>('none');
  const [searchResult, setSearchResult] = React.useState<Hit<Product>[]>(null);
  const [paginationData, setPaginationData] = React.useState<
    PaginationDataProps | SearchResponse<Product>
  >(null);
  const [perPage, setPerPage] = React.useState(10);
  const [searchInput, setSearchInput] = React.useState(''); // guarda lo que el usuario ingresa en el input de busqueda
  const [loadingSearch, setLoadingSearch] = React.useState(false);
  const [loadProducts, { data, loading }] = useLazyQuery<{
    productPagination: {
      items: Array<Product>;
      count: number;
      pageInfo: PaginationInfo;
    };
  }>(GET_PRODUCTS_LIST);

  const queryVariables = {
    perPage: perPage ?? 10,
    sort: 'CREATEDAT_DESC',
    filter: {
      placeToSell: productType === 'none' ? undefined : productType,
    },
  };

  const handleGoToPage = (value) => {
    if (validateString(searchInput)) {
      searchProducts(searchInput, value - 1); // ir a la siguiente pagina de la busqueda de algolia
    } else {
      loadProducts({
        variables: {
          page: value ?? 1,
          ...queryVariables,
        },
        fetchPolicy: 'cache-and-network',
      }); // ir a la siguiente pagina del query
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <p className="flex justify-between items-center">
            TÍTULO
            {/* <MenuIcon className="w-5 h-5 text-neutral-100" /> */}
          </p>
        ),

        accessor: 'title',
      },
      {
        Header: (
          <p className="flex justify-between items-center">
            CATEGORÍAS
            {/* <MenuIcon className="w-5 h-5 text-neutral-100" /> */}
          </p>
        ),
        accessor: 'categories',
      },
      {
        Header: (
          <p className="flex justify-between items-center">
            ESTATUS
            {/* <MenuIcon className="w-5 h-5 text-neutral-100" /> */}
          </p>
        ),
        accessor: 'status',
      },
    ],
    []
  );

  const filterResults = React.useCallback(
    (results: SearchResponse<Product>) => results?.hits ?? [],
    []
  );

  const searchProducts = async (value: string, page_: number) => {
    try {
      if (!validateString(value) || value === '') {
        loadProducts({
          variables: {
            page: 1,
            ...queryVariables,
          },
          fetchPolicy: 'cache-and-network',
        });
        setPaginationData(data?.productPagination);
        setSearchResult([]);
        return;
      }
      setLoadingSearch(true);
      const result = await searchIndex.search<Product>(value, {
        filters:
          productType !== 'none' ? `placeToSell:${productType}` : undefined,
        page: page_,
        hitsPerPage: perPage ?? 10,
      });
      setLoadingSearch(false);
      setPaginationData(result);
      const resultsFiltered = filterResults(result);
      setSearchResult(resultsFiltered);
    } catch (err) {
      console.log(err);
    }
  };

  const onChangeSearch = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setSearchInput(e.target.value);
    },
    []
  );

  const dataMemo = React.useMemo(
    () =>
      searchResult?.length > 0
        ? buildTableData(searchResult)
        : buildTableData((paginationData as PaginationDataProps)?.items) ?? [],
    [paginationData, searchResult]
  );

  React.useEffect(() => {
    if (data) {
      setPaginationData(data?.productPagination);
    }
  }, [data]);

  React.useEffect(() => {
    searchProducts(searchInput, 0); // para algolia, la paginación empieza en 0
  }, [productType, searchInput, perPage]);

  return (
    <div className="flex flex-col gap-4">
      <Select
        name=""
        value={productType ?? 'none'}
        // label="Turno"
        onChange={(e) => {
          e.preventDefault();
          setProductType(e.target.value);
        }}
        className="pr-10 w-fit shadow-[0_2px_5px_rgba(0,0,0,0.25)] focus:shadow-[0_2px_5px_rgba(0,0,0,0.25)] rounded border-none"
      >
        <option value="none">Todos los productos</option>
        <option key="ecommerce" value="ecommerce">
          Productos
        </option>
        <option key="service" value="service">
          Servicios
        </option>
        <option key="service_product" value="service_product">
          Productos usados durante el servicio
        </option>
      </Select>
      <Table
        columns={columns}
        data={dataMemo}
        perPage={perPage}
        setPerPage={setPerPage}
        error={null}
        loading={loading || loadingSearch}
        pageCount={0}
        header="Productos"
        pageNumber={
          ((paginationData as SearchResponse<Product>)?.page ??
            ((paginationData as PaginationDataProps)?.pageInfo?.currentPage ??
              1) - 1) + 1
        }
        addButtonText="Producto"
        handleGoToPage={handleGoToPage}
        results={
          (paginationData as SearchResponse<Product>)?.nbHits ??
          (paginationData as PaginationDataProps)?.count
        }
        path="app/products"
        link={Link}
        onChangeSearch={onChangeSearch}
      />
    </div>
  );
}
