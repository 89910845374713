import { Multimedia, User, DniTypeEnum } from '@spa-cars/models';

export const ACTIONS = {
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME',
  EMAIL: 'EMAIL',
  PASSWORD: 'PASSWORD',
  DNI: 'DNI',
  DNI_TYPE: 'DNI_TYPE',
  PHONE: 'PHONE',
  PHOTO: 'PHOTO',
  LOCATION: 'LOCATION',
  SHIFT: 'SHIFT',
  OBSERVATION: 'OBSERVATION',
  DEFAULT: 'DEFAULT',
} as const;

export type ACTIONS_TYPE =
  | { type: 'FIRST_NAME'; payload: string }
  | { type: 'LAST_NAME'; payload: string }
  | { type: 'EMAIL'; payload: string }
  | { type: 'PASSWORD'; payload: string }
  | { type: 'DNI'; payload: string }
  | { type: 'DNI_TYPE'; payload: DniTypeEnum }
  | { type: 'PHONE'; payload: string }
  | { type: 'PHOTO'; payload: Multimedia }
  | { type: 'LOCATION'; payload: string }
  | { type: 'SHIFT'; payload: 'morning' | 'afternoon' }
  | { type: 'OBSERVATION'; payload: string }
  | { type: 'DEFAULT'; payload: Partial<User> };

export function reducer(
  state: Partial<User>,
  action: ACTIONS_TYPE
): Partial<User> {
  switch (action.type) {
    case ACTIONS.FIRST_NAME:
      return { ...state, firstName: action.payload };
    case ACTIONS.LAST_NAME:
      return { ...state, lastName: action.payload };
    case ACTIONS.SHIFT:
      return { ...state, shift: action.payload };
    case ACTIONS.PHONE:
      return { ...state, phone: { ...state.phone, phone: action.payload } };
    case ACTIONS.EMAIL:
      return { ...state, email: action.payload };
    case ACTIONS.PASSWORD:
      return { ...state, password: action.payload };
    case ACTIONS.DNI:
      return { ...state, dni: action.payload };
    case ACTIONS.OBSERVATION:
      return { ...state, observation: action.payload };
    case ACTIONS.DNI_TYPE:
      return { ...state, dniType: action.payload };
    case ACTIONS.LOCATION:
      return { ...state, location: action.payload };
    case ACTIONS.PHOTO:
      return { ...state, photo: { ...action.payload } };
    case ACTIONS.DEFAULT:
      return { ...state, ...(action?.payload ?? {}) };
    default:
      return { ...state };
  }
}
