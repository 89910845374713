import React from 'react';
import NProgress from 'nprogress';
import { AfternoonIcon, Card, CloseIcon, SunIcon } from '@spa-cars/ui';
import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import { useRouter } from 'next/router';
import { User, Location } from '@spa-cars/models';
import { shiftTypes } from '../../../lib';
import { UPDATE_USER } from '../../../graphql/mutations';
import { useNotify } from '../../../hooks';
import ConfirmationModal from './ConfirmationModal';

interface OperatorCardProps {
  operator: Partial<User>;
  refetch: (variables?: Partial<OperationVariables>) => Promise<
    ApolloQueryResult<{
      users: User[];
    }>
  >;
  changeShiftTo?: 'morning' | 'afternoon';
  setAdd: React.Dispatch<React.SetStateAction<boolean>>;
}
function OperatorCard({
  operator,
  refetch,
  changeShiftTo = null,
  setAdd,
}: OperatorCardProps) {
  const [disabled, setDisabled] = React.useState(false);
  const [isOpen, setOpen] = React.useState(false);
  const notify = useNotify();
  const router = useRouter();

  const [updateUser] = useMutation<{
    updateUser: {
      recordId: string;
    };
  }>(UPDATE_USER);

  const deleteOperatorShift = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      NProgress.start();
      e.preventDefault();
      if (e.stopPropagation) e.stopPropagation();
      setDisabled(true);
      if (operator) {
        const { data } = await updateUser({
          variables: {
            filter: {
              _id: operator._id,
            },
            record: {
              shift: null,
            },
          },
        });
        if (data?.updateUser?.recordId) {
          notify(
            'Se le ha eliminado el turno al operador con éxito',
            'success'
          );
        } else {
          notify('Ha ocurrido un error al eliminar el turno', 'error');
        }
      }
      refetch();
      setDisabled(false);
    } catch (err) {
      notify(err.message, 'error');
      setDisabled(false);
    } finally {
      NProgress.done();
    }
  };

  const changeOperatorShift = async () => {
    try {
      NProgress.start();
      setDisabled(true);
      if (operator) {
        const { data } = await updateUser({
          variables: {
            filter: {
              _id: operator._id,
            },
            record: {
              shift: changeShiftTo,
            },
          },
        });
        if (data?.updateUser?.recordId) {
          notify(
            'Se le ha actualizado el turno al operador con éxito',
            'success'
          );
        } else {
          notify('Ha ocurrido un error al actualizar el turno', 'error');
        }
      }
      refetch();
      setDisabled(false);
      setAdd(false);
    } catch (err) {
      notify(err.message, 'error');
      setDisabled(false);
    } finally {
      NProgress.done();
    }
  };

  return (
    <Card
      className={`p-4 shadow-[0_2px_5px_rgba(0,0,0,0.25)]  ${
        changeShiftTo ? ' cursor-pointer' : 'cursor-pointer'
      }`}
      onClick={() => {
        if (changeShiftTo) {
          changeOperatorShift();
        } else {
          router.push(`/app/operators/${operator?._id}`);
        }
      }}
    >
      <ConfirmationModal
        isOpen={isOpen}
        setOpen={setOpen}
        deleteOperatorShift={deleteOperatorShift}
        operator={operator}
      />
      <div className="flex gap-4">
        <img
          className="w-[25px] h-[25px] rounded-full bg-neutral-50  object-center object-cover"
          src={operator?.photo?.src ?? '/img/placeholder/user.png'}
          alt="profile"
        />
        <div className="w-full">
          <div className="flex gap-4 align-middle mb-[6px]">
            <span className=" font-medium text-sm text-black my-auto">
              {`${operator?.firstName ?? ''} ${operator?.lastName ?? ''}`}
            </span>
            {!changeShiftTo ? (
              <button
                type="button"
                className={` hover:bg-opacity-70 z-50 top-0 -right-0 mt-2 mr-2 p-1 bg-danger-200 rounded-full ml-auto h-fit absolute`}
                onClick={(e) => {
                  e.preventDefault();
                  if (e.stopPropagation) e.stopPropagation();
                  setOpen(true);
                }}
              >
                <CloseIcon className="w-3 h-3 text-neutral-50" />
              </button>
            ) : null}
          </div>
          <div className=" bg-neutral-100 text-xs text-text-white rounded py-0.5 px-4 mb-1 w-fit">
            {`${(operator?.location as Location)?.name}`}
          </div>
          {operator?.shift ? (
            <h3 className="text-neutral-300 flex align-middle text-center text-xs">
              {operator?.shift === 'morning' ? (
                <SunIcon className=" h-3 w-3 text-secondary-300 mr-1 my-auto" />
              ) : (
                <AfternoonIcon className=" h-3 w-3 text-neutral-300 mr-[10px] my-auto" />
              )}
              {
                shiftTypes?.find((shift) => shift?.value === operator?.shift)
                  ?.name
              }
            </h3>
          ) : (
            <h3 className="text-neutral-300 flex align-middle text-center text-xs">
              Sin turno
            </h3>
          )}
        </div>
      </div>
    </Card>
  );
}

export default OperatorCard;
