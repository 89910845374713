import { gql } from '@apollo/client';

export const TAX_FRAGMENT = gql`
  fragment TaxFragment on Tax {
    _id
    name
    value
    active
  }
`;

export const MULTIMEDIA_FRAGMENT = gql`
  fragment MultimediaFragment on Multimedia {
    _id
    src
    alt
    type
  }
`;

export const PHONE_FRAGMENT = gql`
  fragment PhoneFragment on Phone {
    _id
    code
    phone
    active
  }
`;

export const ADDRESS_FRAGMENT = gql`
  fragment AddressFragment on Address {
    slug
    country
    province
    municipality
    parish
    city
    zip
    address1
    coordinates {
      type
      coordinates
      _id
    }
    extra
    active
    _id
  }
`;

export const LOCATION_FRAGMENT = gql`
  fragment LocationFragment on Location {
    _id
    name
    description
    indexa
    photos {
      ...MultimediaFragment
    }
    address {
      ...AddressFragment
    }
    active
    booths
  }
  ${MULTIMEDIA_FRAGMENT}
  ${ADDRESS_FRAGMENT}
`;

export const VARIANT_FRAGMENT = gql`
  fragment VariantFragment on Variant {
    _id
    title
    barcode
    cost
    price
    indexa
    compareAtPrice
    photo {
      ...MultimediaFragment
    }
    stock
    minStock
    maxStock
    option {
      option1
      option2
      option3
    }
    location {
      ...LocationFragment
    }
    inventoryHistory {
      date
      cost
      quantity
      movementType
    }
    sku
    active
  }
  ${LOCATION_FRAGMENT}
`;

export const SUBCATEGORY_FRAGMENT = gql`
  fragment SubcategoryFragment on Subcategory {
    _id
    title
    slug
    description
    icon
    active
  }
`;

export const CATEGORY_FRAGMENT = gql`
  fragment CategoryFragment on Category {
    _id
    title
    slug
    description
    icon
    subcategories {
      ...SubcategoryFragment
    }
    active
  }
  ${SUBCATEGORY_FRAGMENT}
`;

export const PRODUCT_FRAGMENT = gql`
  fragment ProductFragment on Product {
    _id
    title
    slug
    description
    brand
    priority
    placeToSell
    photos {
      ...MultimediaFragment
    }
    rating
    points
    indexa
    reviews
    volatileInventory
    options {
      name
      values
    }
    variants {
      ...VariantFragment
    }
    categories {
      ...CategoryFragment
    }
    extraInfo {
      _id
      name
      value
    }
    comments {
      _id
      title
      text
      rating
    }
    tags
    taxes {
      _id
      name
      value
    }
    likes
    history {
      _id
      user
      actionType
      comment
      createdAt
    }
    status
    active
  }
  ${MULTIMEDIA_FRAGMENT}
  ${VARIANT_FRAGMENT}
  ${CATEGORY_FRAGMENT}
`;

export const DRIVER_FRAGMENT = gql`
  fragment driverFragment on Driver {
    _id
    name
    dni
    dniType
    phone {
      ...PhoneFragment
    }
    active
  }
  ${PHONE_FRAGMENT}
`;

export const SPECS_FRAGMENT = gql`
  fragment SpecsFragment on Specs {
    _id
    coolantColor
    oilFilter
    oilOrigin
    oilViscosity
    psi
    screenWasher
    tireFiller
    oilQuantity
  }
`;

export const VEHICLE_FRAGMENT = gql`
  fragment VehicleFragment on Vehicle {
    _id
    brand {
      _id
      name
    }
    model {
      _id
      name
    }
    version {
      _id
      engine {
        transmission
        volume
        cylinders
        valves
      }
      year
    }
    plate
    engineSerial
    vin
    color
    note
    owner
    active
    token
    specs {
      ...SpecsFragment
    }
  }
  ${SPECS_FRAGMENT}
`;

export const ORDER_PRODUCT_FRAGMENT = gql`
  fragment OrderProductFragment on OrderProduct {
    _id
    title
    cost
    discount
    quantity
    price
    option {
      _id
      option1
      option2
      option3
    }
    product {
      ...ProductFragment
    }
    photo {
      ...MultimediaFragment
    }
    location {
      ...LocationFragment
    }
    variant {
      ...VariantFragment
    }
    taxes {
      _id
      value
      tax
    }
  }
  ${MULTIMEDIA_FRAGMENT}
  ${LOCATION_FRAGMENT}
  ${VARIANT_FRAGMENT}
  ${PRODUCT_FRAGMENT}
`;

export const SHOPCART_FRAGMENT = gql`
  fragment ShopcartFragment on Shopcart {
    _id
    client
    status
    items {
      ...OrderProductFragment
    }
  }

  ${PRODUCT_FRAGMENT}
  ${ORDER_PRODUCT_FRAGMENT}
`;

export const CLIENT_FRAGMENT = gql`
  fragment ClientFragment on Client {
    _id
    acceptsMarketing
    user
    notes
    points
    birthdate
    #promos
    #vehicles
    #addresses
    shopCart {
      ...ShopcartFragment
    }
  }
  ${MULTIMEDIA_FRAGMENT}
  ${PHONE_FRAGMENT}
  ${SHOPCART_FRAGMENT}
`;

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    _id
    firstName
    lastName
    userType
    email
    locale
    client {
      ...ClientFragment
    }
    phone {
      ...PhoneFragment
    }
    photo {
      ...MultimediaFragment
    }
    dni
    dniType
    active
    shift
    location {
      ...LocationFragment
    }
    permission {
      name
      key
      options
    }
    createdAt
    updatedAt
  }
  ${LOCATION_FRAGMENT}
  ${MULTIMEDIA_FRAGMENT}
  ${PHONE_FRAGMENT}
  ${CLIENT_FRAGMENT}
`;

export const ORDER_FRAGMENT = gql`
  fragment OrderFragment on Order {
    _id
    status
    paymentStatus
    user {
      ...UserFragment
    }
    client
    subtotal
    extraFee
    discountTotal
    taxBase
    taxTotal
    total
    gatewayCommission
    promoCodes
    billingAddress
    note
    ownFlush
    ownFilter
    ownOil
    discounts {
      _id
      discount
      value
    }
    history {
      _id
      user
      actionType
      comment
      createdAt
    }
    paymentInfo {
      _id
      gateway
      amount
      currency
      rate
      metadata
    }
    items {
      ...OrderProductFragment
    }
    createdAt
    updatedAt
    location {
      ...LocationFragment
    }
  }
  ${ORDER_PRODUCT_FRAGMENT}
  ${USER_FRAGMENT}
  ${LOCATION_FRAGMENT}
`;

export const APPOINTMENT_FRAGMENT = gql`
  fragment AppointmentFragment on Appointment {
    _id
    date
    flush
    show
    user {
      ...UserFragment
    }
    order {
      ...OrderFragment
    }
    active
    user {
      ...UserFragment
    }
    location {
      ...LocationFragment
    }
    vehicle {
      ...VehicleFragment
    }
    driver {
      ...driverFragment
    }
    status
    services {
      name
      order
      active
    }
    subOrders {
      ...OrderFragment
    }
    reservationNumber
    createdAt
    updatedAt
  }
  ${VEHICLE_FRAGMENT}
  ${DRIVER_FRAGMENT}
  ${LOCATION_FRAGMENT}
  ${ORDER_FRAGMENT}
  ${USER_FRAGMENT}
`;

export const DISCOUNT_FRAGMENT = gql`
  fragment DiscountFragment on Discount {
    _id
    name
    code
    value
    show
    photo {
      ...MultimediaFragment
    }
    type
    date
    category {
      ...CategoryFragment
    }
    url
    show
    active
  }
  ${MULTIMEDIA_FRAGMENT}
  ${CATEGORY_FRAGMENT}
`;

export const REPAIR_FRAGMENT = gql`
  fragment RepairFragment on Repair {
    _id
    vehicle
    mechanic
    order
    start
    finish
    description
    mileage
    process {
      _id
      step
      title
      date
      issueStatus
      issueOptions
      issue
      issueDescription
      resultStatus
      resultOptions
      result
      active
      stepInstruction {
        instruction
        description
        photo {
          ...MultimediaFragment
        }
      }
    }
    items
    extraFee
    status
    photo {
      ...MultimediaFragment
    }
    subtotal
    discount
    taxes
    total
    currentStep
    active
    createdAt
    updatedAt
  }
  ${MULTIMEDIA_FRAGMENT}
`;

export const CURRENCY_FRAGMENT = gql`
  fragment CurrencyFragment on Currency {
    _id
    name
    symbol
    rate
    localCoin
    active
  }
`;

export const ACCOUNT_FRAGMENT = gql`
  fragment AccountFragment on Account {
    _id
    status
    user {
      ...UserFragment
    }
    expiredDate
    balance
    appointments {
      ...AppointmentFragment
    }
    createdAt
    updatedAt
  }
  ${USER_FRAGMENT}
  ${APPOINTMENT_FRAGMENT}
`;
