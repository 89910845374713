import React from 'react';
import { motion } from 'framer-motion';
import { CheckIcon, UserIcon } from '@spa-cars/ui';
import { Appointment, User } from '@spa-cars/models';

interface ClientCardProps {
  state: Partial<Appointment>;
}

function ClientCard({ state }: ClientCardProps) {
  return (
    <motion.li
      layout
      className="p-4 relative flex flex-col min-w-0 bg-white bg-clip-border shadow-[0_4px_10px_rgba(0,0,0,0.25)] rounded-lg "
    >
      <div className="flex w-full justify-between items-center">
        <div className="flex gap-4 items-center">
          <div className="bg-neutral-300 rounded-full p-1 w-fit h-fit">
            <UserIcon className="h-4 w-4 text-neutral-50" />
          </div>
          <div className="flex flex-col gap-1">
            <div className="flex gap-2">
              <h2 className=" text-neutral-100 text-sm">CLIENTE</h2>
              <CheckIcon className="h-4 w-4 text-primary-300" />
            </div>
            <div className="flex gap-2">
              <img
                className=" w-[20px] h-[20px] rounded-full bg-neutral-50  object-center object-cover"
                src={
                  (state?.user as User)?.photo?.src ??
                  '/img/placeholder/user.png'
                }
                alt="profile"
              />
              <span className="text-text-black text-sm ">
                {`${(state?.user as User)?.firstName ?? 'N/A'} ${
                  (state?.user as User)?.lastName ?? ''
                }`}
              </span>
              <span className="text-text-black text-sm ml-2">
                {`${(state?.user as User)?.phone?.code ?? ''} ${
                  (state?.user as User)?.phone?.phone ?? 'N/A'
                }`}
              </span>
            </div>
          </div>
        </div>
      </div>
    </motion.li>
  );
}

export default ClientCard;
