import React from 'react';
import dayjs from 'dayjs';
import { CheckIcon, CloseIcon, DotIcon, TimerIcon } from '@spa-cars/ui';
import { useRouter } from 'next/router';
import {
  Appointment,
  AppointmentStatusEnum,
  VehicleModel,
} from '@spa-cars/models';
import { useNotify } from '../../../hooks';

interface AppointmentCardProps {
  appointment: Appointment;
}

const getStatusType = (statusType: AppointmentStatusEnum, date: Date) => {
  switch (statusType) {
    case 'not_started':
      if (dayjs(date).isBefore(dayjs())) return 'Vencida';
      return 'Pendiente';
    case 'in_progress':
      return 'En proceso';
    case 'completed':
      return 'Completada';
    case 'cancelled':
      return 'Cancelada';
    default:
      return 'N/A';
  }
};
const getStatusTypeIcon = (statusType: AppointmentStatusEnum, date: Date) => {
  switch (statusType) {
    case 'not_started':
      if (dayjs(date).isBefore(dayjs())) {
        return <TimerIcon className="h-4 w-4 text-danger-200" />;
      }
      return <DotIcon className="h-4 w-4 text-success-300" />;
    case 'in_progress':
      return <TimerIcon className="h-4 w-4 text-neutral-200" />;
    case 'completed':
      return <CheckIcon className="h-4 w-4 text-success-300" />;
    case 'cancelled':
      return <CloseIcon className="h-4 w-4 text-danger-200" />;
    default:
      return '';
  }
};

function AppointmentCard({ appointment }: AppointmentCardProps) {
  const router = useRouter();
  const notify = useNotify();
  return (
    <button
      type="button"
      className=" rounded-lg p-4 shadow-[0_2px_5px_rgba(0,0,0,0.25)] w-full md:w-[270px] flex flex-row gap-4"
      onClick={(e) => {
        e.preventDefault();
        if (!appointment?.order) {
          notify('Esta orden se encuentra en proceso de pago', 'warning');
        } else {
          router.push(`/app/orders/${appointment?.order?._id}`);
        }
      }}
    >
      <div className="w-fit">
        <img
          className="w-[25px] h-[25px] rounded-full bg-neutral-50  object-center object-cover"
          src={appointment?.user?.photo?.src ?? '/img/placeholder/user.png'}
          alt="profile"
        />
      </div>
      <div className=" flex flex-col gap-1 w-full">
        <span className=" font-medium text-sm text-black leading-3">
          {appointment?.driver?.name ?? 'Conductor'}{' '}
          {appointment?.services?.find((s) => s.name === 'flush') ||
          appointment?.order?.withFlush
            ? '(Flush)'
            : ''}
        </span>
        <div className="flex">
          {(appointment?.vehicle?.model as VehicleModel)?.name
            ?.split(' ')
            ?.map((word) => (
              <p
                key={`model-${word}`}
                className="text-[10px] text-neutral-100 leading-3"
              >
                {`${word?.charAt(0).toUpperCase()}${word
                  ?.slice(1)
                  .toLowerCase()}`}
                &nbsp;
              </p>
            ))}
        </div>
        <div className="flex gap-2 align-middle">
          <div className="my-auto text-sm">
            {dayjs(appointment?.date).format('hh:mm A')}
          </div>
          <DotIcon className="w-2 h-2 text-neutral-100 my-auto" />
          <div className="flex gap-[6px] align-middle items-center">
            {getStatusTypeIcon(appointment?.status, appointment?.date)}
            {getStatusType(appointment?.status, appointment?.date)}
          </div>
        </div>
        <div className=" bg-neutral-100 text-xs text-text-white rounded py-0.5 px-4 mb-1 w-fit">
          {`${appointment?.location?.name}`}
        </div>
        <div className=" text-primary-400 text-xs px-4 mb-1 w-fit">
          Ver selección del cliente
        </div>
      </div>
    </button>
  );
}

export default AppointmentCard;
