import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { Client, User, Vehicle } from '@spa-cars/models';
import { GET_CLIENT_VEHICLES } from '../graphql/queries';

export function useClientVehicles(client: User) {
  const [vehicles, setVehicles] = React.useState<Vehicle[]>([]);

  const [loadVehicles, { loading, data }] = useLazyQuery<{
    client: {
      vehicles: Vehicle[];
    };
  }>(GET_CLIENT_VEHICLES, {
    fetchPolicy: 'cache-and-network',
  });

  React.useEffect(
    function syncVehicles() {
      setVehicles(
        data?.client?.vehicles && !loading ? data?.client?.vehicles : []
      );
    },
    [data, loading]
  );

  React.useEffect(
    function syncClient() {
      if (client) {
        loadVehicles({
          variables: { filter: { _id: (client.client as Client)?._id } },
        });
      } else {
        setVehicles([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client]
  );

  return vehicles;
}
