import React from 'react';
import { Button, CloseIcon, Line, Modal } from '@spa-cars/ui';
import { User } from '@spa-cars/models';

interface ConfirmationModalProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  operator: Partial<User>;
  deleteOperatorShift: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => Promise<void>;
}

function ConfirmationModal({
  isOpen,
  setOpen,
  operator,
  deleteOperatorShift,
}: ConfirmationModalProps) {
  return (
    <Modal isOpen={isOpen} setOpen={setOpen} className="w-fit md:ml-72 ">
      <div className="bg-gray-50 rounded-lg px-6 py-3">
        {/* Title */}
        <div className="flex justify-between items-center pb-3">
          <h2 className="text-xl text-primary-500 font-medium">
            Eliminar turno
          </h2>
          <button
            type="button"
            aria-label="close"
            onClick={(e) => {
              e.preventDefault();
              if (e.stopPropagation) e.stopPropagation();
              setOpen(false);
            }}
          >
            <CloseIcon className="h-5 w-5 text-red-500" />
          </button>
        </div>
        <Line />
        <div className="py-3">
          ¿Estás seguro de que quieres eliminar el turno del operador "
          {`${operator?.firstName ?? ''} ${operator?.lastName ?? ''}`}"?
        </div>
        <div className="w-full flex justify-end gap-4">
          <Button
            type="button"
            className="w-fit bg-danger-200"
            onClick={(e) => {
              e.preventDefault();
              setOpen(false);
              if (e.stopPropagation) e.stopPropagation();
            }}
          >
            Cancelar
          </Button>
          <Button type="button" className="w-fit" onClick={deleteOperatorShift}>
            Confirmar
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmationModal;
