import React from 'react';
import dayjs from 'dayjs';
import { Card, DateInput } from '@spa-cars/ui';
import { Appointment, Repair } from '@spa-cars/models';

interface DateCardProps {
  repair: Partial<Repair>;
  date: string;
  appointment: Partial<Appointment>;
}

function DateCard({ repair, date, appointment }: DateCardProps) {
  console.log(date);
  return (
    <Card className="p-6">
      <h2 className=" text-neutral-100 text-sm font-semibold">
        INFORMACIÓN DEL SERVICIO
      </h2>
      <span className="text-sm text-neutral-200 my-2">
        Hora agendada: {dayjs(appointment?.date).format('hh:mm A')}
      </span>
      <DateInput
        allowPreviousDates
        viewOnly
        value={date}
        setState={(value: string) => {}}
        open
      />
      <div className="mt-4 w-full flex gap-2 justify-center flex-col md:flex-row items-center">
        <button
          type="button"
          disabled
          className={`appearance-none py-2 px-[10px] leading-4 ${'border-[0.5px] border-primary-400 rounded text-primary-400'} w-fit`}
        >
          Desde:{' '}
          {repair?.start
            ? dayjs(repair?.start).format('hh:mm A')
            : 'No iniciado'}
        </button>
        <button
          type="button"
          disabled
          className={`appearance-none py-2 px-[10px] leading-4 ${'border-[0.5px] border-primary-400 rounded text-primary-400'} w-fit`}
        >
          Hasta:{' '}
          {repair?.finish && repair?.status !== 'started'
            ? dayjs(repair?.finish).format('hh:mm A')
            : 'No terminado'}
        </button>
      </div>
    </Card>
  );
}

export default DateCard;
