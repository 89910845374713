import { Card, FullListIcon } from '@spa-cars/ui';
import dayjs from 'dayjs';
import React from 'react';
import { Appointment } from '@spa-cars/models';
import AppointmentCard from './AppointmentCard';

interface AppointmentListProps {
  appointments: Appointment[];
  date: dayjs.Dayjs;
}

function AppointmentList({ appointments, date }: AppointmentListProps) {
  const months = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ];
  return (
    <Card className=" p-7 flex flex-col gap-6">
      <div className="">
        <h2 className="text-neutral-300 flex text-2xl font-medium ">
          Agenda -&nbsp;
          <p className="text-primary-300">{`${date?.date()}`}</p>
          &nbsp;
          {`de ${months[date?.month()]}`}
        </h2>
      </div>

      <div className="  flex flex-col gap-2">
        <h3 className="text-neutral-300 flex align-middle text-center font-medium ">
          <FullListIcon className=" h-4 w-4 text-neutral-300 mr-[10px] my-auto" />
          Órdenes
        </h3>
        {appointments?.length === 0 ? (
          <p className=" text-sm ">No hay órdenes agendadas</p>
        ) : (
          <div className="flex flex-wrap justify-center md:justify-start gap-4">
            {appointments?.map((appointment) => (
              <AppointmentCard
                appointment={appointment}
                key={appointment?._id}
              />
            ))}
          </div>
        )}
      </div>
    </Card>
  );
}

export default AppointmentList;
