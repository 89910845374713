import React from 'react';
import { Button, CheckIcon, SelectSearch, UserIcon } from '@spa-cars/ui';
import { useQuery } from '@apollo/client';
import { motion } from 'framer-motion';
import { User } from '@spa-cars/models';
import { GET_USERS } from '../../../graphql/queries';

const buildUsersOptions = (users: User[]) =>
  users?.map((user) => ({
    ...user,
    name: `${user?.firstName} ${user?.lastName}`,
  }));

interface OperatorCardProps {
  operator: Partial<User>;
  disabled: boolean;
  locationId: string;
  updateRepairInfo: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => Promise<void>;
  setOperator: React.Dispatch<React.SetStateAction<Partial<User>>>;
}

function OperatorCard({
  operator,
  disabled,
  updateRepairInfo,
  setOperator,
  locationId,
}: OperatorCardProps) {
  const [editOperator, setEditOperator] = React.useState(false);
  const { data: operatorsData } = useQuery<{ users: User[] }>(GET_USERS, {
    variables: {
      filter: {
        active: true,
        userType: 'mechanic',
        location: locationId,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const handleChangeSelectSearch = (value: User, name: string) => {
    setOperator(value);
  };

  return (
    <motion.li
      layout
      transition={{ type: 'spring', bounce: 0 }}
      className="p-4 relative flex flex-col min-w-0 bg-white bg-clip-border shadow-[0_4px_10px_rgba(0,0,0,0.25)] rounded-lg "
    >
      <div className="flex w-full justify-between items-center">
        {editOperator ? (
          <>
            <div className="w-1/2 mr-4">
              <SelectSearch
                onChangeSelect={handleChangeSelectSearch}
                options={buildUsersOptions(operatorsData?.users) ?? []}
                defaultSelect={{
                  ...operator,
                  name: `${operator?.firstName ?? 'N/A'} ${
                    operator?.lastName ?? ''
                  }`,
                }}
                actionName="operator"
                description="Seleccione un operador"
                label="Operador"
              />
            </div>
            <div className="flex gap-4 md:flex-row flex-col-reverse ">
              <Button
                type="button"
                className=" py-2 px-4  w-fit h-fit font-normal text-sm bg-danger-100  text-white"
                onClick={(e) => {
                  e.preventDefault();
                  setEditOperator(!editOperator);
                }}
              >
                <p className="leading-[14px]">Atrás</p>
              </Button>
              <Button
                type="submit"
                className=" py-2 px-4  w-fit h-fit font-normal text-sm text-white"
                disabled={disabled}
                onClick={(e) => {
                  updateRepairInfo(e);
                  setEditOperator(false);
                }}
              >
                <p className="leading-[14px]">Actualizar operador</p>
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="flex gap-4 items-center w-full">
              <div className="bg-neutral-300 rounded-full p-1 w-fit h-fit">
                <UserIcon className="h-4 w-4 text-neutral-50" />
              </div>
              <div className="flex flex-col gap-1">
                <div className="flex gap-2">
                  <h2 className=" text-neutral-100 text-sm">OPERADOR</h2>
                  <CheckIcon className="h-4 w-4 text-primary-300" />
                </div>
                <div className="flex gap-2">
                  <img
                    className=" w-[20px] h-[20px] rounded-full bg-neutral-50  object-center object-cover"
                    src={operator?.photo?.src ?? '/img/placeholder/user.png'}
                    alt="profile"
                  />
                  <span className="text-text-black text-sm ">
                    {`${operator?.firstName ?? 'Sin asignar'} ${
                      operator?.lastName ?? ''
                    }`}
                  </span>
                  <span className="text-text-black text-sm ml-2">
                    {`${operator?.phone?.code ?? ''} ${
                      operator?.phone?.phone ?? ''
                    }`}
                  </span>
                </div>
              </div>
            </div>
            {operator ? (
              <Button
                type="button"
                className=" py-2 px-4 w-fit h-fit font-normal text-sm text-white"
                onClick={(e) => {
                  e.preventDefault();
                  setEditOperator(!editOperator);
                }}
              >
                <p className="leading-[14px]">Cambiar</p>
              </Button>
            ) : null}
          </>
        )}
      </div>
    </motion.li>
  );
}

export default OperatorCard;
