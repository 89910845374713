import { v4 as uuid } from 'uuid';
import { Option, Location, Variant } from '@spa-cars/models';
import mixValues from './mixValues';

function checkMixedLength<T>(mixed: T[][]): boolean {
  if (mixed.length > 0) {
    return mixed[0].length > 0;
  }
  return false;
}

function buildVariant(values: string[], location: Location): Variant {
  return {
    title: '',
    option: {
      option1: values[0] || '',
      option2: values[1] || '',
      option3: values[2] || '',
    },
    price: 0.0,
    cost: 0.0,
    stock: 0.0,
    minStock: 0.0,
    maxStock: 0.0,
    compareAtPrice: 0.0,
    photo: null,
    sku: '',
    location: location._id,
    _id: uuid(),
    indexa: '',
  };
}

function compareVariants(base: Variant, compareTo: Variant): boolean {
  if ((base.location as string) !== (compareTo.location as string)) {
    return false;
  }
  const {
    option: { option1: baseValue1, option2: baseValue2, option3: baseValue3 },
  } = base;
  const {
    option: {
      option1: compareValue1,
      option2: compareValue2,
      option3: compareValue3,
    },
  } = compareTo;
  return (
    baseValue1 === compareValue1 &&
    baseValue2 === compareValue2 &&
    baseValue3 === compareValue3
  );
}

function generateVariantsHook(
  options: Option[],
  locations: Location[],
  variants: Variant[]
) {
  const mixedValues = mixValues(
    options
      .slice()
      .map(({ values }) => values)
      .filter((x) => !!x)
  );
  if (checkMixedLength(mixedValues)) {
    let generatedVariants = mixedValues
      .map((values) =>
        locations.map((location) => buildVariant(values, location))
      )
      .flat(Infinity) as Variant[];
    generatedVariants = generatedVariants.map((generatedVariant) => {
      const filtered = variants.filter((currentVariant) =>
        compareVariants(generatedVariant, currentVariant)
      );
      if (filtered.length > 0) {
        return filtered[0];
      }
      return generatedVariant;
    });
    return generatedVariants;
  }
  return variants;
}

export default generateVariantsHook;
