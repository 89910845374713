import {
  Address,
  CalculateDiscount,
  Discount,
  Order,
  OrderProduct,
  History,
  PaymentInfo,
  User,
} from '@spa-cars/models';

export const ACTIONS = {
  CLIENT: 'CLIENT',
  ITEMS: 'ITEMS',
  SUBTOTAL: 'SUBTOTAL',
  EXTRA_FEE: 'EXTRA_FEE',
  DISCOUNT_TOTAL: 'DISCOUNT_TOTAL',
  TAX_TOTAL: 'TAX_TOTAL',
  TAX_BASE: 'TAX_BASE',
  GATEWAY_COMMISSION: 'GATEWAY_COMMISSION',
  TOTAL: 'TOTAL',
  PROMO_CODES: 'PROMO_CODES',
  DISCOUNTS: 'DISCOUNTS',
  BILLING_ADDRESS: 'BILLING_ADDRESS',
  HISTORY: 'HISTORY',
  PAYMENT_INFO: 'PAYMENT_INFO',
  NOTE: 'NOTE',
  DEFAULT: 'DEFAULT',
} as const;

export type ACTIONS_TYPE =
  | { type: 'CLIENT'; payload: string | User }
  | { type: 'ITEMS'; payload: (string | Partial<OrderProduct>)[] }
  | { type: 'SUBTOTAL'; payload: number }
  | { type: 'EXTRA_FEE'; payload: number }
  | { type: 'DISCOUNT_TOTAL'; payload: number }
  | { type: 'TAX_TOTAL'; payload: number }
  | { type: 'TAX_BASE'; payload: number }
  | { type: 'GATEWAY_COMMISSION'; payload: number }
  | { type: 'TOTAL'; payload: number }
  | { type: 'PROMO_CODES'; payload: Array<string | Discount> }
  | { type: 'DISCOUNTS'; payload: CalculateDiscount[] }
  | { type: 'BILLING_ADDRESS'; payload: Address }
  | { type: 'HISTORY'; payload: History[] }
  | { type: 'PAYMENT_INFO'; payload: PaymentInfo }
  | { type: 'NOTE'; payload: string }
  | { type: 'DEFAULT'; payload: Partial<Order> };

export function reducer(
  state: Partial<Order>,
  action: ACTIONS_TYPE
): Partial<Order> {
  switch (action.type) {
    case ACTIONS.CLIENT:
      return { ...state, client: action.payload };
    case ACTIONS.ITEMS:
      return { ...state, items: action.payload };
    case ACTIONS.SUBTOTAL:
      return { ...state, subtotal: action.payload };
    case ACTIONS.EXTRA_FEE:
      return { ...state, extraFee: action.payload };
    case ACTIONS.DISCOUNT_TOTAL:
      return { ...state, discountTotal: action.payload };
    case ACTIONS.TAX_TOTAL:
      return { ...state, taxTotal: action.payload };
    case ACTIONS.TAX_BASE:
      return { ...state, taxBase: action.payload };
    case ACTIONS.GATEWAY_COMMISSION:
      return {
        ...state,
        gatewayCommission: action.payload,
      };
    case ACTIONS.TOTAL:
      return {
        ...state,
        total: action.payload,
      };
    case ACTIONS.PROMO_CODES:
      return { ...state, promoCodes: action.payload };

    case ACTIONS.DISCOUNTS:
      return {
        ...state,
        discounts: action.payload,
      };
    case ACTIONS.BILLING_ADDRESS:
      return {
        ...state,
        billingAddress: action.payload,
      };
    case ACTIONS.HISTORY:
      return {
        ...state,
        history: action.payload,
      };
    case ACTIONS.PAYMENT_INFO:
      return {
        ...state,
        paymentInfo: action.payload,
      };
    case ACTIONS.NOTE:
      return {
        ...state,
        note: action.payload,
      };

    case ACTIONS.DEFAULT:
      return { ...state, ...(action?.payload ?? {}) };
    default:
      return { ...state };
  }
}
