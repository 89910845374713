export const discountTypes = [
  {
    name: 'Monto fijo',
    value: 'fixed',
  },
  {
    name: 'Porcentaje',
    value: 'percentage',
  },
  // {
  //   name: 'Una vez',
  //   value: 'one_time',
  // },
  // {
  //   name: 'Gratis',
  //   value: 'free',
  // },
  // {
  //   name: 'Fecha',
  //   value: 'date',
  // },
  // {
  //   name: 'Categoría',
  //   value: 'category',
  // },
];
