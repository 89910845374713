export const productTypes = [
  {
    name: 'Producto',
    value: 'ecommerce',
  },
  {
    name: 'Servicio',
    value: 'service',
  },
  {
    name: 'Producto del servicio',
    value: 'service_product',
  },
];
