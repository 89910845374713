/* eslint-disable no-nested-ternary */
import React from 'react';
import dayjs from 'dayjs';
import { useQuery } from '@apollo/client';
import { validateString } from 'avilatek-utils';
import { DateInput, Select, SpinnerIcon } from '@spa-cars/ui';
import { Appointment, Location, User } from '@spa-cars/models';
import { ACTIONS, reducer } from './reducer';
import { appointmentStatusTypes, shiftTypes } from '../../lib';
import {
  GET_ALL_APPOINTMENTS_BY_LOCATION,
  GET_LOCATIONS,
  GET_USERS,
} from '../../graphql/queries';
import { OperatorList } from './operator';
import { AppointmentList } from './appointment';
import { useUser } from '../../hooks';

const today = dayjs();

function Schedule() {
  const [state, dispatch] = React.useReducer(reducer, {});
  const [user] = useUser();
  const [date, setDate] = React.useState(today.format('DD/MM/YYYY'));
  const [formatedDate, setFormatedDate] = React.useState<string>(null);

  const { data: locationsData } = useQuery<{ locations: Location[] }>(
    GET_LOCATIONS,
    {
      variables: {
        filter: {
          active: true,
          _id:
            user?.userType === 'admin'
              ? (user?.location as Location)?._id
              : undefined,
        },
      },
      fetchPolicy: 'cache-and-network',
    }
  );
  const {
    data: operatorsData,
    loading: operatorsLoading,
    refetch: operatorsRefetch,
  } = useQuery<{ users: User[] }>(GET_USERS, {
    variables: {
      filter: {
        active: true,
        userType: 'mechanic',
        location: state?.location,
      },
    },
    fetchPolicy: 'cache-and-network',
  });
  const {
    data: appointmentsData,
    loading: appointmentsLoading,
    error: appointmentsError,
  } = useQuery<{ getAllAppointmentsByLocations: Appointment[] }>(
    GET_ALL_APPOINTMENTS_BY_LOCATION,
    {
      variables: {
        data: {
          date: formatedDate,
          location: state?.location,
        },
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const appointmentsFilter = (appointments: Appointment[]) => {
    switch (state?.shift) {
      case 'afternoon':
        return appointments?.filter(
          (appointment) =>
            dayjs(appointment?.date)?.hour() > 11 &&
            (state?.status === ('none' as string) || !state?.status
              ? true
              : appointment?.status === state?.status)
        );
      case 'morning':
        return appointments?.filter(
          (appointment) =>
            dayjs(appointment?.date)?.hour() <= 11 &&
            (state?.status === ('none' as string) || !state?.status
              ? true
              : appointment?.status === state?.status)
        );
      default:
        return appointments?.filter((appointment) =>
          state?.status === ('none' as string) || !state?.status
            ? true
            : appointment?.status === state?.status
        );
    }
  };

  const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (value !== 'none') {
      dispatch({ type: name as any, payload: value });
    } else {
      dispatch({ type: name as any, payload: null });
    }
  };

  React.useEffect(() => {
    if (locationsData?.locations?.length > 0) {
      dispatch({
        type: ACTIONS.LOCATION,
        payload: locationsData?.locations[0]?._id,
      });
    }
  }, [locationsData]);
  React.useEffect(() => {
    if (validateString(date)) {
      const dateParts = date?.split('/');
      setFormatedDate(
        new Date(
          +dateParts[2],
          parseInt(dateParts[1]) - 1,
          +dateParts[0]
        ).toISOString()
      );
    }
  }, [date]);

  return (
    <div className="flex flex-col gap-8">
      <div className=" w-full flex justify-end gap-4 flex-col md:flex-row ">
        <Select
          name={ACTIONS.SHIFT}
          value={state?.shift ?? 'none'}
          // label="Turno"
          onChange={handleChangeSelect}
          className="pr-10 focus:shadow-[0_2px_5px_rgba(0,0,0,0.25)] shadow-[0_2px_5px_rgba(0,0,0,0.25)] rounded border-none"
        >
          <option value="none">Turno</option>
          {shiftTypes.map((type) => (
            <option key={type.value} value={type.value}>
              {type.name}
            </option>
          ))}
        </Select>
        <Select
          name={ACTIONS.STATUS}
          value={state?.status ?? 'none'}
          // label="Estatus"
          className="pr-10 focus:shadow-[0_2px_5px_rgba(0,0,0,0.25)] shadow-[0_2px_5px_rgba(0,0,0,0.25)] rounded border-none"
          onChange={handleChangeSelect}
        >
          <option value="none">Estatus</option>
          {appointmentStatusTypes.map((type) => (
            <option key={type.value} value={type.value}>
              {type.name}
            </option>
          ))}
        </Select>
        <Select
          name={ACTIONS.LOCATION}
          value={state?.location ?? 'none'}
          // label="Punto de servicio"
          className="pr-10 focus:shadow-[0_2px_5px_rgba(0,0,0,0.25)] shadow-[0_2px_5px_rgba(0,0,0,0.25)] rounded border-none"
          onChange={handleChangeSelect}
        >
          <option value="none" disabled>
            Punto de servicio
          </option>
          {locationsData?.locations.map((location) => (
            <option key={location._id} value={location._id}>
              {location?.name}
            </option>
          ))}
        </Select>
      </div>
      <div className="flex gap-8 flex-col lg:flex-row items-center lg:items-start">
        <div className="w-full max-w-[500px] ">
          <DateInput
            value={date}
            size="medium"
            setState={(value: string) => {
              setDate(value);
            }}
            open
          />
        </div>
        <div className="w-full">
          {operatorsLoading ? (
            <SpinnerIcon className="w-20 h-20 mx-auto text-gray-200 animate-spin dark:text-text-white fill-primary-300" />
          ) : (
            <OperatorList
              operators={operatorsData?.users ?? []}
              refetch={operatorsRefetch}
              location={locationsData?.locations?.find(
                (ll) => ll?._id === state?.location
              )}
            />
          )}
        </div>
      </div>
      <div>
        {appointmentsLoading ? (
          <SpinnerIcon className="w-20 h-20 mx-auto text-gray-200 animate-spin dark:text-text-white fill-primary-300" />
        ) : (
          <AppointmentList
            appointments={
              appointmentsFilter(
                appointmentsData?.getAllAppointmentsByLocations?.filter(
                  (app) => app?.show && app?.order
                )
              ) ?? []
            }
            date={dayjs(formatedDate)}
          />
        )}
      </div>
    </div>
  );
}

export default Schedule;
