import React from 'react';
import { Line, TrashIcon } from '@spa-cars/ui';
import { OrderProduct } from '@spa-cars/models';
import ConfirmationModal from './ConfirmationModal';

interface ProductItemProps {
  orderProduct: OrderProduct;
  modify: boolean;
  deleteOrderProduct: (orderProduct: Partial<OrderProduct>) => void;
  confirmOrder: boolean;
}

function ProductItem({
  orderProduct,
  modify,
  deleteOrderProduct,
  confirmOrder,
}: ProductItemProps) {
  const [isOpen, setOpen] = React.useState(false);
  return (
    <>
      <ConfirmationModal
        isOpen={isOpen}
        setOpen={setOpen}
        orderProduct={orderProduct}
        deleteOrderProduct={deleteOrderProduct}
      />
      <div className="w-full py-4">
        <div className="w-full flex justify-between">
          <h3 className="text-text-black font-medium text-[18px] leading-[21px] mb-[2px]">
            {orderProduct?.title}
          </h3>
          {modify && !confirmOrder ? (
            <button
              type="button"
              className={`   h-fit w-fit`}
              onClick={(e) => {
                e.preventDefault();
                setOpen(true);
              }}
            >
              <TrashIcon className="w-5 h-5 text-danger-200" />
            </button>
          ) : null}
        </div>
        <p className="text-neutral-200 text-[14px] leading-4 mb-2">
          {`${orderProduct?.variant?.option?.option1 ?? ''} ${
            orderProduct?.variant?.option?.option2 ?? ''
          } ${orderProduct?.variant?.option?.option3 ?? ''}`}
        </p>
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <p className="text-neutral-200 text-[14px] font-medium">
              {/* {modify &&
              orderProduct?.quantity > 0 &&
              orderProduct?.product?.placeToSell === 'ecommerce' ? (
                <button
                  type="button"
                  className="text-[18px]"
                  disabled={orderProduct?.quantity === 0}
                  onClick={(e) => {
                    e.preventDefault();
                    changeAmount(
                      (orderProduct?.quantity ?? 1) - 1,
                      orderProduct
                    );
                  }}
                >
                  -
                </button>
              ) : (
                ''
              )}{' '} */}
              <span className="text-primary-500 font-medium">
                ${orderProduct?.price?.toFixed(2) ?? 0}
              </span>{' '}
              x {orderProduct?.quantity}{' '}
              {/* {modify &&
              orderProduct?.quantity > 0 &&
              orderProduct?.product?.placeToSell === 'ecommerce' ? (
                <button
                  type="button"
                  className=" text-[18px]"
                  onClick={(e) => {
                    e.preventDefault();
                    changeAmount(
                      (orderProduct?.quantity ?? 0) + 1,
                      orderProduct
                    );
                  }}
                >
                  +
                </button>
              ) : (
                ''
              )} */}
            </p>
          </div>
          <span className="text-primary-500 font-medium text-[14px] ">
            $
            {(
              (orderProduct?.price ?? 0) * (orderProduct?.quantity ?? 0)
            ).toFixed(2)}
          </span>
        </div>
      </div>
      <Line />
    </>
  );
}

export default ProductItem;
