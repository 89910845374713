import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronRightIcon } from '@spa-cars/ui';
import { IRoute } from '../../routes';

interface SidebarItemProps {
  route: IRoute;
  setRoutes_: React.Dispatch<React.SetStateAction<IRoute[]>>;
  routes_: IRoute[];
  decreaseMenu: boolean;
  screenSize: number;
  setCollapseShow: React.Dispatch<React.SetStateAction<string>>;
}

export default function SidebarItem({
  route,
  setRoutes_,
  routes_,
  decreaseMenu,
  screenSize,
  setCollapseShow,
}: SidebarItemProps) {
  const router = useRouter();

  if (
    route.children &&
    Array.isArray(route.children) &&
    route.children.length > 0
  ) {
    return (
      <li className="w-full inline-flex flex-col">
        <motion.button
          type="button"
          className={`px-4 py-3 flex items-center justify-between w-full ${
            route?.isOpen ? 'bg-neutral-500 ' : ''
          }`}
          animate={{
            justifyContent: decreaseMenu ? 'start' : 'space-between',
          }}
          transition={{ duration: 0.05 }}
          initial={false}
          onClick={(e) => {
            e.preventDefault();
            const openRoute = routes_?.find((rr) => rr?.isOpen);
            if (openRoute && openRoute !== route) {
              setRoutes_(
                routes_?.map((rr) => {
                  if (rr === openRoute) {
                    return {
                      ...rr,
                      isOpen: false,
                    };
                  }
                  if (rr === route) {
                    return {
                      ...rr,
                      isOpen: !route?.isOpen,
                    };
                  }

                  return rr;
                })
              );
            } else {
              setRoutes_(
                routes_?.map((rr) => {
                  if (rr === route) {
                    return {
                      ...rr,
                      isOpen: !route?.isOpen,
                    };
                  }

                  return rr;
                })
              );
            }
          }}
        >
          <div className="flex items-center py-2">
            <div className=" w-5 h-5 text-neutral-100">{route.icon}</div>
            <motion.div
              className="text-base font-medium text-text-white overflow-hidden text-clip whitespace-nowrap"
              initial={{
                width: 'fit-content',
                marginLeft: '16px',
              }}
              style={{ marginLeft: '16px' }}
              animate={{
                width: decreaseMenu ? '0px' : 'fit-content',
                marginLeft: decreaseMenu ? '0px' : '16px',
              }}
              // layout={screenSize >= 768}
              transition={{ duration: 0.2, origin: 1, bounce: 0 }}
            >
              {route.text}
            </motion.div>
          </div>
          <motion.div
            className=" w-4 h-4 text-neutral-100 "
            animate={{
              rotate: route?.isOpen ? 90 : 0,
              display: decreaseMenu ? 'none' : 'block',
            }}
            transition={{
              duration: 0.1,
              bounce: 0,
            }}
          >
            <ChevronRightIcon />
          </motion.div>
        </motion.button>
        <AnimatePresence initial={false}>
          {route?.isOpen ? (
            <motion.ul
              className={`pl-4 list-none ${
                route?.isOpen ? 'bg-neutral-500' : ''
              }`}
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: 'auto' },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              {route.children.map((_route) => (
                <li className="w-full " key={_route.key}>
                  <button
                    type="button"
                    className="w-full"
                    onClick={(e) => {
                      e.preventDefault();
                      if (screenSize < 768) {
                        setCollapseShow('hidden');
                      }
                    }}
                  >
                    <Link
                      href={`${_route.href}`}
                      className="w-full inline-flex px-4 py-3"
                    >
                      <span
                        className={`block w-4 h-4 ${
                          router.asPath.includes(_route.key)
                            ? ' text-primary-200'
                            : 'text-neutral-100'
                        }`}
                        color={`${
                          router.asPath.includes(_route.key)
                            ? 'text-primary-200'
                            : 'text-neutral-100'
                        }`}
                      >
                        {_route.icon}
                      </span>
                      <motion.span
                        className={`${
                          router.asPath.includes(_route.key)
                            ? ' text-primary-200'
                            : 'text-text-white'
                        } overflow-hidden text-clip whitespace-nowrap`}
                        initial={{
                          width: decreaseMenu ? '0px' : 'fit-content',
                          marginLeft: decreaseMenu ? '0px' : '16px',
                        }}
                        animate={{
                          width: decreaseMenu ? '0px' : 'fit-content',
                          marginLeft: decreaseMenu ? '0px' : '16px',
                        }}
                        // layout={screenSize >= 768}
                        transition={{ duration: 0.2, origin: 1, bounce: 0 }}
                      >
                        {_route.text}
                      </motion.span>
                    </Link>
                  </button>
                </li>
              ))}
            </motion.ul>
          ) : null}
        </AnimatePresence>
      </li>
    );
  }
  return (
    <li
      className={`w-full ${
        router.asPath.includes(route.key)
          ? ' bg-neutral-500 border-l-[3px] border-primary-300'
          : ''
      }`}
    >
      <button
        type="button"
        className="w-full"
        onClick={(e) => {
          e.preventDefault();
          if (screenSize < 768) {
            setCollapseShow('hidden');
          }
        }}
      >
        <Link href={`${route.href}`} className="w-full inline-flex px-4 py-3">
          <span
            className={`block w-5 h-5 ${
              router.asPath.includes(route.key)
                ? ' text-text-white'
                : 'text-neutral-100'
            }`}
          >
            {route.icon}
          </span>
          <motion.span
            className="text-text-white font-medium overflow-hidden text-clip whitespace-nowrap"
            initial={{ width: 'fit-content', marginLeft: '16px' }}
            animate={{
              width: decreaseMenu ? '0px' : 'fit-content',
              marginLeft: decreaseMenu ? '0px' : '16px',
            }}
            // layout={screenSize >= 768}
            transition={{ duration: 0.2, origin: 1, bounce: 0 }}
          >
            {route.text}
          </motion.span>
        </Link>
      </button>
    </li>
  );
}
