import {
  CalendarIcon,
  HomeIcon,
  InventoryIcon,
  LocalOfferIcon,
  SupervisorIcon,
  FullListIcon,
  LocalPlayIcon,
  BuildIcon,
  LocationOnIcon,
  VerifiedUserIcon,
  CategoryIcon,
  WrenchIcon,
  MoneyIcon,
  TaxIcon,
  ShopcartIcon,
  BriefcaseIcon,
  SettingsIcon,
} from '@spa-cars/ui';

export interface IRoute {
  text: string;
  href: string;
  as: string;
  privileges: string[];
  icon: JSX.Element;
  key: string;
  children?: IRoute[];
  isOpen?: boolean;
}

export const superAdminRoutes: IRoute[] = [
  {
    text: 'Walk In',
    href: '/app/walkin',
    as: 'as',
    icon: <HomeIcon />,
    privileges: [],
    key: 'walkin',
  },
  {
    text: 'Historial de servicios',
    href: '/app/orders',
    as: 'as',
    icon: <FullListIcon />,
    privileges: [],
    key: 'orders',
  },
  {
    text: 'Ventas',
    href: '/app/sales',
    as: 'as',
    icon: <ShopcartIcon />,
    privileges: [],
    key: 'sales',
  },
  {
    text: 'Clientes',
    href: '/app/clients',
    as: 'as',
    icon: <SupervisorIcon />,
    privileges: [],
    key: 'clients',
  },
  {
    text: 'Agenda',
    href: '/app/schedule',
    as: 'as',
    icon: <CalendarIcon />,
    privileges: [],
    key: 'schedule',
  },
  {
    text: 'Inventario',
    href: '/app/inventory',
    as: 'as',
    icon: <LocalOfferIcon />,
    privileges: [],
    key: 'inventory',
    children: [
      {
        text: 'Productos',
        href: '/app/products',
        as: 'as',
        icon: <LocalPlayIcon />,
        privileges: [],
        key: 'products',
      },
      {
        text: 'Variantes',
        href: '/app/variants',
        as: 'as',
        icon: <LocalPlayIcon />,
        privileges: [],
        key: 'variants',
      },
    ],
  },

  {
    text: 'Mantenimiento',
    href: '/app/maintenance',
    as: 'as',
    icon: <InventoryIcon />,
    privileges: [],
    key: 'maintenance',
    children: [
      {
        text: 'Promociones',
        href: '/app/discounts',
        as: 'as',
        icon: <LocalPlayIcon />,
        privileges: [],
        key: 'discounts',
      },
      {
        text: 'Operadores',
        href: '/app/operators',
        as: 'as',
        icon: <BuildIcon />,
        privileges: [],
        key: 'operators',
      },
      {
        text: 'Punto de servicio',
        href: '/app/locations',
        as: 'as',
        icon: <LocationOnIcon />,
        privileges: [],
        key: 'locations',
      },
      {
        text: 'Categorías',
        href: '/app/categories',
        as: 'as',
        icon: <CategoryIcon />,
        privileges: [],
        key: 'categories',
      },
    ],
  },

  {
    text: 'Administración',
    href: '/app/admin',
    as: 'as',
    icon: <WrenchIcon />,
    privileges: [],
    key: 'admin',
    children: [
      {
        text: 'Impuestos',
        href: '/app/taxes',
        as: 'as',
        icon: <TaxIcon />,
        privileges: [],
        key: 'taxes',
      },
      {
        text: 'Monedas',
        href: '/app/currencies',
        as: 'as',
        icon: <MoneyIcon />,
        privileges: [],
        key: 'currencies',
      },
    ],
  },

  {
    text: 'Roles y privilegios',
    href: '/app/users',
    as: 'as',
    icon: <VerifiedUserIcon />,
    privileges: [],
    key: 'users',
  },
  {
    text: 'Gestión de Flotas',
    href: '/app/fleet-management',
    as: 'as',
    icon: <SettingsIcon />,
    privileges: [],
    key: 'fleet-management',
  },
];

export const marketingRoutes: IRoute[] = [
  {
    text: 'Promociones',
    href: '/app/discounts',
    as: 'as',
    icon: <LocalPlayIcon />,
    privileges: [],
    key: 'discounts',
  },
];

export const inventoryRoutes: IRoute[] = [
  {
    text: 'Inventario',
    href: '/app/inventory',
    as: 'as',
    icon: <LocalOfferIcon />,
    privileges: [],
    key: 'inventory',
    children: [
      {
        text: 'Productos',
        href: '/app/products',
        as: 'as',
        icon: <LocalPlayIcon />,
        privileges: [],
        key: 'products',
      },
      {
        text: 'Variantes',
        href: '/app/variants',
        as: 'as',
        icon: <LocalPlayIcon />,
        privileges: [],
        key: 'variants',
      },
    ],
  },
];

export const companyRoutes: IRoute[] = [
  {
    text: 'Mi Flota',
    href: '/app/vehicle-fleet',
    as: 'as',
    icon: <BriefcaseIcon />,
    privileges: [],
    key: 'vehicle-fleet',
  },
];

export const routes: IRoute[] = [
  {
    text: 'Walk In',
    href: '/app/walkin',
    as: 'as',
    icon: <HomeIcon />,
    privileges: [],
    key: 'walkin',
  },
  {
    text: 'Historial de servicios',
    href: '/app/orders',
    as: 'as',
    icon: <FullListIcon />,
    privileges: [],
    key: 'orders',
  },
  {
    text: 'Ventas',
    href: '/app/sales',
    as: 'as',
    icon: <ShopcartIcon />,
    privileges: [],
    key: 'sales',
  },
  {
    text: 'Clientes',
    href: '/app/clients',
    as: 'as',
    icon: <SupervisorIcon />,
    privileges: [],
    key: 'clients',
  },
  {
    text: 'Agenda',
    href: '/app/schedule',
    as: 'as',
    icon: <CalendarIcon />,
    privileges: [],
    key: 'schedule',
  },

  {
    text: 'Mantenimiento',
    href: '/app/maintenance',
    as: 'as',
    icon: <InventoryIcon />,
    privileges: [],
    key: 'maintenance',
    children: [
      {
        text: 'Operadores',
        href: '/app/operators',
        as: 'as',
        icon: <BuildIcon />,
        privileges: [],
        key: 'operators',
      },
    ],
  },

  {
    text: 'Administración',
    href: '/app/admin',
    as: 'as',
    icon: <WrenchIcon />,
    privileges: [],
    key: 'admin',
    children: [
      {
        text: 'Impuestos',
        href: '/app/taxes',
        as: 'as',
        icon: <TaxIcon />,
        privileges: [],
        key: 'taxes',
      },
      {
        text: 'Monedas',
        href: '/app/currencies',
        as: 'as',
        icon: <MoneyIcon />,
        privileges: [],
        key: 'currencies',
      },
    ],
  },
];
