import React from 'react';
import {
  AddIcon,
  AfternoonIcon,
  Card,
  SunIcon,
  WrenchIcon,
} from '@spa-cars/ui';
import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import { Location, User } from '@spa-cars/models';
import OperatorCard from './OperatorCard';
import ChangeOperatorCard from './ChangeOperatorCard';
import { useNotify } from '../../../hooks';

interface OperatorListProps {
  operators: Partial<User>[];
  refetch: (variables?: Partial<OperationVariables>) => Promise<
    ApolloQueryResult<{
      users: User[];
    }>
  >;
  location: Location;
}

function OperatorList({ operators, refetch, location }: OperatorListProps) {
  const [addOperatorMorning, setAddOperatorMorning] = React.useState(false);
  const [addOperatorAfternoon, setAddOperatorAfternoon] = React.useState(false);
  const notify = useNotify();
  return (
    <Card className=" p-7 flex flex-col gap-4">
      <div className="flex gap-2 align-middle ">
        <WrenchIcon className="h-4 w-4 text-neutral-200 my-auto" />
        <h2 className="text-neutral-300 flex justify-between"> Operadores </h2>
      </div>

      {!operators?.find((op) => op?.shift === 'morning') ||
      addOperatorMorning ? (
        <ChangeOperatorCard
          shift="morning"
          operators={operators}
          refetch={refetch}
          addOperator={addOperatorMorning}
          setAdd={setAddOperatorMorning}
        />
      ) : (
        <div className="  flex flex-col gap-2">
          <div className="flex justify-between w-full">
            <h3 className="text-neutral-300 flex align-middle text-center font-medium text-lg">
              <SunIcon className=" h-4 w-4 text-secondary-300 mr-[10px] my-auto" />
              Turno de la mañana
            </h3>
            {!addOperatorMorning ? (
              <button
                type="button"
                className=" flex items-center gap-1 text-neutral-100 bg-none p-0 hover:text-primary-300 cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    location?.booths >
                    operators?.filter((op) => op?.shift === 'morning')?.length
                  ) {
                    setAddOperatorMorning(true);
                  } else {
                    notify(
                      `${location?.name} cuenta con ${
                        location?.booths ?? 0
                      } cabinas de servicio, no puede agregar más operadores para este turno`,
                      'warning'
                    );
                  }
                }}
              >
                <AddIcon className=" h-5 w-5 " />
                operador
              </button>
            ) : null}
          </div>
          <div
            className={` grid gap-3 ${
              operators?.filter((op) => op?.shift === 'morning')?.length > 3
                ? 'md:grid-cols-2  '
                : ''
            }`}
          >
            {operators?.map(
              (operator) =>
                operator?.shift === 'morning' && (
                  <OperatorCard
                    operator={operator}
                    refetch={refetch}
                    key={operator?._id}
                    setAdd={setAddOperatorMorning}
                  />
                )
            )}
          </div>
        </div>
      )}
      {!operators?.find((op) => op?.shift === 'afternoon') ||
      addOperatorAfternoon ? (
        <ChangeOperatorCard
          shift="afternoon"
          operators={operators}
          refetch={refetch}
          addOperator={addOperatorAfternoon}
          setAdd={setAddOperatorAfternoon}
        />
      ) : (
        <div className="  flex flex-col gap-2">
          <div className="flex justify-between">
            <h3 className="text-neutral-300 flex align-middle text-center font-medium text-lg">
              <AfternoonIcon className=" h-4 w-4 text-neutral-300 mr-[10px] my-auto" />
              Turno de la tarde
            </h3>
            {!addOperatorAfternoon ? (
              <button
                type="button"
                className=" flex items-center gap-1 text-neutral-100 bg-none p-0 hover:text-primary-300 cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    location?.booths >
                    operators?.filter((op) => op?.shift === 'afternoon')?.length
                  ) {
                    setAddOperatorAfternoon(true);
                  } else {
                    notify(
                      `${location?.name} cuenta con ${
                        location?.booths ?? 0
                      } cabinas de servicio, no puede agregar más operadores para este turno`,
                      'warning'
                    );
                  }
                }}
              >
                <AddIcon className=" h-5 w-5 " />
                operador
              </button>
            ) : null}
          </div>
          <div
            className={` grid  gap-3 ${
              operators?.filter((op) => op?.shift === 'afternoon')?.length > 3
                ? 'md:grid-cols-2  '
                : ''
            }`}
          >
            {operators?.map(
              (operator) =>
                operator?.shift === 'afternoon' && (
                  <OperatorCard
                    operator={operator}
                    refetch={refetch}
                    key={operator?._id}
                    setAdd={setAddOperatorAfternoon}
                  />
                )
            )}
          </div>
        </div>
      )}
    </Card>
  );
}

export default OperatorList;
