import React from 'react';
import { Input, SearchIcon } from '@spa-cars/ui';
import Link from 'next/link';
import { useUser } from '../../hooks';

export default function Navbar() {
  const [user] = useUser();
  return (
    <>
      {/* Navbar */}
      <nav className="h-[72px] md:absolute md:top-0  md:w-full md:z-10 md:bg-transparent md:py-4 md:flex hidden">
        <div className="w-full items-center flex justify-between  md:px-8 ">
          <form className="">
            {/* <Input
            value=""
            placeholder="Buscar"
            className="rounded-full bg-[#EBEBEB] border-none w-[450px]"
          >
            <div className=" w-5 h-5 text-neutral-100">
              <SearchIcon />
            </div>
          </Input> */}
          </form>
          {/* User */}
          <Link
            href={
              user?.userType === 'admin' || user?.userType === 'superadmin'
                ? `/app/users/${user?._id}`
                : '/'
            }
          >
            <img
              className="w-8 h-8 rounded-full bg-neutral-50  object-center object-cover"
              src={user?.photo?.src ?? '/img/placeholder/user.png'}
              alt="profile"
            />
          </Link>
        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
}
