import React from 'react';
import CurrencyForm from './CurrencyForm';
import { Layout } from '../layout';

export default function CreateCurrencyForm() {
  return (
    <Layout
      title="Nueva moneda"
      routesHistory={[
        {
          text: 'Perfil de la moneda',
          href: '/app/currencies/create',
          key: 'create',
        },
      ]}
    >
      <CurrencyForm />
    </Layout>
  );
}
