import React from 'react';
import { useQuery } from '@apollo/client';
import { Category, Location, Tax } from '@spa-cars/models';
import { GET_CATEGORIES, GET_LOCATIONS, GET_TAXES } from '../graphql/queries';

const variables = {
  filter: {
    active: true,
  },
};

export function useProductFormData() {
  // React hooks
  const [categories, setCategories] = React.useState<Category[]>([]);
  const [locations, setLocations] = React.useState<Location[]>([]);
  const [taxes, setTaxes] = React.useState<Tax[]>([]);

  // Apollo queries
  const { data: categoriesData, loading: categoriesLoading } = useQuery<{
    categories: Category[];
  }>(GET_CATEGORIES, {
    variables,
    fetchPolicy: 'cache-and-network',
  });
  const { data: locationsData, loading: locationsLoading } = useQuery<{
    locations: Location[];
  }>(GET_LOCATIONS, {
    fetchPolicy: 'cache-and-network',
  });
  const { data: taxesData, loading: taxesLoading } = useQuery<{
    taxes: Tax[];
  }>(GET_TAXES, {
    variables,
    fetchPolicy: 'cache-and-network',
  });

  // React effects
  React.useEffect(
    function syncCategories() {
      if (!categoriesLoading && categoriesData) {
        setCategories(categoriesData.categories);
      }
    },
    [categoriesData, categoriesLoading]
  );
  React.useEffect(
    function syncLocations() {
      if (!locationsLoading && locationsData) {
        setLocations(locationsData.locations);
      }
    },
    [locationsData, locationsLoading]
  );
  React.useEffect(
    function syncTaxes() {
      if (!taxesLoading && taxesData) {
        setTaxes(taxesData.taxes);
      }
    },
    [taxesData, taxesLoading]
  );

  return [categories, locations, taxes] as const;
}
