import React from 'react';
import dayjs from 'dayjs';
import { AnimatePresence, motion } from 'framer-motion';
import { validateString } from 'avilatek-utils';
import { Line, PointIcon } from '@spa-cars/ui';
import { RepairStep } from '@spa-cars/models';

interface RepairStepCardProps {
  repairStep: RepairStep;
  mileage: string;
  inProcess: boolean;
}

const getIssueStatus = (repairStep: RepairStep) => {
  let status = 'none';
  const resultType = repairStep?.resultOptions?.indexOf(repairStep?.result);
  if (resultType > 0) {
    if (resultType === 1) status = 'warning';
    if (resultType === 2) status = 'danger';
  }
  if (
    status === 'none' &&
    repairStep?.issueOptions?.indexOf(repairStep?.issue) >= 0
  ) {
    status = 'warning';
  }
  switch (status) {
    case 'none':
      return 'text-primary-300';
    case 'warning':
      return 'text-warning-300';
    case 'danger':
      return 'text-danger-300';

    default:
      return 'text-warning-300';
  }
};

export default function RepairStepCard({
  repairStep,
  mileage,
  inProcess,
}: RepairStepCardProps) {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);
  return (
    <motion.div
      layout
      className="relative flex flex-col min-w-0 bg-white bg-clip-border shadow-[0_4px_10px_rgba(0,0,0,0.25)] rounded-lg "
    >
      {/* <div className="w-full flex gap-[14px] items-center"> */}
      <motion.li
        onClick={toggleOpen}
        layout
        className="w-full p-4 flex gap-[14px] items-center cursor-pointer"
      >
        <motion.div layout>
          <PointIcon
            className={`w-[20px] h-[20px] ${
              inProcess ? 'text-text-black' : getIssueStatus(repairStep)
            }  `}
          />
        </motion.div>
        <motion.div layout className="w-full">
          <motion.div layout className="w-full flex justify-between ">
            <motion.div
              layout
              className={` ${isOpen ? 'text-primary-300' : ''}`}
            >
              Punto #{repairStep?.step} - {repairStep?.title}{' '}
              {repairStep?.step === '0' && mileage
                ? `- Kilometraje: ${mileage}`
                : ''}
            </motion.div>
            <motion.div
              layout
              className={` ${
                isOpen ? 'text-primary-300' : 'text-text-black'
              } ml-1`}
            >
              {`${
                repairStep?.date && !inProcess
                  ? dayjs(repairStep?.date).format('hh:mm A')
                  : 'En proceso...'
              }`}
            </motion.div>
          </motion.div>
          <AnimatePresence>
            {isOpen && (
              <motion.div
                layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="flex flex-col  gap-2 max-h-[300px] overflow-y-auto w-full overflow-x-none mt-4"
              >
                <Line />
                {validateString(repairStep?.result) &&
                repairStep?.resultOptions?.length > 1 ? (
                  <div className="flex flex-col gap-2">
                    <p className="text-text-black  text-[15px] font-semibold">
                      Resultado:
                    </p>

                    <p className="text-neutral-200  text-[15px]">
                      {repairStep?.result}
                    </p>

                    <Line />
                  </div>
                ) : null}
                {validateString(repairStep?.issue) ? (
                  <div className="flex flex-col gap-2">
                    <p className="text-text-black  text-[15px] font-semibold">
                      Problema presentado:
                    </p>

                    <p className="text-neutral-200  text-[15px]">
                      {repairStep?.issue}
                    </p>

                    <Line />
                  </div>
                ) : null}
                <p className="text-text-black mx-auto text-[15px] font-semibold">
                  Procedimiento
                </p>
                <Line />
                {repairStep?.stepInstruction?.map((stepIn) => (
                  <div className="flex flex-col gap-2" key={stepIn?._id}>
                    <p className="text-text-black  text-[15px] font-medium">
                      Instrucción: {stepIn?.instruction}
                    </p>
                    <p className="text-neutral-100  text-sm">
                      {stepIn?.description}
                    </p>
                    {validateString(stepIn?.photo?.src) ? (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="w-fit"
                        href={stepIn?.photo?.src ?? ''}
                        onClick={(e) => {
                          if (e.stopPropagation) e.stopPropagation();
                        }}
                      >
                        <motion.img
                          src={stepIn?.photo?.src ?? ''}
                          alt="img"
                          className="h-[48px] w-[48px] object-cover object-center rounded-lg z-50"
                        />
                      </a>
                    ) : null}
                    <Line />
                  </div>
                ))}
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </motion.li>
    </motion.div>
  );
}
