import React from 'react';
import { useRouter } from 'next/router';
import BreadcrumbNavigation from '../BreadcrumbNavigation';
import { IRoute, companyRoutes, superAdminRoutes } from '../../routes';
import Navbar from './Navbar';

interface LayoutProps {
  children: React.ReactNode;
  title?: string;
  titlePhoto?: string;
  routesHistory: Partial<IRoute>[];
}

export default function Layout({
  children,
  title = '',
  titlePhoto = null,
  routesHistory,
}: LayoutProps) {
  const router = useRouter();
  // const [decreaseMenu, setDecreaseMenu] = React.useState(false);
  // const [screenSize_, setScreenSize_] = React.useState(null);
  // const { screenSize } = useScreenSize();

  let firstRoute = [...superAdminRoutes, ...companyRoutes]?.find(
    (r) => router?.asPath?.includes(r.href)
  );
  if (firstRoute === undefined) {
    [...companyRoutes, ...superAdminRoutes]?.forEach((route) => {
      if (
        route?.children?.length > 0 &&
        route?.children?.find((rc) => router?.asPath?.includes(rc.href))
      ) {
        firstRoute = route?.children?.find(
          (rc) => router?.asPath?.includes(rc.href)
        );
      }
    });
  }

  // React.useEffect(() => {
  //   if (screenSize > 0) {
  //     setScreenSize_(screenSize);
  //     if (screenSize < 768) {
  //       setDecreaseMenu(false);
  //     }
  //   }
  // }, [screenSize]);

  return (
    <>
      <Navbar />

      <div className="relative md:pt-[72px] z-0 pt-[93px]">
        <div className=" md:px-8 mx-auto w-full px-4 flex md:gap-8 gap-4 items-center">
          {titlePhoto ? (
            <img
              className=" w-16 h-16 rounded-full bg-neutral-50  object-center object-cover"
              src={titlePhoto}
              alt="profile"
            />
          ) : null}
          <div className="flex flex-col gap-2">
            <h2 className="text-[32px] leading-[38px] text-neutral-500 font-medium">
              {title}
            </h2>
            <BreadcrumbNavigation
              routesHistory={[
                { ...firstRoute, key: 'icon' },
                firstRoute,
                ...(routesHistory ?? []),
              ]}
            />
          </div>
        </div>
      </div>
      <div className="relative md:pt-8 pt-4 px-4 md:px-8 md:pb-8 pb-4 mx-auto w-full z-10 min-h-full ">
        {children}
      </div>

      <img
        className="w-fit h-fit opacity-30 fixed bottom-0 right-0 z-0 md:block hidden"
        alt="logo"
        src="/img/leaf-app.png"
      />
    </>
  );
}
