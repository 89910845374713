import React from 'react';
import { AfternoonIcon, Card, SunIcon } from '@spa-cars/ui';
import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import { User } from '@spa-cars/models';
import OperatorCard from './OperatorCard';

interface ChangeOperatorCardProps {
  shift: 'morning' | 'afternoon';
  operators: Partial<User>[];
  refetch: (variables?: Partial<OperationVariables>) => Promise<
    ApolloQueryResult<{
      users: User[];
    }>
  >;
  setAdd: React.Dispatch<React.SetStateAction<boolean>>;
  addOperator: boolean;
}

function ChangeOperatorCard({
  shift,
  operators,
  refetch,
  setAdd,
  addOperator,
}: ChangeOperatorCardProps) {
  return (
    <Card className=" shadow-none border-dashed border rounded-lg border-neutral-200 bg-[#EEEEEE] p-4 flex-col gap-[10px] flex">
      <div className="flex justify-between w-full">
        <h3 className="text-neutral-300 flex align-middle text-center font-medium text-lg">
          {shift === 'morning' ? (
            <>
              <SunIcon className=" h-4 w-4 text-secondary-300 mr-[10px] my-auto" />
              Turno de la mañana
            </>
          ) : (
            <>
              <AfternoonIcon className=" h-4 w-4 text-neutral-300 mr-[10px] my-auto" />
              Turno de la tarde
            </>
          )}
        </h3>
        {addOperator ? (
          <button
            type="button"
            className=" text-neutral-200 bg-none p-0 hover:text-danger-300 cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              setAdd(false);
            }}
          >
            Cancelar
          </button>
        ) : null}
      </div>
      <p className=" text-sm font-medium">Seleccione un operador</p>
      <div className="flex flex-wrap gap-[10px]">
        {operators?.length === 0 ? (
          <p className=" text-sm ">
            No hay operadores asociados a la ubicación seleccionada
          </p>
        ) : (
          operators?.map((operator) => (
            <OperatorCard
              operator={operator}
              changeShiftTo={shift}
              refetch={refetch}
              setAdd={setAdd}
            />
          ))
        )}
      </div>
    </Card>
  );
}

export default ChangeOperatorCard;
