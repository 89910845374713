export const inOut = [
  {
    name: 'Entrada',
    value: 'in',
  },
  {
    name: 'Salida',
    value: 'out',
  },
];
