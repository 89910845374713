import React from 'react';
import NProgress from 'nprogress';
import Link from 'next/link';
import {
  ArrowBackIcon,
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  Line,
} from '@spa-cars/ui';
import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import { Tax, DocumentModel } from '@spa-cars/models';
import { useNotify } from '../../hooks';
import { ACTIONS, reducer } from './reducer';
import { CREATE_TAX, UPDATE_TAX } from '../../graphql/mutations';

interface TaxFormProps {
  tax?: Partial<Tax>;
  setTax?: React.Dispatch<React.SetStateAction<Partial<Tax>>>;
}

function TaxForm({ tax = null, setTax = null }: TaxFormProps) {
  const [state, dispatch] = React.useReducer(reducer, tax || {});
  const [updateTax] = useMutation<{
    updateTax: {
      recordId: string;
    };
  }>(UPDATE_TAX);
  const [createTax] = useMutation<{
    createTax: {
      recordId: string;
    };
  }>(CREATE_TAX);
  const [documents, setDocuments] = React.useState<
    Array<Partial<DocumentModel>>
  >([]);
  const notify = useNotify();
  const router = useRouter();
  const [disabled, setDisabled] = React.useState(false);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      NProgress.start();
      setDisabled(true);
      delete state?._id;

      const taxData = {
        ...state,
        value: parseFloat(String(state?.value)),
      };
      if (tax) {
        const { data } = await updateTax({
          variables: {
            filter: {
              _id: tax._id,
            },
            record: {
              ...taxData,
              __typename: undefined,
            },
          },
        });
        if (data?.updateTax?.recordId) {
          notify('Impuesto actualizado con éxito', 'success');
          router.back();
        } else {
          notify('Ha ocurrido un error al actualizar el impuesto', 'error');
        }
      } else {
        const { data } = await createTax({
          variables: {
            record: {
              ...taxData,
              __typename: undefined,
            },
          },
        });
        if (data?.createTax?.recordId) {
          notify('Impuesto creado con éxito', 'success');
          router.push(`/app/taxes/${data?.createTax?.recordId}`);
        } else {
          notify('Ha ocurrido un error al crear el impuesto', 'error');
        }
      }
      setDisabled(false);
    } catch (err) {
      notify(err.message, 'error');
      setDisabled(false);
    } finally {
      NProgress.done();
    }
  };

  const updateActiveTax = async (active: boolean) => {
    try {
      NProgress.start();
      setDisabled(true);
      if (tax) {
        const { data } = await updateTax({
          variables: {
            filter: {
              _id: tax._id,
            },
            record: {
              active,
            },
          },
        });
        if (data?.updateTax?.recordId) {
          notify('Impuesto actualizado con éxito', 'success');
          router.back();
        } else {
          notify('Ha ocurrido un error al inhabilitar el impuesto', 'error');
        }
      }
      setDisabled(false);
    } catch (err) {
      notify(err.message, 'error');
      setDisabled(false);
    } finally {
      NProgress.done();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({ type: name as any, payload: value });
  };

  React.useEffect(() => {
    if (tax) {
      dispatch({ type: ACTIONS.DEFAULT, payload: tax });
    }
  }, [tax]);

  return (
    <form
      method="POST"
      onSubmit={onSubmit}
      className="flex flex-col w-full gap-8"
    >
      <Card>
        <CardHeader>
          {tax ? 'Actualizar' : 'Crear'} impuesto
          <Link
            href="/app/taxes"
            className="flex gap-1 text-success-200 font-medium items-center"
          >
            <ArrowBackIcon className="w-4 h-4" />
            IMPUESTOS
          </Link>
        </CardHeader>

        <Line />
        <CardBody>
          <div className="w-full flex md:flex-row flex-col gap-4">
            <div className="w-full">
              <Input
                name={ACTIONS.NAME}
                value={state?.name ?? ''}
                label="Nombre"
                type="text"
                placeholder="Ingrese un nombre"
                onChange={handleChange}
                required
              />
            </div>
            <div className="w-full">
              <Input
                name={ACTIONS.VALUE}
                value={state?.value ?? ''}
                label="Valor porcentual"
                type="number"
                min={0}
                max={100}
                step={1}
                placeholder="Ingrese un valor porcentual"
                onChange={handleChange}
                required
              />
            </div>
          </div>
        </CardBody>
      </Card>
      <div className="flex self-end gap-[30px]">
        {tax?.active ? (
          <Button
            type="button"
            className=" w-fit py-3 px-[25px] bg-danger-100"
            disabled={disabled}
            onClick={(e) => {
              e.preventDefault();
              updateActiveTax(false);
            }}
          >
            Inhabilitar
          </Button>
        ) : (
          tax && (
            <Button
              type="button"
              className=" w-fit py-3 px-[25px] bg-white text-primary-400"
              disabled={disabled}
              onClick={(e) => {
                e.preventDefault();
                updateActiveTax(true);
              }}
            >
              Habilitar
            </Button>
          )
        )}
        <Button
          type="submit"
          className=" w-fit py-3 px-[25px]"
          disabled={disabled}
        >
          {tax ? 'Actualizar' : 'Crear'}
        </Button>
      </div>
    </form>
  );
}

export default TaxForm;
