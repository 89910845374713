import React from 'react';
import NProgress from 'nprogress';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { useMutation } from '@apollo/client';
import { Button, Input } from '@spa-cars/ui';
import { RESET_PASSWORD } from '../../graphql/mutations';
import { useNotify } from '../../hooks';

function ResetPasswordForm() {
  const notify = useNotify();
  const emailRef = React.useRef<HTMLInputElement>();
  // const captchaRef = React.useRef(null);
  const [captchaToken, setCaptchaToken] = React.useState(null);

  const [disabled, setDisabled] = React.useState(false);
  const [resetPassword] = useMutation<{
    resetPassword: {
      success: boolean;
      err: string;
    };
  }>(RESET_PASSWORD);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      const res = await axios.post(`/api/captcha`, { token: captchaToken });
      const email = emailRef.current.value;
      NProgress.start();
      setDisabled(true);
      if (res?.data?.success) {
        const { data } = await resetPassword({
          variables: {
            data: {
              email,
            },
          },
        });
        if (data?.resetPassword?.success) {
          notify('Se ha enviado el correo de cambio de contraseña', 'success');
        } else {
          notify(`${data?.resetPassword?.err}`, 'error');
        }
      } else {
        notify('Debe completar el Captcha', 'warning');
      }
    } catch (err) {
      notify(err.message, 'error');
    } finally {
      NProgress.done();
      setDisabled(false);
    }
  };

  const onChange = (value) => {
    setCaptchaToken(value);
  };

  return (
    <section className="w-full h-full min-h-screen flex flex-row flex-wrap bg-neutral-50">
      <article className="w-full md:w-1/2 min-h-screen h-full flex items-center justify-center  md:px-16 px-4 py-16  ">
        <div className=" flex flex-col gap-10">
          <div className="flex flex-col">
            <h2 className=" text-[40px] font-medium text-text-black ">
              ¿Problemas para iniciar sesión?
            </h2>
            <p>Ingrese su correo electrónico para restablecer su contraseña</p>
          </div>
          <form
            method="POST"
            className="flex flex-col items-center gap-4"
            onSubmit={onSubmit}
          >
            <div className="w-full">
              <Input
                label="Correo Electrónico"
                name="email"
                type="email"
                placeholder="Ingrese un correo electrónico"
                ref={emailRef}
                required
              />
            </div>
            <ReCAPTCHA
              sitekey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY}
              onChange={onChange}
              // ref={captchaRef}
            />
            <Button type="submit" className=" mt-6" disabled={disabled}>
              Enviar correo
            </Button>
          </form>
        </div>
      </article>
      <article className="w-0 h-0 md:w-1/2 md:h-screen  md:bg-primary-500  overflow-hidden md:flex md:flex-col md:justify-between ">
        <div className="  w-fit h-1/3 opacity-30 ">
          <img
            className="w-full h-full  "
            alt="logo"
            src="/img/leaf-sign-in-2.png"
          />
        </div>
        <div className=" w-full flex flex-col gap-6 text-center text-text-white ">
          <h1 className=" text-[64px]  font-medium leading-[60px]">
            Dashboard Administrativo
          </h1>
          <h2 className=" text-[26px]">Spacars</h2>
        </div>

        <div className=" ml-auto w-fit h-1/3 opacity-30 ">
          <img
            className="w-full h-full  "
            alt="logo"
            src="/img/leaf-sign-in-1.png"
          />
        </div>
      </article>
    </section>
  );
}

export default ResetPasswordForm;
