import { CheckIcon } from '@spa-cars/ui';
import React from 'react';

function OwnProductCheck({ label }: { label: string }) {
  return (
    <div className="flex gap-2 items-center">
      <h2 className=" text-neutral-500 ">{label}</h2>
      <CheckIcon className="h-5 w-5 text-primary-300" />
    </div>
  );
}

export default OwnProductCheck;
