import React from 'react';
import ClientForm from './ClientForm';
import { Layout } from '../layout';

export default function CreateClientForm() {
  return (
    <Layout
      title="Nuevo cliente"
      routesHistory={[
        {
          text: 'Perfil de cliente',
          href: '/app/clients/create',
          key: 'create',
        },
      ]}
    >
      <ClientForm />
    </Layout>
  );
}
