import React from 'react';
import Link from 'next/link';
import { Hit, SearchResponse } from '@algolia/client-search';
import { DotIcon, Table } from '@spa-cars/ui';
import { validateString } from 'avilatek-utils';
import { useLazyQuery } from '@apollo/client';
import { Currency, PaginationInfo } from '@spa-cars/models';
import { useAlgolia } from '../../hooks';
import { GET_CURRENCIES_LIST } from '../../graphql/queries';

const buildTableData = (currencies: Array<Currency | Hit<Currency>> = []) =>
  currencies.map((currency) => ({
    _id: currency._id ?? (currency as any).id,
    name: currency?.name ?? 'N/A',
    symbol: currency?.symbol ?? 'N/A',
    rate: currency?.rate ?? 'N/A',
    status: (
      <p className="flex gap-[10px] items-center">
        <DotIcon
          className={` ${
            currency?.active ? 'text-primary-300' : ' text-danger-200'
          } w-[15px] h-[15px]`}
        />
        {currency?.active ? 'Activo' : 'Inactivo'}
      </p>
    ),
  }));

interface PaginationDataProps {
  items: Array<Currency>;
  count: number;
  pageInfo: PaginationInfo;
}

export default function CurrencyTable() {
  const searchCurrency = useAlgolia();
  const searchIndex = searchCurrency.initIndex('currencies');
  const [searchResult, setSearchResult] = React.useState<Hit<Currency>[]>(null);
  const [paginationData, setPaginationData] = React.useState<
    PaginationDataProps | SearchResponse<Currency>
  >(null);
  const [perPage, setPerPage] = React.useState(10);
  const [searchInput, setSearchInput] = React.useState(''); // guarda lo que el usuario ingresa en el input de busqueda
  const [loadingSearch, setLoadingSearch] = React.useState(false);
  const [loadCurrencies, { data, loading, error, fetchMore }] = useLazyQuery<{
    currencyPagination: {
      items: Array<Currency>;
      count: number;
      pageInfo: PaginationInfo;
    };
  }>(GET_CURRENCIES_LIST);

  const queryVariables = {
    perPage: perPage ?? 10,
    sort: 'CREATEDAT_DESC',
  };

  const handleGoToPage = (value) => {
    if (validateString(searchInput)) {
      searchCurrencies(searchInput, value - 1); // ir a la siguiente pagina de la busqueda de algolia
    } else {
      loadCurrencies({
        variables: {
          page: value ?? 1,
          ...queryVariables,
        },
        fetchPolicy: 'cache-and-network',
      }); // ir a la siguiente pagina del query
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <p className="flex justify-between items-center">
            NOMBRE
            {/* <MenuIcon className="w-5 h-5 text-neutral-100" /> */}
          </p>
        ),

        accessor: 'name',
      },
      {
        Header: (
          <p className="flex justify-between items-center">
            SÍMBOLO
            {/* <MenuIcon className="w-5 h-5 text-neutral-100" /> */}
          </p>
        ),

        accessor: 'symbol',
      },
      {
        Header: (
          <p className="flex justify-between items-center">
            TASA
            {/* <MenuIcon className="w-5 h-5 text-neutral-100" /> */}
          </p>
        ),
        accessor: 'rate',
      },
      {
        Header: (
          <p className="flex justify-between items-center">
            ESTATUS
            {/* <MenuIcon className="w-5 h-5 text-neutral-100" /> */}
          </p>
        ),
        accessor: 'status',
      },
    ],
    []
  );

  const filterResults = React.useCallback(
    (results: SearchResponse<Currency>) => results?.hits ?? [],
    []
  );

  const searchCurrencies = async (value: string, page_: number) => {
    try {
      if (!validateString(value) || value === '') {
        loadCurrencies({
          variables: {
            page: 1,
            ...queryVariables,
          },
          fetchPolicy: 'cache-and-network',
        });
        setPaginationData(data?.currencyPagination);
        setSearchResult([]);
        return;
      }
      setLoadingSearch(true);
      const result = await searchIndex.search<Currency>(value, {
        page: page_,
        hitsPerPage: perPage ?? 10,
      });
      setLoadingSearch(false);
      setPaginationData(result);
      const resultsFiltered = filterResults(result);
      setSearchResult(resultsFiltered);
    } catch (err) {
      console.log(err);
    }
  };

  const onChangeSearch = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      setSearchInput(e.target.value);
    },
    []
  );

  const dataMemo = React.useMemo(
    () =>
      searchResult?.length > 0
        ? buildTableData(searchResult)
        : buildTableData((paginationData as PaginationDataProps)?.items) ?? [],
    [paginationData, searchResult]
  );

  React.useEffect(() => {
    if (data) {
      setPaginationData(data?.currencyPagination);
    }
  }, [data]);

  React.useEffect(() => {
    searchCurrencies(searchInput, 0); // para algolia, la paginación empieza en 0
  }, [searchInput, perPage]);

  return (
    <Table
      perPage={perPage}
      setPerPage={setPerPage}
      columns={columns}
      data={dataMemo}
      error={null}
      loading={loading || loadingSearch}
      pageCount={0}
      header="Monedas"
      pageNumber={
        ((paginationData as SearchResponse<Currency>)?.page ??
          ((paginationData as PaginationDataProps)?.pageInfo?.currentPage ??
            1) - 1) + 1
      }
      addButtonText="Moneda"
      handleGoToPage={handleGoToPage}
      results={
        (paginationData as SearchResponse<Currency>)?.nbHits ??
        (paginationData as PaginationDataProps)?.count
      }
      path="app/currencies"
      link={Link}
      onChangeSearch={onChangeSearch}
    />
  );
}
