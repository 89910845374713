export const dniTypes = [
  {
    name: 'V',
    value: 'V',
  },
  {
    name: 'E',
    value: 'E',
  },
  {
    name: 'J',
    value: 'J',
  },
  {
    name: 'G',
    value: 'G',
  },
  {
    name: 'P',
    value: 'P',
  },
];
