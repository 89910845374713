import React from 'react';
import NProgress from 'nprogress';
import { AddIcon, Button, Card, Input, Line, SelectSearch } from '@spa-cars/ui';
import EditIcon from '@spa-cars/ui/src/icons/EditIcon';
import { useMutation, useQuery } from '@apollo/client';
import { validateNumber } from 'avilatek-utils';
import {
  Order,
  OrderProduct,
  Product,
  Variant,
  Location,
} from '@spa-cars/models';
import ProductItem from './ProductItem';
import { GET_ALL_PRODUCTS } from '../../../graphql/queries';
import { ACTIONS, reducer } from './reducer';
import { useNotify, useUser } from '../../../hooks';
import {
  ADD_ITEMS_ORDER,
  DECREASE_ITEMS_ORDER,
  REMOVE_ITEMS_ORDER,
} from '../../../graphql/mutations';

interface InvoiceProps {
  order: Order;
  location: Location;
  services?: boolean;
  serviceOrderIds?: string[];
  subOrders?: (string | Order)[];
}

const buildProductsOptions = (products: Product[]) =>
  products?.map((product) => ({
    ...product,
    name: product?.title,
  }));

const buildVariantsOptions = (variants: Variant[]) =>
  variants?.map((variant) => ({
    ...variant,
    name: [
      variant?.option?.option1,
      variant?.option?.option2,
      variant?.option?.option3,
    ]
      .filter((x) => !!x)
      .join('/'),
  }));

function Invoice({
  order,
  location,
  services = false,
  subOrders = [],
  serviceOrderIds = [],
}: InvoiceProps) {
  const [state, dispatch] = React.useReducer(reducer, {});
  const [modify, setModify] = React.useState(false);
  const [add, setAdd] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [resetSelection, setResetSelection] = React.useState(false);
  const notify = useNotify();
  const [productSelected, setProductSelected] = React.useState<
    Partial<Product>
  >({});
  const [variantSelected, setVariantSelected] = React.useState<
    Partial<Variant>
  >({});
  const [quantitySelected, setQuantitySelected] = React.useState<number>(1);
  const {
    data: productsData,
    loading: productsLoading,
    error: productsError,
  } = useQuery<{ products: Product[] }>(GET_ALL_PRODUCTS, {
    variables: {
      filter: {
        active: true,
        placeToSell: 'ecommerce',
      },
    },
    fetchPolicy: 'cache-and-network',
  });
  const [user] = useUser();
  const [addItemsOrder] = useMutation<{
    addItemsOrder: Order;
  }>(ADD_ITEMS_ORDER);
  const [decreaseItemsOrder] = useMutation<{
    decreaseItemsOrder: Order;
  }>(DECREASE_ITEMS_ORDER);
  const [removeItemsOrder] = useMutation<{
    removeItemsOrder: Order;
  }>(REMOVE_ITEMS_ORDER);

  const onUpdateOrderProducts = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      e.preventDefault();
      NProgress.start();
      setDisabled(true);
      let successAdd = true;
      let successRemove = true;
      let successDecrease = true;
      if (state && order && user) {
        const itemsToRemove = state?.items?.filter(
          (item: OrderProduct) => item?.quantity === 0
        );

        if (itemsToRemove?.length > 0) {
          const { data } = await removeItemsOrder({
            variables: {
              data: {
                order: order?._id,
                admin: user?._id,
                items: itemsToRemove?.map((item: OrderProduct) => ({
                  orderProductId: item?._id,
                  quantity:
                    ((order?.items as OrderProduct[])?.find(
                      (item_: OrderProduct) => item_?._id === item?._id
                    )?.quantity ?? 0) - (item?.quantity ?? 0),
                })),
              },
            },
          });
          if (!data?.removeItemsOrder?._id) {
            successRemove = false;
          }
        }
        const itemsToDecrease = state?.items?.filter(
          (item: OrderProduct) =>
            item?.quantity <
            (order?.items as OrderProduct[])?.find(
              (itt) => itt?._id === item?._id
            )?.quantity
        );
        if (itemsToDecrease?.length > 0) {
          const { data } = await decreaseItemsOrder({
            variables: {
              data: {
                orderId: order?._id,
                admin: user?._id,
                items: itemsToDecrease?.map((item: OrderProduct) => ({
                  productId: item?.product?._id,
                  variantId: item?.variant?._id,
                  quantity:
                    ((order?.items as OrderProduct[])?.find(
                      (itt) => itt?._id === item?._id
                    )?.quantity ?? 0) - (item?.quantity ?? 0),
                })),
              },
            },
          });
          if (!data?.decreaseItemsOrder?._id) {
            successDecrease = false;
          }
        }
        const itemsToAdd = state?.items?.filter(
          (item: OrderProduct) =>
            item?.quantity >
            (order?.items as OrderProduct[])?.find(
              (itt) => itt?._id === item?._id
            )?.quantity
        );
        if (itemsToAdd?.length > 0) {
          const { data } = await addItemsOrder({
            variables: {
              data: {
                orderId: order?._id,
                admin: user?._id,
                items: itemsToAdd?.map((item: OrderProduct) => ({
                  productId: item?.product?._id,
                  variantId: item?.variant?._id,
                  quantity:
                    (item?.quantity ?? 0) -
                    ((order?.items as OrderProduct[])?.find(
                      (itt) => itt?._id === item?._id
                    )?.quantity ?? 0),
                })),
              },
            },
          });
          if (!data?.addItemsOrder?._id) {
            successAdd = false;
          }
        }
        if (successAdd && successRemove && successDecrease) {
          notify('Orden actualizada con éxito', 'success');
        } else {
          notify('Ha ocurrido un error al actualizar la orden', 'error');
        }
      }
      setModify(false);
      setDisabled(false);
    } catch (err) {
      notify(err.message, 'error');
      setDisabled(false);
    } finally {
      NProgress.done();
    }
  };

  const onAddProduct = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      e.preventDefault();
      NProgress.start();
      setDisabled(true);
      if (state && order?._id && user?._id && productSelected?._id) {
        const { data: dataAddItems } = await addItemsOrder({
          variables: {
            data: {
              orderId: order?._id,
              admin: user?._id,
              items: [
                {
                  productId: productSelected?._id,
                  variantId: variantSelected?._id,
                  quantity: quantitySelected,
                },
              ],
            },
          },
        });
        if (dataAddItems?.addItemsOrder?._id) {
          notify('Producto agregado con éxito', 'success');
        } else {
          notify('Ha ocurrido un error al agregar el producto', 'error');
        }
      }
      setDisabled(false);
    } catch (err) {
      notify(err.message, 'error');
      setDisabled(false);
    } finally {
      NProgress.done();
      setResetSelection(true);
      setAdd(false);
      setProductSelected({});
      setVariantSelected({});
      setQuantitySelected(1);
    }
  };

  const handleSelectProduct = (value: Product, name: string) => {
    setProductSelected(value);
  };
  const handleSelectVariant = (value: Variant, name: string) => {
    setVariantSelected(value);
  };

  const handleChangeProductAmount = (
    newAmount: number,
    orderProduct: OrderProduct
  ) => {
    const itemsUpdated = state?.items?.map((item) => {
      if ((item as OrderProduct)?._id === orderProduct?._id) {
        return {
          ...orderProduct,
          quantity: newAmount,
        };
      }
      return item;
    });
    dispatch({ type: ACTIONS.ITEMS, payload: itemsUpdated });
  };

  React.useEffect(() => {
    dispatch({ type: ACTIONS.DEFAULT, payload: order });
    if (services) {
      const subOrderItems = (subOrders as Order[])
        ?.filter((subO) => serviceOrderIds?.includes(subO?._id))
        ?.map((subOrder) => subOrder?.items);
      dispatch({
        type: ACTIONS.ITEMS,
        payload: (subOrderItems as Partial<OrderProduct>[]).flat(),
      });
    }
  }, [order]);

  return (
    <>
      <Card className="p-6">
        <h2 className=" text-neutral-100 text-sm font-semibold mb-[10px]">
          {services ? 'SERVICIOS EXTRA' : 'RESUMEN'}
        </h2>
        <Line />
        {location ? (
          <>
            <div className="flex justify-between mb-2 mt-[10px]">
              <p className=" text-neutral-200 text-[14px] font-medium leading-4">
                Punto de servicio
              </p>
              <p className=" text-neutral-200 text-[14px] font-medium leading-4">
                {(state?.location as unknown as Location)?.name ?? 'N/A'}
              </p>
            </div>
            <Line />
          </>
        ) : null}

        {order?.paymentInfo?.gateway ? (
          <>
            <div className="flex justify-between mb-2 mt-[10px]">
              <p className=" text-neutral-200 text-[14px] font-medium leading-4">
                Método de pago
              </p>
              <p className=" text-neutral-200 text-[14px] font-medium leading-4 capitalize">
                {order?.paymentInfo?.gateway}
              </p>
            </div>
            <Line />
          </>
        ) : null}

        {!services &&
          state?.items?.map((p: OrderProduct) => (
            <div>
              {p?.quantity === 0 ? null : (
                <ProductItem
                  orderProduct={p as OrderProduct}
                  key={(p as OrderProduct)?._id}
                  modify={modify}
                  changeAmount={handleChangeProductAmount}
                />
              )}
            </div>
          ))}
        {services ? (
          <div>
            {subOrders?.map((subO: Order) => (
              <div>
                {subO?.items?.map((p: OrderProduct) => (
                  <div>
                    {p?.quantity === 0 ? null : (
                      <ProductItem
                        orderProduct={p as OrderProduct}
                        key={(p as OrderProduct)?._id}
                        modify={modify}
                        changeAmount={handleChangeProductAmount}
                      />
                    )}
                  </div>
                ))}
                <div className="flex justify-between mb-2 mt-[10px]">
                  <p className=" text-neutral-200 text-[14px] font-medium leading-4">
                    Subtotal
                  </p>
                  <p className=" text-neutral-200 text-[14px] font-medium leading-4">
                    ${subO?.subtotal?.toFixed(2) ?? 'N/A'}
                  </p>
                </div>
                <div className="flex justify-between mb-2">
                  <p className=" text-neutral-200 text-[14px] font-medium leading-4">
                    Impuesto
                  </p>
                  <p className=" text-neutral-200 text-[14px] font-medium leading-4">
                    ${subO?.taxTotal?.toFixed(2) ?? 'N/A'}
                  </p>
                </div>
                {subO?.discounts?.length > 0 ? (
                  <>
                    <div className="flex justify-between mb-2">
                      <p className=" text-neutral-200 text-[14px] font-medium leading-4">
                        Descuentos
                      </p>
                      <div className="flex flex-col justify-end items-end gap-1">
                        <p className=" text-danger-300 text-[14px] font-medium leading-4 ml-auto">
                          - ${subO?.discountTotal?.toFixed(2) ?? 'N/A'}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2 pl-4 pb-3">
                      {subO?.discounts?.map((d, i) => (
                        <div
                          className="flex justify-between items-start py-1"
                          key={i}
                        >
                          <p className=" text-neutral-200 text-[12px] font-medium leading-3">
                            código: {d?.discount?.code ?? 'N/A'}
                          </p>
                          <div
                            className="flex flex-col items-end "
                            key={d?._id}
                          >
                            <p className=" text-neutral-100 text-[12px] font-medium leading-3">
                              {d?.discount?.type === 'percentage'
                                ? `- ${d?.value}% de descuento`
                                : `- $${d?.value} de descuento`}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : null}
                <div className="flex justify-between mb-2">
                  <p className=" text-neutral-200 text-[14px] font-medium leading-4">
                    Total
                  </p>
                  <p className=" text-neutral-200 text-[14px] font-medium leading-4">
                    {`$${subO?.total?.toFixed(2) ?? 'N/A'}`}
                  </p>
                </div>
                <Line className="mb-2" />
              </div>
            ))}
          </div>
        ) : null}
        {!services ? (
          <>
            {!modify && (
              <>
                <div className="flex justify-between mb-2 mt-[10px]">
                  <p className=" text-neutral-200 text-[14px] font-medium leading-4">
                    Subtotal
                  </p>
                  <p className=" text-neutral-200 text-[14px] font-medium leading-4">
                    ${state?.subtotal?.toFixed(2) ?? 'N/A'}
                  </p>
                </div>
                <div className="flex justify-between mb-2">
                  <p className=" text-neutral-200 text-[14px] font-medium leading-4">
                    Impuesto
                  </p>
                  <p className=" text-neutral-200 text-[14px] font-medium leading-4">
                    ${state?.taxTotal?.toFixed(2) ?? 'N/A'}
                  </p>
                </div>
                {state?.discounts?.length > 0 ? (
                  <>
                    <div className="flex justify-between mb-2">
                      <p className=" text-neutral-200 text-[14px] font-medium leading-4">
                        Descuentos
                      </p>
                      <div className="flex flex-col justify-end items-end gap-1">
                        <p className=" text-danger-300 text-[14px] font-medium leading-4 ml-auto">
                          - ${state?.discountTotal?.toFixed(2) ?? 'N/A'}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2 pl-4 pb-3">
                      {state?.discounts?.map((d, i) => (
                        <div
                          className="flex justify-between items-start py-1"
                          key={i}
                        >
                          <p className=" text-neutral-200 text-[12px] font-medium leading-3">
                            código: {d?.discount?.code ?? 'N/A'}
                          </p>
                          <div
                            className="flex flex-col items-end "
                            key={d?._id}
                          >
                            <p className=" text-neutral-100 text-[12px] font-medium leading-3">
                              {d?.discount?.type === 'percentage'
                                ? `- ${d?.value}% de descuento`
                                : `- $${d?.value} de descuento`}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : null}
                <div className="flex justify-between mb-2">
                  <p className=" text-neutral-200 text-[14px] font-medium leading-4">
                    Total
                  </p>
                  <p className=" text-neutral-200 text-[14px] font-medium leading-4">
                    {`$${state?.total?.toFixed(2) ?? 'N/A'}`}
                  </p>
                </div>
              </>
            )}
            {/* <Line className="mb-2" /> */}
          </>
        ) : null}
        {add && !modify ? (
          <Button
            type="button"
            className="py-2 text-[14px] leading-4 mt-[10px] bg-danger-100 "
            onClick={(e) => {
              e.preventDefault();
              setResetSelection(true);
              setAdd(false);
              setProductSelected({});
              setVariantSelected({});
              setQuantitySelected(1);
            }}
            disabled={disabled}
          >
            Cancelar
          </Button>
        ) : (
          !add &&
          !modify &&
          !services && (
            <div />
            // ***DEPRECATED***
            // <button
            //   type="button"
            //   className="flex gap-2 text-neutral-200 hover:text-primary-400 cursor-pointer  bg-none p-0  items-center mb-1"
            //   onClick={(e) => {
            //     e.preventDefault();
            //     setAdd(true);
            //   }}
            // >
            //   <AddIcon className="w-4 h-4 " />
            //   Agregar nuevo producto
            // </button>
          )
        )}
        {modify && !add ? (
          <>
            <Button
              type="button"
              className="py-2 text-[14px] leading-4 my-[10px]"
              onClick={onUpdateOrderProducts}
              disabled={disabled}
            >
              Actualizar
            </Button>
            <Button
              type="button"
              className="py-2 text-[14px] leading-4 my-[10px] bg-danger-100"
              onClick={(e) => {
                e.preventDefault();
                setModify(false);
                dispatch({ type: ACTIONS.DEFAULT, payload: order });
              }}
              disabled={disabled}
            >
              Cancelar
            </Button>
          </>
        ) : (
          !modify &&
          !add &&
          !services && (
            <div />
            // ***DEPRECATED***
            // <button
            //   type="button"
            //   className=" flex gap-2 text-neutral-200 hover:text-primary-400 cursor-pointer bg-none p-0 items-center"
            //   onClick={(e) => {
            //     e.preventDefault();
            //     setModify(true);
            //   }}
            // >
            //   <EditIcon className="w-4 h-4 " />
            //   Modificar productos
            // </button>
          )
        )}
      </Card>
      {add ? (
        <Card className="p-6 flex flex-col gap-1">
          <SelectSearch
            onChangeSelect={handleSelectProduct}
            setResetSelection={setResetSelection}
            resetSelection={resetSelection}
            options={
              buildProductsOptions(
                productsData?.products?.filter((pr) => pr?.variants?.length > 0)
              ) ?? []
            }
            actionName=""
            description="Seleccione un producto"
            label="Producto"
          />
          {productSelected?._id ? (
            <SelectSearch
              onChangeSelect={handleSelectVariant}
              setResetSelection={setResetSelection}
              resetSelection={resetSelection}
              options={
                buildVariantsOptions(
                  productSelected?.variants?.filter((vv) => vv?.stock > 0)
                ) ?? []
              }
              actionName=""
              description="Seleccione una variante del producto"
              label="Variante"
            />
          ) : null}
          {variantSelected?._id ? (
            <Input
              name=""
              label={`Cantidad (Stock de la variante "${
                variantSelected?.option?.option1 ?? ''
              } ${variantSelected?.option?.option2 ?? ''} ${
                variantSelected?.option?.option3 ?? ''
              }": ${variantSelected?.stock})
                        `}
              type="number"
              min="1"
              max={variantSelected?.stock}
              step="1"
              value={quantitySelected}
              onChange={(e) => {
                e.preventDefault();
                if (validateNumber(parseInt(e.target.value))) {
                  setQuantitySelected(parseInt(e.target.value));
                }
              }}
            />
          ) : null}
          <Button
            type="button"
            className={`py-2 text-[14px] leading-4 my-[10px] ${
              disabled || !(productSelected?._id && variantSelected?._id)
                ? 'hover:opacity-100 bg-primary-500'
                : ''
            }`}
            disabled={
              disabled || !(productSelected?._id && variantSelected?._id)
            }
            onClick={onAddProduct}
          >
            Agregar producto
          </Button>
        </Card>
      ) : null}
    </>
  );
}

export default Invoice;
