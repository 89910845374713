import React from 'react';
import { IRoute } from '../../routes';
import { Layout } from '../layout';
import OperatorForm from './OperatorForm';

interface UpdateOperatorFormProps {
  prevRoute?: Partial<IRoute>;
}

function CreateOperatorForm({ prevRoute = null }: UpdateOperatorFormProps) {
  return (
    <Layout
      title="Nuevo operador"
      routesHistory={[
        prevRoute,
        {
          text: 'Perfil de operador',
          href: '/app/operators/create',
          key: 'create',
        },
      ]}
    >
      <OperatorForm
        prevLink={prevRoute?.href ?? null}
        prevLinkName={prevRoute?.text ?? null}
      />
    </Layout>
  );
}

export default CreateOperatorForm;
