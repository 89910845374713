import React from 'react';
import { motion } from 'framer-motion';
import { useQuery } from '@apollo/client';
import { Button, CarIcon, CheckIcon, SelectSearch } from '@spa-cars/ui';
import { Vehicle, VehicleBrand, VehicleModel } from '@spa-cars/models';
import { GET_VEHICLES } from '../../../graphql/queries';

interface VehicleCardProps {
  vehicle: Partial<Vehicle>;
  clientId: string;
  disabled: boolean;
  updateRepairInfo: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => Promise<void>;
  setVehicle: React.Dispatch<React.SetStateAction<Partial<Vehicle>>>;
}

const buildVehiclesOptions = (vehicles: Vehicle[]) =>
  vehicles?.map((vehicle) => ({
    ...vehicle,
    name: `${(vehicle?.brand as VehicleBrand)?.name ?? 'N/A'}, ${
      (vehicle?.model as VehicleModel)?.name ?? ''
    }`,
  }));

function VehicleCard({
  vehicle,
  setVehicle,
  clientId,
  disabled,
  updateRepairInfo,
}: VehicleCardProps) {
  const [editVehicle, setEditVehicle] = React.useState(false);
  const { data: vehiclesData } = useQuery<{ vehicles: Vehicle[] }>(
    GET_VEHICLES,
    {
      variables: {
        filter: {
          active: true,
          owner: clientId,
        },
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  // eslint-disable-next-line
  const handleChangeSelectSearch = (value: Vehicle, name: string) => {
    setVehicle(value);
  };

  return (
    <motion.li
      layout
      transition={{ type: 'spring', bounce: 0 }}
      className="p-4 relative flex flex-col min-w-0 bg-white bg-clip-border shadow-[0_4px_10px_rgba(0,0,0,0.25)] rounded-lg "
    >
      <div className="flex w-full justify-between items-center">
        {editVehicle ? (
          <>
            <div className="w-1/2 mr-4">
              <SelectSearch
                onChangeSelect={handleChangeSelectSearch}
                options={buildVehiclesOptions(vehiclesData?.vehicles) ?? []}
                defaultSelect={{
                  ...vehicle,
                  name: `${vehicle?.brand ?? 'N/A'}, ${vehicle?.model ?? ''}`,
                }}
                actionName="vehicle"
                description="Seleccione un vehículo"
                label="Vehículo"
              />
            </div>
            <div className="flex gap-4 md:flex-row flex-col-reverse">
              <Button
                type="button"
                className=" py-2 px-4  w-fit h-fit font-normal text-sm bg-danger-100"
                onClick={(e) => {
                  e.preventDefault();
                  setEditVehicle(!editVehicle);
                }}
              >
                <p className="leading-[14px]">Atrás</p>
              </Button>
              <Button
                type="submit"
                className=" py-2 px-4  w-fit h-fit font-normal text-sm"
                disabled={disabled}
                onClick={(e) => {
                  updateRepairInfo(e);
                  setEditVehicle(false);
                }}
              >
                <p className="leading-[14px]">Actualizar vehículo</p>
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="w-full flex gap-4 items-center">
              <div className="bg-neutral-300 rounded-full p-1 w-fit h-fit">
                <CarIcon className="h-4 w-4 text-neutral-50" />
              </div>
              <div className="flex flex-col gap-1">
                <div className="flex gap-2">
                  <h2 className=" text-neutral-100 text-sm">VEHÍCULO</h2>
                  <CheckIcon className="h-4 w-4 text-primary-300" />
                </div>
                <div className="flex gap-2">
                  <span className="text-text-black text-sm ">
                    {`${
                      (vehicle?.brand as VehicleBrand)?.name ?? 'Sin asignar'
                    }${(vehicle?.brand as VehicleBrand)?.name ? ',' : ''} ${
                      (vehicle?.model as VehicleModel)?.name ?? ''
                    }`}
                  </span>
                  <span className="text-text-black text-sm ml-2">
                    {`${vehicle?.plate ?? ''}`}
                  </span>
                </div>
              </div>
            </div>
            {vehicle ? (
              <Button
                type="button"
                className=" py-2 px-4  w-fit h-fit font-normal text-sm text-white"
                onClick={(e) => {
                  e.preventDefault();
                  setEditVehicle(!editVehicle);
                }}
              >
                <p className="leading-[14px]">Cambiar</p>
              </Button>
            ) : null}
          </>
        )}
      </div>
    </motion.li>
  );
}

export default VehicleCard;
