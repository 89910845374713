import React from 'react';
import NProgress from 'nprogress';
import { validateString } from 'avilatek-utils';
import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';
import { Button, Input } from '@spa-cars/ui';
import { CHANGE_PASSWORD } from '../../graphql/mutations';
import { useNotify } from '../../hooks';

function ChangePasswordForm() {
  const notify = useNotify();
  const router = useRouter();
  const passwordRef = React.useRef<HTMLInputElement>();
  const confirmPasswordRef = React.useRef<HTMLInputElement>();

  const [disabled, setDisabled] = React.useState(false);
  const [changePassword] = useMutation<{
    changePassword: {
      success: boolean;
      err: string;
    };
  }>(CHANGE_PASSWORD);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      const password = passwordRef.current.value;
      const confirmPassword = confirmPasswordRef.current.value;

      if (
        !validateString(password) ||
        !validateString(confirmPassword) ||
        password !== confirmPassword
      ) {
        notify('Error, ambas contraseñas deben ser iguales y validas', 'error');
        return;
      }

      NProgress.start();
      setDisabled(true);
      const { data } = await changePassword({
        variables: {
          data: {
            password,
            token: router.query.token,
          },
        },
      });
      if (data?.changePassword?.success) {
        notify('Se ha restablecido su contraseña con éxito', 'success');
        router.push('/');
      } else {
        notify(`${data?.changePassword?.err}`, 'error');
      }
    } catch (err) {
      notify(err.message, 'error');
    } finally {
      NProgress.done();
      setDisabled(false);
    }
  };

  return (
    <section className="w-full h-full min-h-screen flex flex-row flex-wrap bg-neutral-50">
      <article className="w-full md:w-1/2 min-h-screen h-full flex items-center justify-center  md:px-16 px-4 py-16  ">
        <div className=" flex flex-col gap-10">
          <div className="flex flex-col">
            <h2 className=" text-[40px] font-medium text-text-black ">
              Restablecer contraseña
            </h2>
            <p>Ingrese su nueva y confirme contraseña</p>
          </div>
          <form
            method="POST"
            className="flex flex-col items-center gap-4"
            onSubmit={onSubmit}
          >
            <div className="w-full">
              <Input
                label="Nueva contraseña"
                name="password"
                type="password"
                placeholder="Ingrese su nueva contraseña"
                ref={passwordRef}
                required
              />
            </div>
            <div className="w-full">
              <Input
                label="Confirmar contraseña"
                name="confirmPassword"
                type="password"
                placeholder="Ingrese su contraseña"
                ref={confirmPasswordRef}
                required
              />
            </div>
            <Button type="submit" className=" mt-6" disabled={disabled}>
              Restablecer
            </Button>
          </form>
        </div>
      </article>
      <article className="w-0 h-0 md:w-1/2 md:h-screen  md:bg-primary-500  overflow-hidden md:flex md:flex-col md:justify-between ">
        <div className="  w-fit h-1/3 opacity-30 ">
          <img
            className="w-full h-full  "
            alt="logo"
            src="/img/leaf-sign-in-2.png"
          />
        </div>
        <div className=" w-full flex flex-col gap-6 text-center text-text-white ">
          <h1 className=" text-[64px]  font-medium leading-[60px]">
            Dashboard Administrativo
          </h1>
          <h2 className=" text-[26px]">Spacars</h2>
        </div>

        <div className=" ml-auto w-fit h-1/3 opacity-30 ">
          <img
            className="w-full h-full  "
            alt="logo"
            src="/img/leaf-sign-in-1.png"
          />
        </div>
      </article>
    </section>
  );
}

export default ChangePasswordForm;
