import { Discount, OrderItem, Tax } from '@spa-cars/models';

export default function getOrderTotal(
  items: OrderItem[],
  discounts: Discount[]
) {
  let subtotal = 0;
  let discountTotal = 0;
  let totalTax = 0;
  const appliedDiscounts = discounts ?? [];

  const orderProducts = [];
  for (const item of items) {
    const { product } = item;
    const { variant } = item;
    const { taxes } = item.product;
    const _taxes = (taxes as Tax[]).map((tax) => ({
      tax: tax._id,
      value: tax.value,
    }));

    const newOrderProduct = {
      title: product.title,
      photo: product.photos?.[0],
      cost: variant?.cost,
      price: variant.price,
      discount: 0,
      quantity: item.quantity,
      option: variant.option,
      product: product._id,
      location: variant.location,
      sku: variant.sku,
      variant: variant._id,
      taxes: _taxes ?? [],
      canDelete: false,
    };
    orderProducts.push(newOrderProduct);
  }

  const totalDiscountPercentage = appliedDiscounts.reduce(
    (acc, discount) =>
      acc + (String(discount.type) === 'percentage' ? discount.value / 100 : 0),
    0
  );

  orderProducts.forEach((item) => {
    const itemSubtotal = item.price * item.quantity;
    const itemDiscount = totalDiscountPercentage * itemSubtotal;
    const itemSubtotalAfterDiscount = Math.max(itemSubtotal - itemDiscount, 0);

    discountTotal += itemDiscount;
    subtotal += itemSubtotalAfterDiscount;

    item.taxes.forEach((tax) => {
      totalTax += itemSubtotalAfterDiscount * (tax.value / 100);
    });
  });

  let fixedDiscount = 0;
  appliedDiscounts.forEach((discount) => {
    if (String(discount.type) === 'fixed') {
      discountTotal += discount.value;
      fixedDiscount += discount.value;
    }
  });

  const total = Math.max(subtotal + totalTax - fixedDiscount, 0);

  return { subtotal, total, totalTax, discountTotal };
}
