import React from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import { Tax } from '@spa-cars/models';
import TaxForm from './TaxForm';
import { Layout } from '../layout';
import { GET_TAX } from '../../graphql/queries';
import { Loading } from '../shared';

export default function UpdateTaxForm() {
  const router = useRouter();
  const { data, loading } = useQuery<{ tax: Tax }>(GET_TAX, {
    variables: {
      filter: { _id: router.query._id as string },
    },
    fetchPolicy: 'cache-and-network',
  });
  const [tax, setTax] = React.useState<Partial<Tax>>(data?.tax ?? null);

  React.useEffect(() => {
    if (data?.tax) {
      setTax(data.tax);
    }
  }, [data?.tax]);

  return (
    <Layout
      title={tax?.name ?? 'Perfil del impuesto'}
      routesHistory={[
        {
          text: 'Perfil del impuesto',
          href: `/app/taxes/${router.query._id}`,
          key: 'update',
        },
      ]}
    >
      {loading ? <Loading /> : <TaxForm tax={tax} setTax={setTax} />}
    </Layout>
  );
}
