export const userTypes = [
  {
    name: 'Super-Admin',
    value: 'superadmin',
  },
  {
    name: 'Administrador',
    value: 'admin',
  },
  {
    name: 'Marketing Manager',
    value: 'admin_manager',
  },
  {
    name: 'Inventory Manager',
    value: 'inventory_manager',
  },
];
