import React from 'react';
import NProgress from 'nprogress';
import Link from 'next/link';
import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import { Button, Input } from '@spa-cars/ui';
import { User } from '@spa-cars/models';
import { SIGN_IN } from '../../graphql/mutations';
import { useNotify, useUser } from '../../hooks';

function SignInForm() {
  const notify = useNotify();
  const router = useRouter();
  const [, setUser] = useUser();
  const emailRef = React.useRef<HTMLInputElement>();
  const passwordRef = React.useRef<HTMLInputElement>();
  const [disabled, setDisabled] = React.useState(false);
  const [signIn] = useMutation<{
    signIn: {
      user: User;
    };
  }>(SIGN_IN);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      const email = emailRef.current.value;
      const password = passwordRef.current.value;
      NProgress.start();
      setDisabled(true);
      const { data } = await signIn({
        variables: {
          data: {
            email,
            password,
          },
        },
      });
      if (
        data.signIn &&
        data?.signIn?.user?.userType !== 'client' &&
        data?.signIn?.user?.userType !== 'mechanic' &&
        data?.signIn?.user?.active
      ) {
        setUser({ ...data.signIn?.user });
        await router.push('/app');
      } else if (
        data?.signIn?.user?.userType === 'client' ||
        data?.signIn?.user?.userType === 'mechanic' ||
        !data?.signIn?.user?.active
      ) {
        notify('Credenciales invalidas', 'error');
      } else {
        notify('Ha ocurrido un error', 'error');
      }
    } catch (err) {
      notify(err.message, 'error');
    } finally {
      NProgress.done();
      setDisabled(false);
    }
  };

  return (
    <section className="w-full h-full min-h-screen flex flex-row flex-wrap bg-neutral-50">
      <article className="w-full md:w-1/2 min-h-screen h-full flex items-center justify-center  md:px-16 px-4 py-16  ">
        <div className=" flex flex-col gap-10">
          <div className="flex flex-col">
            <h2 className=" text-[40px] font-medium text-text-black ">
              Inicio de sesión
            </h2>
            <p>Es momento de crear unicornios trabajando como camellos</p>
          </div>
          <form
            method="POST"
            className="flex flex-col items-center gap-4"
            onSubmit={onSubmit}
          >
            <div className="w-full">
              <Input
                label="Correo Electrónico"
                name="email"
                type="email"
                placeholder="Ingrese un correo electrónico"
                ref={emailRef}
                required
              />
            </div>
            <div className="w-full">
              <Input
                label="Contraseña"
                name="password"
                type="password"
                placeholder="Ingrese su contraseña"
                ref={passwordRef}
                required
              />
              <div className=" flex self-start mt-2 ">
                <Link
                  href="/reset-password"
                  className="text-primary-400 cursor-pointer hover:text-primary-200 text-sm"
                >
                  Olvidé mi contraseña
                </Link>
              </div>
            </div>
            <Button type="submit" className=" mt-6" disabled={disabled}>
              Iniciar Sesión
            </Button>
          </form>
        </div>
      </article>
      <article className="w-0 h-0 md:w-1/2 md:h-screen  md:bg-primary-500  overflow-hidden md:flex md:flex-col md:justify-between ">
        <div className="  w-fit h-1/3 opacity-30 ">
          <img
            className="w-full h-full  "
            alt="logo"
            src="/img/leaf-sign-in-2.png"
          />
        </div>
        <div className=" w-full flex flex-col gap-6 text-center text-text-white ">
          <h1 className=" text-[64px]  font-medium leading-[60px]">
            Dashboard Administrativo
          </h1>
          <h2 className=" text-[26px]">Spacars</h2>
        </div>

        <div className=" ml-auto w-fit h-1/3 opacity-30 ">
          <img
            className="w-full h-full  "
            alt="logo"
            src="/img/leaf-sign-in-1.png"
          />
        </div>
      </article>
    </section>
  );
}

export default SignInForm;
