import React from 'react';
import NProgress from 'nprogress';
import Link from 'next/link';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import {
  ArrowBackIcon,
  Button,
  Card,
  CardBody,
  CardHeader,
  DniInput,
  Input,
  Line,
} from '@spa-cars/ui';
import { Client, DocumentModel, User } from '@spa-cars/models';
import { useNotify } from '../../hooks';
import { ACTIONS, reducer } from './reducer';
import { SIGN_UP_WITHOUT_TOKEN, UPDATE_USER } from '../../graphql/mutations';
import { DocumentForm } from '../document';
import AppointmentTable from './AppointmentTable';
import { dniTypes } from '../../lib';

interface ClientFormProps {
  client?: Partial<User>;
  setClient?: React.Dispatch<React.SetStateAction<Partial<User>>>;
}

function ClientForm({ client = null, setClient = null }: ClientFormProps) {
  const [state, dispatch] = React.useReducer(reducer, client || {});
  const [updateUser] = useMutation<{
    updateUser: {
      recordId: string;
    };
  }>(UPDATE_USER);
  const [signUpWithoutToken] = useMutation<{
    signUpWithoutToken: {
      user: User;
    };
  }>(SIGN_UP_WITHOUT_TOKEN);
  const [documents, setDocuments] = React.useState<
    Array<Partial<DocumentModel>>
  >([]);
  const notify = useNotify();
  const router = useRouter();
  const [disabled, setDisabled] = React.useState(false);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      NProgress.start();
      setDisabled(true);
      delete state?._id;

      let localPhone = null;
      let phoneCode = null;

      if (state.phone.phone?.length > 3) {
        localPhone = state.phone.phone.slice(
          state.phone.phone.length - 10,
          state.phone.phone.length
        );
        phoneCode = state.phone.phone.slice(0, state.phone.phone.length - 10);
      }

      const clientData = {
        ...state,
        email: state?.email?.toLowerCase(),
        phone: {
          phone: localPhone,
          code: phoneCode,
        },
        photo: {
          src: (documents[0]?.src as string) ?? null,
          alt: (documents[0]?.name as string) ?? null,
          type: (documents[0]?.file?.type as string) ?? null,
        },
        dniType: state?.dniType ?? 'V',
        client: (state?.client as unknown as Client)?._id,
      };
      if (client) {
        const { data } = await updateUser({
          variables: {
            filter: {
              _id: client._id,
            },
            record: {
              ...clientData,
              __typename: undefined,
            },
          },
        });
        if (data?.updateUser?.recordId) {
          notify('Cliente actualizado con éxito', 'success');
          router.push('/app/clients');
        } else {
          notify('Ha ocurrido un error al actualizar el cliente', 'error');
        }
      } else {
        const { data } = await signUpWithoutToken({
          variables: {
            data: {
              ...clientData,
              __typename: undefined,
            },
          },
        });
        if (data?.signUpWithoutToken?.user?._id) {
          notify('Cliente creado con éxito', 'success');
          router.push(`/app/clients/${data?.signUpWithoutToken?.user?._id}`);
        } else {
          notify('Ha ocurrido un error al crear el cliente', 'error');
        }
      }
      setDisabled(false);
    } catch (err) {
      if (err.message.includes('email_1')) {
        notify(`El correo ingresado ya está en uso`, 'error');
      } else if (err.message.includes('dni_1')) {
        notify(`La cédula ingresada ya está en uso`, 'error');
      } else {
        notify(`Error al actualizar perfil: ${err}`, 'error');
      }
      setDisabled(false);
    } finally {
      NProgress.done();
    }
  };

  const updateActiveClient = async (active: boolean) => {
    try {
      NProgress.start();
      setDisabled(true);
      if (client) {
        const { data } = await updateUser({
          variables: {
            filter: {
              _id: client._id,
            },
            record: {
              active,
            },
          },
        });
        if (data?.updateUser?.recordId) {
          notify('Cliente actualizado con éxito', 'success');
          router.push('/app/clients');
        } else {
          notify('Ha ocurrido un error al inhabilitar el cliente', 'error');
        }
      }
      setDisabled(false);
    } catch (err) {
      notify(err.message, 'error');
      setDisabled(false);
    } finally {
      NProgress.done();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({ type: name as any, payload: value });
  };

  const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    dispatch({ type: name as any, payload: value });
  };

  React.useEffect(() => {
    if (client) {
      dispatch({ type: ACTIONS.DEFAULT, payload: client });
      if (client?.photo?.src) {
        setDocuments([{ src: client?.photo?.src, name: client?.photo?.alt }]);
      }
      dispatch({
        type: ACTIONS.PHONE,
        payload: `${client?.phone?.code ?? ''}${client?.phone?.phone ?? ''}`,
      });
      if (!client?.phone?.phone) {
        dispatch({ type: ACTIONS.PHONE, payload: '+58' });
      }
    } else {
      dispatch({ type: ACTIONS.PHONE, payload: '+58' });
    }
  }, [client]);

  return (
    <form
      method="POST"
      onSubmit={onSubmit}
      className="flex flex-col w-full gap-8"
    >
      <Card>
        <CardHeader>
          {client ? 'Actualizar' : 'Crear'} cliente
          <Link
            href="/app/clients"
            className="flex gap-1 text-success-200 font-medium items-center"
          >
            <ArrowBackIcon className="w-4 h-4" />
            CLIENTES
          </Link>
        </CardHeader>

        <Line />
        <CardBody>
          <div className="w-full flex md:flex-row flex-col gap-4">
            <div className="w-full">
              <Input
                name={ACTIONS.FIRST_NAME}
                value={state?.firstName ?? ''}
                label="Nombre"
                type="text"
                placeholder="Ingrese un nombre"
                onChange={handleChange}
                required
              />
            </div>
            <div className="w-full">
              <Input
                name={ACTIONS.LAST_NAME}
                value={state?.lastName ?? ''}
                label="Apellido"
                type="text"
                placeholder="Ingrese un apellido"
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className=" flex md:flex-row flex-col gap-4">
            <div className="w-full">
              <DniInput
                selectName={ACTIONS.DNI_TYPE}
                selectValue={state?.dniType}
                label="Cédula de identidad"
                handleChangeSelect={handleChangeSelect}
                defaultSelect={dniTypes[0].value}
                inputName={ACTIONS.DNI}
                inputValue={state?.dni ?? ''}
                placeholder="Ingrese una cédula de identidad"
                handleChange={handleChange}
              >
                {dniTypes.map((option) => (
                  <option value={option.name} key={option.name}>
                    {option.name}
                  </option>
                ))}
              </DniInput>
            </div>
            <div className="w-full">
              <span className="text-neutral-200 text-sm font-medium mb-2 ">
                Teléfono
              </span>
              <PhoneInput
                inputStyle={{
                  appearance: 'none',
                  height: '24px',
                  background: 'transparent',
                  width: '100%',
                  border: 'solid',
                  borderWidth: '0.5px',
                  borderColor: '#A6A6A6',
                  borderRadius: '4px',
                  minHeight: '40px',
                  fontSize: '16px',
                  color: '#606060',
                  fontWeight: '300',
                }}
                countryCodeEditable={false}
                value={state?.phone?.phone}
                placeholder="Ingrese un teléfono"
                onChange={(phone) => {
                  dispatch({ type: ACTIONS.PHONE, payload: phone });
                }}
              />
            </div>
          </div>
          <div className=" flex md:flex-row flex-col gap-4">
            <div className="w-full">
              <Input
                label="Correo Electrónico"
                name={ACTIONS.EMAIL}
                value={state?.email ?? ''}
                type="email"
                placeholder="Ingrese un correo electrónico"
                onChange={handleChange}
                required
              />
            </div>
            <div className="w-full">
              {!client ? (
                <Input
                  label="Contraseña"
                  name={ACTIONS.PASSWORD}
                  value={state?.password ?? ''}
                  type="password"
                  placeholder="Ingrese una contraseña"
                  onChange={handleChange}
                  required
                />
              ) : null}
            </div>
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>Imagen de perfil</CardHeader>
        <CardBody>
          <DocumentForm
            documents={documents}
            updateURLs={setDocuments}
            maximumAmount={1}
          />
        </CardBody>
      </Card>
      {client ? <AppointmentTable userId={state?._id} /> : null}
      <div className="flex self-end gap-[30px]">
        {client?.active ? (
          <Button
            type="button"
            className=" w-fit py-3 px-[25px] bg-danger-100"
            disabled={disabled}
            onClick={(e) => {
              e.preventDefault();
              updateActiveClient(false);
            }}
          >
            Banear
          </Button>
        ) : (
          client && (
            <Button
              type="button"
              className=" w-fit py-3 px-[25px] bg-white text-primary-400"
              disabled={disabled}
              onClick={(e) => {
                e.preventDefault();
                updateActiveClient(true);
              }}
            >
              Activar
            </Button>
          )
        )}
        <Button
          type="submit"
          className=" w-fit py-3 px-[25px]"
          disabled={disabled}
        >
          {client ? 'Actualizar' : 'Crear'}
        </Button>
      </div>
    </form>
  );
}

export default ClientForm;
