import React, { useCallback } from 'react';
import update from 'immutability-helper';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { DndProvider } from 'react-dnd';
import { v4 as uuid } from 'uuid';
import { DocumentModel } from '@spa-cars/models';
import DropZone from './DropZone';
import DocumentList from './DocumentList';
import { useNotify } from '../../hooks';

interface DocumentFormProps {
  documents: Array<Partial<DocumentModel>>;
  updateURLs: React.Dispatch<React.SetStateAction<Partial<DocumentModel>[]>>;
  maximumAmount?: number;
}

function DocumentForm({
  updateURLs,
  documents,
  maximumAmount = 5,
}: DocumentFormProps) {
  const notify = useNotify();
  const [docs, setDocs] = React.useState<Array<DocumentModel>>(
    documents?.map((doc) => ({
      id: uuid(),
      src: doc?.src,
      name: doc?.name ?? 'N/A',
      file: doc?.file,
    })) ?? []
  );

  const getBackend = () => {
    if (typeof window !== 'undefined' && 'ontouchstart' in window) {
      return TouchBackend;
    }
    return HTML5Backend;
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]): void => {
      if (
        acceptedFiles.length > maximumAmount ||
        documents.length >= maximumAmount ||
        docs.length >= maximumAmount ||
        acceptedFiles.length + docs.length > maximumAmount
      ) {
        notify(`El máximo de fotos es ${maximumAmount}`, 'warning');
        return;
      }
      acceptedFiles
        .map((file: File) => {
          if (file.size > 1000000) {
            notify('Tu archivo debe pesar menos de 1MB', 'error');
            return null;
          }
          const reader = new FileReader();
          reader.onload = function onLoadFile(e) {
            setDocs((prevState) => [
              ...prevState,
              {
                id: uuid(),
                src: e.target.result,
                name: file?.name ?? uuid(),
                file,
              },
            ]);
          };
          reader.readAsDataURL(file);
          return file;
        })
        .filter((x) => !!x) as File[];
    },
    [docs.length, documents.length, maximumAmount, notify]
  );

  const updateDocs = React.useCallback(setDocs, [setDocs]);

  const moveDoc = React.useCallback(
    (dragIndex, hoverIndex) => {
      const draggedImage = docs[dragIndex];
      setDocs(
        update(docs, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, draggedImage],
          ],
        })
      );
      updateURLs((urls) =>
        update(urls, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, urls[dragIndex]],
          ],
        })
      );
    },
    [docs, setDocs, updateURLs]
  );
  React.useEffect(() => {
    if (documents?.length > 0) {
      setDocs(
        documents?.map((doc) => ({
          id: uuid(),
          src: doc?.src,
          name: doc?.name ?? 'N/A',
          file: doc?.file,
        })) ?? []
      );
    } else if (documents?.length === 0 && docs?.length > 0) {
      setDocs([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents]);

  return (
    <DndProvider backend={getBackend()}>
      <section className="w-full flex flex-col flex-wrap">
        <DropZone
          onDrop={onDrop}
          accept={{ 'image/*': ['.png', '.jpeg', '.jpg'] }}
        />
        <DocumentList
          docs={docs}
          updateDocs={updateDocs}
          moveDoc={moveDoc}
          updateURLs={updateURLs}
        />
      </section>
    </DndProvider>
  );
}

export default DocumentForm;
