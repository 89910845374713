import { Address, Location, Multimedia } from '@spa-cars/models';

export const ACTIONS = {
  NAME: 'NAME',
  DESCRIPTION: 'DESCRIPTION',
  PHOTOS: 'PHOTOS',
  ADDRESS: 'ADDRESS',
  BOOTHS: 'BOOTHS',
  INDEXA: 'INDEXA',
  DEFAULT: 'DEFAULT',
} as const;

export type ACTIONS_TYPE =
  | { type: 'NAME'; payload: string }
  | { type: 'DESCRIPTION'; payload: string }
  | { type: 'PHOTOS'; payload: Multimedia[] }
  | { type: 'ADDRESS'; payload: Address }
  | { type: 'BOOTHS'; payload: number }
  | { type: 'INDEXA'; payload: string }
  | { type: 'DEFAULT'; payload: Partial<Location> };

export function reducer(
  state: Partial<Location>,
  action: ACTIONS_TYPE
): Partial<Location> {
  switch (action.type) {
    case ACTIONS.NAME:
      return { ...state, name: action.payload };
    case ACTIONS.DESCRIPTION:
      return { ...state, description: action.payload };
    case ACTIONS.PHOTOS:
      return { ...state, photos: action.payload };
    case ACTIONS.ADDRESS:
      return { ...state, address: action.payload };
    case ACTIONS.BOOTHS:
      return { ...state, booths: action.payload };
    case ACTIONS.INDEXA:
      return { ...state, indexa: action.payload };
    case ACTIONS.DEFAULT:
      return { ...state, ...(action?.payload ?? {}) };
    default:
      return { ...state };
  }
}
